import { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }

export interface SharedGraphQlOperations {
    /** shared/src/auth.ts */
    CurrentAuthState: (variables: CurrentAuthStateVariables) => CurrentAuthStateResult

    /** shared/src/backend/file.ts */
    HighlightedFile: (variables: HighlightedFileVariables) => HighlightedFileResult

    /** shared/src/backend/file.ts */
    HighlightedFileVSCE: (variables: HighlightedFileVSCEVariables) => HighlightedFileVSCEResult

    /** shared/src/backend/repo.ts */
    ResolveRawRepoName: (variables: ResolveRawRepoNameVariables) => ResolveRawRepoNameResult

    /** shared/src/backend/repo.ts */
    TreeEntries: (variables: TreeEntriesVariables) => TreeEntriesResult

    /** shared/src/backend/settings.ts */
    ViewerSettings: (variables: ViewerSettingsVariables) => ViewerSettingsResult

    /** shared/src/codeintel/legacy-extensions/lsif/definition-hover.ts */
    LegacyDefinitionAndHover: (variables: LegacyDefinitionAndHoverVariables) => LegacyDefinitionAndHoverResult

    /** shared/src/codeintel/legacy-extensions/lsif/implementations.ts */
    LegacyImplementations: (variables: LegacyImplementationsVariables) => LegacyImplementationsResult

    /** shared/src/codeintel/legacy-extensions/lsif/ranges.ts */
    LegacyRanges: (variables: LegacyRangesVariables) => LegacyRangesResult

    /** shared/src/codeintel/legacy-extensions/lsif/references.ts */
    LegacyReferences: (variables: LegacyReferencesVariables) => LegacyReferencesResult

    /** shared/src/codeintel/legacy-extensions/lsif/stencil.ts */
    LegacyStencil: (variables: LegacyStencilVariables) => LegacyStencilResult

    /** shared/src/codeintel/legacy-extensions/util/api.ts */
    LegacyResolveRepo: (variables: LegacyResolveRepoVariables) => LegacyResolveRepoResult

    /** shared/src/codeintel/legacy-extensions/util/api.ts */
    LegacyResolveRepo2: (variables: LegacyResolveRepo2Variables) => LegacyResolveRepo2Result

    /** shared/src/codeintel/legacy-extensions/util/api.ts */
    LegacyRepositoryIntrospection: (
        variables: LegacyRepositoryIntrospectionVariables
    ) => LegacyRepositoryIntrospectionResult

    /** shared/src/codeintel/legacy-extensions/util/api.ts */
    LegacyImplementationsIntrospectionQuery: (
        variables: LegacyImplementationsIntrospectionQueryVariables
    ) => LegacyImplementationsIntrospectionQueryResult

    /** shared/src/codeintel/legacy-extensions/util/api.ts */
    LegacyLocalCodeIntelIntrospectionQuery: (
        variables: LegacyLocalCodeIntelIntrospectionQueryVariables
    ) => LegacyLocalCodeIntelIntrospectionQueryResult

    /** shared/src/codeintel/legacy-extensions/util/api.ts */
    LegacySymbolInfoIntrospectionQuery: (
        variables: LegacySymbolInfoIntrospectionQueryVariables
    ) => LegacySymbolInfoIntrospectionQueryResult

    /** shared/src/codeintel/legacy-extensions/util/api.ts */
    LegacySymbolLocationRangeIntrospectionQuery: (
        variables: LegacySymbolLocationRangeIntrospectionQueryVariables
    ) => LegacySymbolLocationRangeIntrospectionQueryResult

    /** shared/src/codeintel/legacy-extensions/util/api.ts */
    LegacyResolveRev: (variables: LegacyResolveRevVariables) => LegacyResolveRevResult

    /** shared/src/codeintel/legacy-extensions/util/api.ts */
    LegacyCodeIntelSearch: (variables: LegacyCodeIntelSearchVariables) => LegacyCodeIntelSearchResult

    /** shared/src/codeintel/legacy-extensions/util/api.ts */
    LegacyExtensionManifests: (variables: LegacyExtensionManifestsVariables) => LegacyExtensionManifestsResult

    /** shared/src/codeintel/legacy-extensions/util/api.ts */
    LegacyProductVersion: (variables: LegacyProductVersionVariables) => LegacyProductVersionResult

    /** shared/src/codeintel/legacy-extensions/util/api.ts */
    LegacyCurrentUser: (variables: LegacyCurrentUserVariables) => LegacyCurrentUserResult

    /** shared/src/codeintel/legacy-extensions/util/api.ts */
    LegacyCreateAccessToken: (variables: LegacyCreateAccessTokenVariables) => LegacyCreateAccessTokenResult

    /** shared/src/codeintel/legacy-extensions/util/api.ts */
    LegacyFileContent: (variables: LegacyFileContentVariables) => LegacyFileContentResult

    /** shared/src/codeintel/legacy-extensions/util/api.ts */
    LegacyStencilIntrospectionQuery: (
        variables: LegacyStencilIntrospectionQueryVariables
    ) => LegacyStencilIntrospectionQueryResult

    /** shared/src/codeintel/legacy-extensions/util/api.ts */
    LegacyCodeIntelSearch2: (variables: LegacyCodeIntelSearch2Variables) => LegacyCodeIntelSearch2Result

    /** shared/src/codeintel/legacy-extensions/util/api.ts */
    LegacyCodeIntelSearch3: (variables: LegacyCodeIntelSearch3Variables) => LegacyCodeIntelSearch3Result

    /** shared/src/codeintel/legacy-extensions/util/api.ts */
    LocalCodeIntel: (variables: LocalCodeIntelVariables) => LocalCodeIntelResult

    /** shared/src/codeintel/legacy-extensions/util/api.ts */
    LegacySymbolInfo: (variables: LegacySymbolInfoVariables) => LegacySymbolInfoResult

    /** shared/src/codeintel/legacy-extensions/util/api.ts */
    LegacySymbolInfo2: (variables: LegacySymbolInfo2Variables) => LegacySymbolInfo2Result

    /** shared/src/extensions/helpers.ts */
    Extensions: (variables: ExtensionsVariables) => ExtensionsResult

    /** shared/src/extensions/helpers.ts */
    ExtensionsWithPrioritizeExtensionIDsParamAndNoJSONFields: (
        variables: ExtensionsWithPrioritizeExtensionIDsParamAndNoJSONFieldsVariables
    ) => ExtensionsWithPrioritizeExtensionIDsParamAndNoJSONFieldsResult

    /** shared/src/settings/edit.ts */
    EditSettings: (variables: EditSettingsVariables) => EditSettingsResult

    /** shared/src/settings/edit.ts */
    OverwriteSettings: (variables: OverwriteSettingsVariables) => OverwriteSettingsResult

    /** shared/src/settings/temporary/TemporarySettingsStorage.ts */
    GetTemporarySettings: (variables: GetTemporarySettingsVariables) => GetTemporarySettingsResult

    /** shared/src/settings/temporary/TemporarySettingsStorage.ts */
    EditTemporarySettings: (variables: EditTemporarySettingsVariables) => EditTemporarySettingsResult
}

/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
    ID: string
    String: string
    Boolean: boolean
    Int: number
    Float: number
    /** A quadruple that represents all possible states of the published value: true, false, 'draft', or null. */
    PublishedValue: boolean | 'draft'
    /** A valid JSON value. */
    JSONValue: unknown
    /** A string that contains valid JSON, with additional support for //-style comments and trailing commas. */
    JSONCString: string
    /** A Git object ID (SHA-1 hash, 40 hexadecimal characters). */
    GitObjectID: string
    /** An arbitrarily large integer encoded as a decimal string. */
    BigInt: string
    /**
     * An RFC 3339-encoded UTC date string, such as 1973-11-29T21:33:09Z. This value can be parsed into a
     * JavaScript Date using Date.parse. To produce this value from a JavaScript Date instance, use
     * Date#toISOString.
     */
    DateTime: string
}

/** Input type of a user (identified either by username or email address) with its repository permission. */
export interface UserPermissionInput {
    /**
     * Depending on the bindID option in the permissions.userMapping site configuration property,
     * the elements of the list are either all usernames (bindID of "username") or all email
     * addresses (bindID of "email").
     */
    bindID: Scalars['String']
    /** The highest level of repository permission. */
    permission?: Maybe<RepositoryPermission>
}

/** A user (identified either by username or email address) with its sub-repository permissions. */
export interface UserSubRepoPermission {
    /**
     * Depending on the bindID option in the permissions.userMapping site configuration property,
     * the elements of the list are either all usernames (bindID of "username") or all email
     * addresses (bindID of "email").
     */
    bindID: Scalars['String']
    /** An array of paths that the user is allowed to access, in glob format. */
    pathIncludes: Array<Scalars['String']>
    /** An array of paths that the user is not allowed to access, in glob format. */
    pathExcludes: Array<Scalars['String']>
}

/** Different repository permission levels. */
export enum RepositoryPermission {
    READ = 'READ',
}

/** Additional options when performing a permissions sync. */
export interface FetchPermissionsOptions {
    /**
     * Indicate that any caches added for optimization encountered during this permissions
     * sync should be invalidated.
     */
    invalidateCaches?: Maybe<Scalars['Boolean']>
}

/** The publication state of a changeset on Sourcegraph */
export enum ChangesetPublicationState {
    /** The changeset has not yet been created on the code host. */
    UNPUBLISHED = 'UNPUBLISHED',
    /** The changeset has been created on the code host. */
    PUBLISHED = 'PUBLISHED',
}

/** The reconciler state of a changeset on Sourcegraph */
export enum ChangesetReconcilerState {
    /** The changeset is scheduled, and will be enqueued when its turn comes in Sourcegraph's rollout window. */
    SCHEDULED = 'SCHEDULED',
    /** The changeset is enqueued for the reconciler to process it. */
    QUEUED = 'QUEUED',
    /**
     * The changeset reconciler is currently computing the delta between the
     * If a delta exists, the reconciler tries to update the state of the
     * changeset on the code host and on Sourcegraph to the desired state.
     */
    PROCESSING = 'PROCESSING',
    /**
     * The changeset reconciler ran into a problem while processing the
     * changeset and will retry it for a number of retries.
     */
    ERRORED = 'ERRORED',
    /**
     * The changeset reconciler ran into a problem while processing the
     * changeset that can't be fixed by retrying.
     */
    FAILED = 'FAILED',
    /** The changeset is not enqueued for processing. */
    COMPLETED = 'COMPLETED',
}

/** The state of a changeset on the code host on which it's hosted. */
export enum ChangesetExternalState {
    DRAFT = 'DRAFT',
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
    MERGED = 'MERGED',
    READONLY = 'READONLY',
    DELETED = 'DELETED',
}

/** The review state of a changeset. */
export enum ChangesetReviewState {
    APPROVED = 'APPROVED',
    CHANGES_REQUESTED = 'CHANGES_REQUESTED',
    PENDING = 'PENDING',
    COMMENTED = 'COMMENTED',
    DISMISSED = 'DISMISSED',
}

/** The state of checks (e.g., for continuous integration) on a changeset. */
export enum ChangesetCheckState {
    PENDING = 'PENDING',
    PASSED = 'PASSED',
    FAILED = 'FAILED',
}

/** The visual state a changeset is currently in. */
export enum ChangesetState {
    /** The changeset has not been marked as to be published. */
    UNPUBLISHED = 'UNPUBLISHED',
    /**
     * The changeset reconciler ran into a problem while processing the
     * changeset that can't be fixed by retrying.
     */
    FAILED = 'FAILED',
    /**
     * The changeset reconciler ran into a problem while processing the
     * changeset and will retry it for a number of retries.
     */
    RETRYING = 'RETRYING',
    /** The changeset is scheduled, and will be enqueued when its turn comes in Sourcegraph's rollout window. */
    SCHEDULED = 'SCHEDULED',
    /**
     * The changeset reconciler is currently computing the delta between the
     * If a delta exists, the reconciler tries to update the state of the
     * changeset on the code host and on Sourcegraph to the desired state.
     */
    PROCESSING = 'PROCESSING',
    /** The changeset is published, not being reconciled and open on the code host. */
    OPEN = 'OPEN',
    /** The changeset is published, not being reconciled and in draft state on the code host. */
    DRAFT = 'DRAFT',
    /** The changeset is published, not being reconciled and closed on the code host. */
    CLOSED = 'CLOSED',
    /** The changeset is published, not being reconciled and merged on the code host. */
    MERGED = 'MERGED',
    /**
     * The changeset is published, and is now read-only, most likely due to the
     * repository being archived.
     */
    READONLY = 'READONLY',
    /** The changeset is published, not being reconciled and has been deleted on the code host. */
    DELETED = 'DELETED',
}

/** This enum declares all operations supported by the reconciler. */
export enum ChangesetSpecOperation {
    /** Push a new commit to the code host. */
    PUSH = 'PUSH',
    /**
     * Update the existing changeset on the codehost. This is purely the changeset resource on the code host,
     * not the git commit. For updates to the commit, see 'PUSH'.
     */
    UPDATE = 'UPDATE',
    /** Move the existing changeset out of being a draft. */
    UNDRAFT = 'UNDRAFT',
    /** Publish a changeset to the codehost. */
    PUBLISH = 'PUBLISH',
    /** Publish a changeset to the codehost as a draft changeset. (Only on supported code hosts). */
    PUBLISH_DRAFT = 'PUBLISH_DRAFT',
    /** Sync the changeset with the current state on the codehost. */
    SYNC = 'SYNC',
    /** Import an existing changeset from the code host with the ExternalID from the spec. */
    IMPORT = 'IMPORT',
    /** Close the changeset on the codehost. */
    CLOSE = 'CLOSE',
    /** Reopen the changeset on the codehost. */
    REOPEN = 'REOPEN',
    /** Internal operation to get around slow code host updates. */
    SLEEP = 'SLEEP',
    /** The changeset is removed from some of the associated batch changes. */
    DETACH = 'DETACH',
    /** The changeset is kept in the batch change, but it's marked as archived. */
    ARCHIVE = 'ARCHIVE',
    /** The changeset is re-added to the batch change. */
    REATTACH = 'REATTACH',
}

/** The type of the changeset spec. */
export enum ChangesetSpecType {
    /** References an existing changeset on a code host to be imported. */
    EXISTING = 'EXISTING',
    /** References a branch and a patch to be applied to create the changeset from. */
    BRANCH = 'BRANCH',
}

/** State of the workspace resolution. */
export enum BatchSpecWorkspaceResolutionState {
    /** Not yet started resolving. Will be picked up by a worker eventually. */
    QUEUED = 'QUEUED',
    /** Currently resolving workspaces. */
    PROCESSING = 'PROCESSING',
    /** An error occured while resolving workspaces. Will be retried eventually. */
    ERRORED = 'ERRORED',
    /** A fatal error occured while resolving workspaces. No retries will be made. */
    FAILED = 'FAILED',
    /** Resolving workspaces finished successfully. */
    COMPLETED = 'COMPLETED',
}

/** Possible sort orderings for a workspace connection. */
export enum WorkspacesSortOrder {
    /** Sort by repository name in ascending order. */
    REPO_NAME_ASC = 'REPO_NAME_ASC',
    /** Sort by repository name in descending order. */
    REPO_NAME_DESC = 'REPO_NAME_DESC',
}

/** The states a workspace can be in. */
export enum BatchSpecWorkspaceState {
    /**
     * The workspace will not be enqueued for execution, because either the
     * workspace is unsupported/ignored or has 0 steps to execute.
     */
    SKIPPED = 'SKIPPED',
    /** The workspace is not yet enqueued for execution. */
    PENDING = 'PENDING',
    /** Not yet started executing. Will be picked up by a worker eventually. */
    QUEUED = 'QUEUED',
    /** Currently executing on the workspace. */
    PROCESSING = 'PROCESSING',
    /** A fatal error occured while executing. No retries will be made. */
    FAILED = 'FAILED',
    /** Execution finished successfully. */
    COMPLETED = 'COMPLETED',
    /** Execution is being canceled. This is an async process. */
    CANCELING = 'CANCELING',
    /** Execution has been canceled. */
    CANCELED = 'CANCELED',
}

/** The state of the batch change. */
export enum BatchChangeState {
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
    DRAFT = 'DRAFT',
}

/** The available types of jobs that can be run over a batch change. */
export enum BulkOperationType {
    /** Bulk post comments over all involved changesets. */
    COMMENT = 'COMMENT',
    /** Bulk detach changesets from a batch change. */
    DETACH = 'DETACH',
    /** Bulk reenqueue failed changesets. */
    REENQUEUE = 'REENQUEUE',
    /** Bulk merge changesets. */
    MERGE = 'MERGE',
    /** Bulk close changesets. */
    CLOSE = 'CLOSE',
    /** Bulk publish changesets. */
    PUBLISH = 'PUBLISH',
}

/** All valid states a bulk operation can be in. */
export enum BulkOperationState {
    /** The bulk operation is still processing on some changesets. */
    PROCESSING = 'PROCESSING',
    /** No operations are still running and all of them finished without error. */
    COMPLETED = 'COMPLETED',
    /** No operations are still running and at least one of them finished with an error. */
    FAILED = 'FAILED',
}

/** The possible states of a batch spec. */
export enum BatchSpecState {
    /** The spec is not yet enqueued for processing. */
    PENDING = 'PENDING',
    /** This spec is being processed. */
    PROCESSING = 'PROCESSING',
    /** This spec failed to be processed. */
    FAILED = 'FAILED',
    /** This spec was processed successfully. */
    COMPLETED = 'COMPLETED',
    /** This spec is queued to be processed. */
    QUEUED = 'QUEUED',
    /** The execution is being canceled. */
    CANCELING = 'CANCELING',
    /** The execution has been canceled. */
    CANCELED = 'CANCELED',
}

/** The possible sources of a batch spec. */
export enum BatchSpecSource {
    /** The spec was created from the local src-cli workflow. */
    LOCAL = 'LOCAL',
    /**
     * This spec was created for remote server-side execution, e.g. from the web UI editor,
     * or with src batch remote.
     */
    REMOTE = 'REMOTE',
}

/**
 * A ChangesetSpecPublicationStateInput is a tuple containing a changeset spec ID
 * and its desired UI publication state.
 */
export interface ChangesetSpecPublicationStateInput {
    /** The changeset spec ID. */
    changesetSpec: Scalars['ID']
    /** The desired publication state. */
    publicationState: Scalars['PublishedValue']
}

/** The priority of an email action. */
export enum MonitorEmailPriority {
    NORMAL = 'NORMAL',
    CRITICAL = 'CRITICAL',
}

/** Supported status of monitor events. */
export enum EventStatus {
    PENDING = 'PENDING',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
}

/** The input required to create a code monitor. */
export interface MonitorInput {
    /**
     * The namespace represents the owner of the code monitor.
     * Owners can either be users or organizations.
     */
    namespace: Scalars['ID']
    /** A meaningful description of the code monitor. */
    description: Scalars['String']
    /** Whether the code monitor is enabled or not. */
    enabled: Scalars['Boolean']
}

/** The input required to edit a code monitor. */
export interface MonitorEditInput {
    /** The id of the monitor. */
    id: Scalars['ID']
    /** The desired state after the udpate. */
    update: MonitorInput
}

/** The input required to create a trigger. */
export interface MonitorTriggerInput {
    /** The query string. */
    query: Scalars['String']
}

/** The input required to edit a trigger. */
export interface MonitorEditTriggerInput {
    /** The id of the Trigger. */
    id: Scalars['ID']
    /** The desired state after the udpate. */
    update: MonitorTriggerInput
}

/** The input required to create an action. */
export interface MonitorActionInput {
    /** An email action. */
    email?: Maybe<MonitorEmailInput>
    /** A webhook action. */
    webhook?: Maybe<MonitorWebhookInput>
    /** A Slack webhook action. */
    slackWebhook?: Maybe<MonitorSlackWebhookInput>
}

/** The input required to create an email action. */
export interface MonitorEmailInput {
    /** Whether the email action is enabled or not. */
    enabled: Scalars['Boolean']
    /** Whether to include the result contents in the email message */
    includeResults: Scalars['Boolean']
    /** The priority of the email. */
    priority: MonitorEmailPriority
    /** A list of users or orgs which will receive the email. */
    recipients: Array<Scalars['ID']>
    /** Use header to automatically approve the message in a read-only or moderated mailing list. */
    header: Scalars['String']
}

/** The input required to create a webhook action. */
export interface MonitorWebhookInput {
    /** Whether the webhook action is enabled or not. */
    enabled: Scalars['Boolean']
    /** Whether to include the result contents in webhook payload. */
    includeResults: Scalars['Boolean']
    /** The URL that will receive a payload when the action is triggered. */
    url: Scalars['String']
}

/** The input required to create a Slack webhook action. */
export interface MonitorSlackWebhookInput {
    /** Whether the Slack webhook action is enabled or not. */
    enabled: Scalars['Boolean']
    /** Whether to include the result contents in Slack notification message. */
    includeResults: Scalars['Boolean']
    /** The URL that will receive a payload when the action is triggered. */
    url: Scalars['String']
}

/** The input required to edit an action. */
export interface MonitorEditActionInput {
    /** An email action. */
    email?: Maybe<MonitorEditEmailInput>
    /** A webhook action. */
    webhook?: Maybe<MonitorEditWebhookInput>
    /** A Slack webhook action. */
    slackWebhook?: Maybe<MonitorEditSlackWebhookInput>
}

/** The input required to edit an email action. */
export interface MonitorEditEmailInput {
    /**
     * The id of an email action. If unset, this will
     * be treated as a new email action and be created
     * rather than updated.
     */
    id?: Maybe<Scalars['ID']>
    /** The desired state after the update. */
    update: MonitorEmailInput
}

/** The input required to edit a webhook action. */
export interface MonitorEditWebhookInput {
    /**
     * The id of a webhook action. If unset, this will
     * be treated as a new webhook action and be created
     * rather than updated.
     */
    id?: Maybe<Scalars['ID']>
    /** The desired state after the update. */
    update: MonitorWebhookInput
}

/** The input required to edit a Slack webhook action. */
export interface MonitorEditSlackWebhookInput {
    /**
     * The id of a Slack webhook action. If unset, this will
     * be treated as a new Slack webhook action and be created
     * rather than updated.
     */
    id?: Maybe<Scalars['ID']>
    /** The desired state after the update. */
    update: MonitorSlackWebhookInput
}

/** The state an LSIF upload can be in. */
export enum LSIFUploadState {
    /** This upload is being processed. */
    PROCESSING = 'PROCESSING',
    /** This upload failed to be processed. */
    ERRORED = 'ERRORED',
    /** This upload was processed successfully. */
    COMPLETED = 'COMPLETED',
    /** This upload is queued to be processed later. */
    QUEUED = 'QUEUED',
    /** This upload is currently being transferred to Sourcegraph. */
    UPLOADING = 'UPLOADING',
    /**
     * This upload is queued for deletion. This upload was previously in the
     * COMPLETED state and evicted, replaced by a newer upload, or deleted by
     * a user. This upload is able to answer code intelligence queries until
     * the commit graph of the upload's repository is next calculated, at which
     * point the upload will become unreachable.
     */
    DELETING = 'DELETING',
    /**
     * This upload is deleted and its metadata is reconstructed from existing
     * audit log entries.
     */
    DELETED = 'DELETED',
}

/** Denotes the type of operation of a given log entry. */
export enum AuditLogOperation {
    /** Denotes this log entry represents an INSERT query. */
    CREATE = 'CREATE',
    /** Denotes this log entry represents an UPDATE query. */
    MODIFY = 'MODIFY',
}

/** The state an LSIF index can be in. */
export enum LSIFIndexState {
    /** This index is being processed. */
    PROCESSING = 'PROCESSING',
    /** This index failed to be processed. */
    ERRORED = 'ERRORED',
    /** This index was processed successfully. */
    COMPLETED = 'COMPLETED',
    /** This index is queued to be processed later. */
    QUEUED = 'QUEUED',
}

/** Denotes the confidence in the correctness of the proposed index target. */
export enum InferedPreciseSupportLevel {
    /**
     * The language is known to have an LSIF indexer associated with it
     * but this may not be the directory from which it should be invoked.
     * Relevant build tool configuration may be available at a parent directory.
     */
    LANGUAGE_SUPPORTED = 'LANGUAGE_SUPPORTED',
    /**
     * Relevant build tool configuration files were located that indicate
     * a good possibility of this directory being where an LSIF indexer
     * could be invoked, however we have or can not infer a potentially complete
     * auto indexing job configuration.
     */
    PROJECT_STRUCTURE_SUPPORTED = 'PROJECT_STRUCTURE_SUPPORTED',
    /**
     * An auto-indexing job configuration was able to be infered for this
     * directory that has a high likelyhood of being complete enough to result
     * in an LSIF index.
     */
    INDEX_JOB_INFERED = 'INDEX_JOB_INFERED',
}

/** Ownership level of the recommended precise code-intel indexer. */
export enum PreciseSupportLevel {
    /** When there is no known indexer. */
    UNKNOWN = 'UNKNOWN',
    /** When the recommended indexer is maintained by us. */
    NATIVE = 'NATIVE',
    /**
     * When the recommended indexer is maintained by a third-party
     * but is recommended over a native indexer, where one exists.
     */
    THIRD_PARTY = 'THIRD_PARTY',
}

/**
 * Tiered list of types of search-based support for a language. This may be expanded as different
 * indexing methods are introduced.
 */
export enum SearchBasedSupportLevel {
    /** The language has no configured search-based code-intel support. */
    UNSUPPORTED = 'UNSUPPORTED',
    /** Universal-ctags is used for indexing this language. */
    BASIC = 'BASIC',
}

/**
 * An input type that describes a product license to be generated and signed.
 * FOR INTERNAL USE ONLY.
 */
export interface ProductLicenseInput {
    /** The tags that indicate which features are activated by this license. */
    tags: Array<Scalars['String']>
    /** The number of users for which this product subscription is valid. */
    userCount: Scalars['Int']
    /** The expiration date of this product license, expressed as the number of seconds since the epoch. */
    expiresAt: Scalars['Int']
}

/**
 * An input type that describes a product subscription to be purchased. Corresponds to
 * ProductSubscriptionInvoiceItem.
 * FOR INTERNAL USE ONLY.
 */
export interface ProductSubscriptionInput {
    /**
     * The billing plan ID for the subscription (ProductPlan.billingPlanID). This also specifies the
     * billing product, because a plan is associated with its product in the billing system.
     */
    billingPlanID: Scalars['String']
    /** This subscription's user count. */
    userCount: Scalars['Int']
}

/** Input object for creating a new dashboard. */
export interface CreateInsightsDashboardInput {
    /** Dashboard title. */
    title: Scalars['String']
    /** Permissions to grant to the dashboard. */
    grants: InsightsPermissionGrantsInput
}

/** Input object for updating a dashboard. */
export interface UpdateInsightsDashboardInput {
    /** Dashboard title. */
    title?: Maybe<Scalars['String']>
    /** Permissions to grant to the dashboard. */
    grants?: Maybe<InsightsPermissionGrantsInput>
}

/** Input object for permissions to grant. */
export interface InsightsPermissionGrantsInput {
    /** Specific users to grant permissions to. */
    users?: Maybe<Array<Scalars['ID']>>
    /** Organizations to grant permissions to. */
    organizations?: Maybe<Array<Scalars['ID']>>
    /** Set global to true to grant global permission. */
    global?: Maybe<Scalars['Boolean']>
}

/** Input object for adding insight view to dashboard. */
export interface AddInsightViewToDashboardInput {
    /** ID of the insight view to attach to the dashboard */
    insightViewId: Scalars['ID']
    /** ID of the dashboard. */
    dashboardId: Scalars['ID']
}

/** Input object for adding insight view to dashboard. */
export interface RemoveInsightViewFromDashboardInput {
    /** ID of the insight view to remove from the dashboard */
    insightViewId: Scalars['ID']
    /** ID of the dashboard. */
    dashboardId: Scalars['ID']
}

/** Input object for update insight series mutation. */
export interface UpdateInsightSeriesInput {
    /** Unique ID for the series. */
    seriesId: Scalars['String']
    /** The desired activity state (enabled or disabled) for the series. */
    enabled?: Maybe<Scalars['Boolean']>
}

/** A custom time scope for an insight data series. */
export interface TimeScopeInput {
    /** Sets a time scope using a step interval (intervals of time). */
    stepInterval?: Maybe<TimeIntervalStepInput>
}

/** A time scope defined using a time interval (ex. 5 days) */
export interface TimeIntervalStepInput {
    /** The time unit for the interval. */
    unit: TimeIntervalStepUnit
    /** The value for the interval. */
    value: Scalars['Int']
}

/** Time interval units. */
export enum TimeIntervalStepUnit {
    HOUR = 'HOUR',
    DAY = 'DAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    YEAR = 'YEAR',
}

/** A custom repository scope for an insight data series. */
export interface RepositoryScopeInput {
    /** The list of repositories included in this scope. */
    repositories: Array<Scalars['String']>
}

/** Options for a line chart */
export interface LineChartOptionsInput {
    /** The chart title. */
    title?: Maybe<Scalars['String']>
}

/** Input for a line chart search insight. */
export interface LineChartSearchInsightInput {
    /** The list of data series to create (or add) to this insight. */
    dataSeries: Array<LineChartSearchInsightDataSeriesInput>
    /** The options for this line chart. */
    options: LineChartOptionsInput
    /** The dashboard IDs to associate this insight with once created. */
    dashboards?: Maybe<Array<Scalars['ID']>>
    /** The default values for filters and aggregates for this line chart. */
    viewControls?: Maybe<InsightViewControlsInput>
}

/** Input for updating a line chart search insight. */
export interface UpdateLineChartSearchInsightInput {
    /** The complete list of data series on this line chart. Note: excluding a data series will remove it. */
    dataSeries: Array<LineChartSearchInsightDataSeriesInput>
    /** The presentation options for this line chart. */
    presentationOptions: LineChartOptionsInput
    /** The default values for filters and aggregates for this line chart. */
    viewControls: InsightViewControlsInput
}

/** Input for the default values for filters and aggregates for an insight. */
export interface InsightViewControlsInput {
    /** Input for the default filters for an insight. */
    filters: InsightViewFiltersInput
    /** Input for series' sort order. */
    seriesDisplayOptions: SeriesDisplayOptionsInput
}

/** Input type for series display options. */
export interface SeriesDisplayOptionsInput {
    /** Sort options for the series. */
    sortOptions?: Maybe<SeriesSortOptionsInput>
    /** Max number of series to return. */
    limit?: Maybe<Scalars['Int']>
}

/** Sort mode for series. */
export enum SeriesSortMode {
    RESULT_COUNT = 'RESULT_COUNT',
    LEXICOGRAPHICAL = 'LEXICOGRAPHICAL',
    DATE_ADDED = 'DATE_ADDED',
}

/** Sort direction for series. */
export enum SeriesSortDirection {
    ASC = 'ASC',
    DESC = 'DESC',
}

/** Input type for series sort options. */
export interface SeriesSortOptionsInput {
    /** Sort mode for series. */
    mode: SeriesSortMode
    /** Sort direction for series. */
    direction: SeriesSortDirection
}

/** Input for the default values by which the insight is filtered. */
export interface InsightViewFiltersInput {
    /** A regex string for which to include repositories in a filter. */
    includeRepoRegex?: Maybe<Scalars['String']>
    /** A regex string for which to exclude repositories in a filter. */
    excludeRepoRegex?: Maybe<Scalars['String']>
    /** A list of query based search contexts to include in the filters for the view. */
    searchContexts?: Maybe<Array<Scalars['String']>>
}

/** Input for a line chart search insight data series. */
export interface LineChartSearchInsightDataSeriesInput {
    /** Unique ID for the series. Omit this field if it's a new series. */
    seriesId?: Maybe<Scalars['String']>
    /** The query string. */
    query: Scalars['String']
    /** Options for this line chart data series. */
    options: LineChartDataSeriesOptionsInput
    /** The scope of repositories. */
    repositoryScope: RepositoryScopeInput
    /** The scope of time. */
    timeScope: TimeScopeInput
    /** Whether or not to generate the timeseries results from the query capture groups. Defaults to false if not provided. */
    generatedFromCaptureGroups?: Maybe<Scalars['Boolean']>
    /** The field to group results by. (For compute powered insights only.) This field is experimental and should be considered unstable in the API. */
    groupBy?: Maybe<GroupByField>
}

/** Fields that can be grouped on for compute powered insights. */
export enum GroupByField {
    REPO = 'REPO',
    LANG = 'LANG',
    PATH = 'PATH',
    AUTHOR = 'AUTHOR',
    DATE = 'DATE',
}

/** Options for a line chart data series */
export interface LineChartDataSeriesOptionsInput {
    /** The label for the data series. */
    label?: Maybe<Scalars['String']>
    /** The line color for the data series. */
    lineColor?: Maybe<Scalars['String']>
}

/** Input for a pie chart search insight */
export interface PieChartSearchInsightInput {
    /** The query string. */
    query: Scalars['String']
    /** The scope of repositories. */
    repositoryScope: RepositoryScopeInput
    /** Options for this pie chart. */
    presentationOptions: PieChartOptionsInput
    /** The dashboard IDs to associate this insight with once created. */
    dashboards?: Maybe<Array<Scalars['ID']>>
}

/** Input for updating a pie chart search insight */
export interface UpdatePieChartSearchInsightInput {
    /** The query string. */
    query: Scalars['String']
    /** The scope of repositories. */
    repositoryScope: RepositoryScopeInput
    /** Options for this pie chart. */
    presentationOptions: PieChartOptionsInput
}

/** Options for a pie chart */
export interface PieChartOptionsInput {
    /** The title for the pie chart. */
    title: Scalars['String']
    /**
     * The threshold for which groups fall into the "other category". Only categories with a percentage greater than
     * this value will be separately rendered.
     */
    otherThreshold: Scalars['Float']
}

/** Required input to generate a time series for a search insight using live preview. */
export interface SearchInsightLivePreviewInput {
    /** The query string. */
    query: Scalars['String']
    /** The desired label for the series. Will be overwritten when series are dynamically generated. */
    label: Scalars['String']
    /** The scope of repositories. */
    repositoryScope: RepositoryScopeInput
    /** The scope of time. */
    timeScope: TimeScopeInput
    /** Whether or not to generate the timeseries results from the query capture groups. */
    generatedFromCaptureGroups: Scalars['Boolean']
    /** Use this field to specify a compute insight. Note: this is experimental and should be considered unstable */
    groupBy?: Maybe<GroupByField>
}

/** Required input to generate a live preview for an insight. */
export interface SearchInsightPreviewInput {
    /** The scope of repositories. */
    repositoryScope: RepositoryScopeInput
    /** The scope of time. */
    timeScope: TimeScopeInput
    /** The series to generate previews for */
    series: Array<SearchSeriesPreviewInput>
}

/** Required input to generate a live preview for a series. */
export interface SearchSeriesPreviewInput {
    /** The query string. */
    query: Scalars['String']
    /** The desired label for the series. Will be overwritten when series are dynamically generated. */
    label: Scalars['String']
    /** Whether or not to generate the timeseries results from the query capture groups. */
    generatedFromCaptureGroups: Scalars['Boolean']
    /** Use this field to specify a compute insight. Note: this is experimental and should be considered unstable */
    groupBy?: Maybe<GroupByField>
}

/** Supported aggregation modes for search aggregations */
export enum SearchAggregationMode {
    REPO = 'REPO',
    PATH = 'PATH',
    AUTHOR = 'AUTHOR',
    CAPTURE_GROUP = 'CAPTURE_GROUP',
}

/** An enum to describe the reasons why a search aggregations are not available */
export enum NotAvailableReasonType {
    INVALID_QUERY = 'INVALID_QUERY',
    INVALID_AGGREGATION_MODE_FOR_QUERY = 'INVALID_AGGREGATION_MODE_FOR_QUERY',
    TIMEOUT_EXTENSION_AVAILABLE = 'TIMEOUT_EXTENSION_AVAILABLE',
    TIMEOUT_NO_EXTENSION_AVAILABLE = 'TIMEOUT_NO_EXTENSION_AVAILABLE',
    OTHER_ERROR = 'OTHER_ERROR',
}

/** NotebooksOrderBy enumerates the ways notebooks can be ordered. */
export enum NotebooksOrderBy {
    NOTEBOOK_UPDATED_AT = 'NOTEBOOK_UPDATED_AT',
    NOTEBOOK_CREATED_AT = 'NOTEBOOK_CREATED_AT',
    NOTEBOOK_STAR_COUNT = 'NOTEBOOK_STAR_COUNT',
}

/** Input to create a line range for a file block. */
export interface CreateFileBlockLineRangeInput {
    /** The first line to fetch (0-indexed, inclusive). */
    startLine: Scalars['Int']
    /** The last line to fetch (0-indexed, exclusive). */
    endLine: Scalars['Int']
}

/** CreateFileBlockInput contains the information necessary to create a file block. */
export interface CreateFileBlockInput {
    /** Name of the repository, e.g. "github.com/sourcegraph/sourcegraph". */
    repositoryName: Scalars['String']
    /** Path within the repository, e.g. "client/web/file.tsx". */
    filePath: Scalars['String']
    /**
     * An optional revision, e.g. "pr/feature-1", "a9505a2947d3df53558e8c88ff8bcef390fc4e3e".
     * If omitted, we use the latest revision (HEAD).
     */
    revision?: Maybe<Scalars['String']>
    /** An optional line range. If omitted, we display the entire file. */
    lineRange?: Maybe<CreateFileBlockLineRangeInput>
}

/** CreateSymbolBlockInput contains the information necessary to create a symbol block. */
export interface CreateSymbolBlockInput {
    /** Name of the repository, e.g. "github.com/sourcegraph/sourcegraph". */
    repositoryName: Scalars['String']
    /** Path within the repository, e.g. "client/web/file.tsx". */
    filePath: Scalars['String']
    /**
     * An optional revision, e.g. "pr/feature-1", "a9505a2947d3df53558e8c88ff8bcef390fc4e3e".
     * If omitted, we use the latest revision (HEAD).
     */
    revision?: Maybe<Scalars['String']>
    /** Number of lines to show before and after the matched symbol line. */
    lineContext: Scalars['Int']
    /** The symbol name. */
    symbolName: Scalars['String']
    /** Name of the symbol container. */
    symbolContainerName: Scalars['String']
    /** The symbol kind. */
    symbolKind: SymbolKind
}

/** Enum of possible block types. */
export enum NotebookBlockType {
    MARKDOWN = 'MARKDOWN',
    QUERY = 'QUERY',
    FILE = 'FILE',
    SYMBOL = 'SYMBOL',
    COMPUTE = 'COMPUTE',
}

/**
 * GraphQL does not accept union types as inputs, so we have to use
 * all possible optional inputs with an enum to select the actual block input we want to use.
 */
export interface CreateNotebookBlockInput {
    /** ID of the block. */
    id: Scalars['String']
    /** Block type. */
    type: NotebookBlockType
    /** Markdown input. */
    markdownInput?: Maybe<Scalars['String']>
    /** Query input. */
    queryInput?: Maybe<Scalars['String']>
    /** File input. */
    fileInput?: Maybe<CreateFileBlockInput>
    /** Symbol input. */
    symbolInput?: Maybe<CreateSymbolBlockInput>
    /** Compute input. */
    computeInput?: Maybe<Scalars['String']>
}

/** Input for a new notebook. */
export interface NotebookInput {
    /** The title of the notebook. */
    title: Scalars['String']
    /** Array of notebook blocks. */
    blocks: Array<CreateNotebookBlockInput>
    /**
     * Notebook namespace (user or org). Controls the visibility of the notebook
     * and who can edit the notebook. Only the notebook creator can update the namespace.
     */
    namespace: Scalars['ID']
    /**
     * Public property controls the visibility of the notebook. A public notebook is available to
     * any user on the instance. Private notebooks are only available to their creators.
     */
    public: Scalars['Boolean']
}

/** A description of a user event. */
export interface Event {
    /** The name of the event. */
    event: Scalars['String']
    /** The randomly generated unique user ID stored in a browser cookie. */
    userCookieID: Scalars['String']
    /** The first sourcegraph URL visited by the user, stored in a browser cookie. */
    firstSourceURL?: Maybe<Scalars['String']>
    /** The last sourcegraph URL visited by the user, stored in a browser cookie. */
    lastSourceURL?: Maybe<Scalars['String']>
    /** The URL when the event was logged. */
    url: Scalars['String']
    /** The source of the event. */
    source: EventSource
    /**
     * An optional cohort ID to identify the user as part of a specific A/B test.
     * The cohort ID is expected to be a date in the form YYYY-MM-DD
     */
    cohortID?: Maybe<Scalars['String']>
    /**
     * An optional referrer parameter for the user's current session.
     * Only captured and stored on Sourcegraph Cloud.
     */
    referrer?: Maybe<Scalars['String']>
    /** The additional argument information. */
    argument?: Maybe<Scalars['String']>
    /**
     * Public argument information. PRIVACY: Do NOT include any potentially private information in this field.
     * These properties get sent to our analytics tools for Cloud, so must not include private information,
     * such as search queries or repository names.
     */
    publicArgument?: Maybe<Scalars['String']>
    /** Device ID used for Amplitude analytics. Used on Sourcegraph Cloud only. */
    deviceID?: Maybe<Scalars['String']>
    /**
     * Event ID used to deduplicate events that occur simultaneously in Amplitude analytics.
     * See https://developers.amplitude.com/docs/http-api-v2#optional-keys. Used on Sourcegraph Cloud only.
     */
    eventID?: Maybe<Scalars['Int']>
    /**
     * Insert ID used to deduplicate events that re-occur in the event of retries or
     * backfills in Amplitude analytics. See https://developers.amplitude.com/docs/http-api-v2#optional-keys.
     * Used on Sourcegraph Cloud only.
     */
    insertID?: Maybe<Scalars['String']>
}

/** A new external service. */
export interface AddExternalServiceInput {
    /** The kind of the external service. */
    kind: ExternalServiceKind
    /** The display name of the external service. */
    displayName: Scalars['String']
    /** The JSON configuration of the external service. */
    config: Scalars['String']
    /**
     * The namespace this external service belongs to.
     * This can be used both for a user and an organization.
     */
    namespace?: Maybe<Scalars['ID']>
}

/** Fields to update for an existing external service. */
export interface UpdateExternalServiceInput {
    /** The id of the external service to update. */
    id: Scalars['ID']
    /** The updated display name, if provided. */
    displayName?: Maybe<Scalars['String']>
    /** The updated config, if provided. */
    config?: Maybe<Scalars['String']>
}

/** Describes options for rendering Markdown. */
export interface MarkdownOptions {
    /** A dummy null value (empty input types are not allowed yet). */
    alwaysNil?: Maybe<Scalars['String']>
}

/** The product sources where events can come from. */
export enum EventSource {
    WEB = 'WEB',
    CODEHOSTINTEGRATION = 'CODEHOSTINTEGRATION',
    BACKEND = 'BACKEND',
    STATICWEB = 'STATICWEB',
    IDEEXTENSION = 'IDEEXTENSION',
}

/**
 * Input for Mutation.settingsMutation, which contains fields that all settings (global, organization, and user
 * settings) mutations need.
 */
export interface SettingsMutationGroupInput {
    /** The subject whose settings to mutate (organization, user, etc.). */
    subject: Scalars['ID']
    /**
     * The ID of the last-known settings known to the client, or null if there is none. This field is used to
     * prevent race conditions when there are concurrent editors.
     */
    lastID?: Maybe<Scalars['Int']>
}

/** An edit to a JSON property in a settings JSON object. The JSON property to edit can be nested. */
export interface SettingsEdit {
    /**
     * The key path of the property to update.
     *
     * Inserting into an existing array is not yet supported.
     */
    keyPath: Array<KeyPathSegment>
    /**
     * The new JSON-encoded value to insert. If the field's value is not set, the property is removed. (This is
     * different from the field's value being the JSON null value.)
     *
     * When the value is a non-primitive type, it must be specified using a GraphQL variable, not an inline literal,
     * or else the GraphQL parser will return an error.
     */
    value?: Maybe<Scalars['JSONValue']>
    /**
     * Whether to treat the value as a JSONC-encoded string, which makes it possible to perform an edit that
     * preserves (or adds/removes) comments.
     */
    valueIsJSONCEncodedString?: Maybe<Scalars['Boolean']>
}

/**
 * DEPRECATED: This type was renamed to SettingsEdit.
 * NOTE: GraphQL does not support @deprecated directives on INPUT_FIELD_DEFINITION (input fields).
 */
export interface ConfigurationEdit {
    /** DEPRECATED */
    keyPath: Array<KeyPathSegment>
    /** DEPRECATED */
    value?: Maybe<Scalars['JSONValue']>
    /** DEPRECATED */
    valueIsJSONCEncodedString?: Maybe<Scalars['Boolean']>
}

/**
 * A segment of a key path that locates a nested JSON value in a root JSON value. Exactly one field in each
 * KeyPathSegment must be non-null.
 * For example, in {"a": [0, {"b": 3}]}, the value 3 is located at the key path ["a", 1, "b"].
 */
export interface KeyPathSegment {
    /** The name of the property in the object at this location to descend into. */
    property?: Maybe<Scalars['String']>
    /** The index of the array at this location to descend into. */
    index?: Maybe<Scalars['Int']>
}

/** Possible answers to "You use Sourcegraph to..." in the NPS Survey. */
export enum SurveyUseCase {
    UNDERSTAND_NEW_CODE = 'UNDERSTAND_NEW_CODE',
    FIX_SECURITY_VULNERABILITIES = 'FIX_SECURITY_VULNERABILITIES',
    REUSE_CODE = 'REUSE_CODE',
    RESPOND_TO_INCIDENTS = 'RESPOND_TO_INCIDENTS',
    IMPROVE_CODE_QUALITY = 'IMPROVE_CODE_QUALITY',
}

/** Input for a user satisfaction (NPS) survey submission. */
export interface SurveySubmissionInput {
    /**
     * User-provided email address, if there is no currently authenticated user. If there is, this value
     * will not be used.
     */
    email?: Maybe<Scalars['String']>
    /** User's likelihood of recommending Sourcegraph to a friend, from 0-10. */
    score: Scalars['Int']
    /** The answer to "What do you use Sourcegraph for?". */
    otherUseCase?: Maybe<Scalars['String']>
    /** The answer to "What would make Sourcegraph better?" */
    better?: Maybe<Scalars['String']>
}

/** Input for a happiness feedback submission. */
export interface HappinessFeedbackSubmissionInput {
    /** User's happiness rating, from 1-4. */
    score: Scalars['Int']
    /** The answer to "What's going well? What could be better?". */
    feedback?: Maybe<Scalars['String']>
    /** The path that the happiness feedback will be submitted from. */
    currentPath?: Maybe<Scalars['String']>
}

/** The version of the search syntax. */
export enum SearchVersion {
    /** Search syntax that defaults to regexp search. */
    V1 = 'V1',
    /** Search syntax that defaults to literal-only search. */
    V2 = 'V2',
    /** Search syntax that defaults to standard search. */
    V3 = 'V3',
}

/** The search pattern type. */
export enum SearchPatternType {
    standard = 'standard',
    literal = 'literal',
    regexp = 'regexp',
    structural = 'structural',
    lucky = 'lucky',
    keyword = 'keyword',
}

/** A specific kind of external service. */
export enum ExternalServiceKind {
    AWSCODECOMMIT = 'AWSCODECOMMIT',
    BITBUCKETCLOUD = 'BITBUCKETCLOUD',
    BITBUCKETSERVER = 'BITBUCKETSERVER',
    GERRIT = 'GERRIT',
    GITHUB = 'GITHUB',
    GITLAB = 'GITLAB',
    GITOLITE = 'GITOLITE',
    GOMODULES = 'GOMODULES',
    JVMPACKAGES = 'JVMPACKAGES',
    NPMPACKAGES = 'NPMPACKAGES',
    OTHER = 'OTHER',
    PAGURE = 'PAGURE',
    PERFORCE = 'PERFORCE',
    PHABRICATOR = 'PHABRICATOR',
    PYTHONPACKAGES = 'PYTHONPACKAGES',
    RUSTPACKAGES = 'RUSTPACKAGES',
}

/** The possible states of an external service sync job. */
export enum ExternalServiceSyncJobState {
    /** Not yet started. Will be picked up by a worker eventually. */
    QUEUED = 'QUEUED',
    /** Currently syncing. */
    PROCESSING = 'PROCESSING',
    /** An error occured while syncing. Will be retried eventually. */
    ERRORED = 'ERRORED',
    /** A fatal error occured while syncing. No retries will be made. */
    FAILED = 'FAILED',
    /** Sync finished successfully. */
    COMPLETED = 'COMPLETED',
}

/** The type of content in a hunk line. */
export enum DiffHunkLineType {
    /** Added line. */
    ADDED = 'ADDED',
    /** Unchanged line. */
    UNCHANGED = 'UNCHANGED',
    /** Deleted line. */
    DELETED = 'DELETED',
}

/** A specific highlighted line range to fetch. */
export interface HighlightLineRange {
    /**
     * The first line to fetch (0-indexed, inclusive). Values outside the bounds of the file will
     * automatically be clamped within the valid range.
     */
    startLine: Scalars['Int']
    /**
     * The last line to fetch (0-indexed, inclusive). Values outside the bounds of the file will
     * automatically be clamped within the valid range.
     */
    endLine: Scalars['Int']
}

/** Represents the severity level of a diagnostic. */
export enum DiagnosticSeverity {
    ERROR = 'ERROR',
    WARNING = 'WARNING',
    INFORMATION = 'INFORMATION',
    HINT = 'HINT',
}

/**
 * All possible kinds of symbols. This set matches that of the Language Server Protocol
 * (https://microsoft.github.io/language-server-protocol/specification#workspace_symbol).
 */
export enum SymbolKind {
    UNKNOWN = 'UNKNOWN',
    FILE = 'FILE',
    MODULE = 'MODULE',
    NAMESPACE = 'NAMESPACE',
    PACKAGE = 'PACKAGE',
    CLASS = 'CLASS',
    METHOD = 'METHOD',
    PROPERTY = 'PROPERTY',
    FIELD = 'FIELD',
    CONSTRUCTOR = 'CONSTRUCTOR',
    ENUM = 'ENUM',
    INTERFACE = 'INTERFACE',
    FUNCTION = 'FUNCTION',
    VARIABLE = 'VARIABLE',
    CONSTANT = 'CONSTANT',
    STRING = 'STRING',
    NUMBER = 'NUMBER',
    BOOLEAN = 'BOOLEAN',
    ARRAY = 'ARRAY',
    OBJECT = 'OBJECT',
    KEY = 'KEY',
    NULL = 'NULL',
    ENUMMEMBER = 'ENUMMEMBER',
    STRUCT = 'STRUCT',
    EVENT = 'EVENT',
    OPERATOR = 'OPERATOR',
    TYPEPARAMETER = 'TYPEPARAMETER',
}

/** All possible types of Git refs. */
export enum GitRefType {
    /** A Git branch (in refs/heads/). */
    GIT_BRANCH = 'GIT_BRANCH',
    /** A Git tag (in refs/tags/). */
    GIT_TAG = 'GIT_TAG',
    /** A Git ref that is neither a branch nor tag. */
    GIT_REF_OTHER = 'GIT_REF_OTHER',
}

/** Ordering options for Git refs. */
export enum GitRefOrder {
    /** By the authored or committed at date, whichever is more recent. */
    AUTHORED_OR_COMMITTED_AT = 'AUTHORED_OR_COMMITTED_AT',
}

/** All possible types of Git objects. */
export enum GitObjectType {
    /** A Git commit object. */
    GIT_COMMIT = 'GIT_COMMIT',
    /** A Git tag object. */
    GIT_TAG = 'GIT_TAG',
    /** A Git tree object. */
    GIT_TREE = 'GIT_TREE',
    /** A Git blob object. */
    GIT_BLOB = 'GIT_BLOB',
    /** A Git object of unknown type. */
    GIT_UNKNOWN = 'GIT_UNKNOWN',
}

/** The format and highlighting to use when requesting highlighting information for a file. */
export enum HighlightResponseFormat {
    /** HTML formatted file content without syntax highlighting. */
    HTML_PLAINTEXT = 'HTML_PLAINTEXT',
    /** HTML formatted file content with syntax highlighting. */
    HTML_HIGHLIGHT = 'HTML_HIGHLIGHT',
    /** SCIP highlighting information as JSON. */
    JSON_SCIP = 'JSON_SCIP',
}

/** The recipient's possible responses to an invitation to join an organization as a member. */
export enum OrganizationInvitationResponseType {
    /** The invitation was accepted by the recipient. */
    ACCEPT = 'ACCEPT',
    /** The invitation was rejected by the recipient. */
    REJECT = 'REJECT',
}

/** RepositoryOrderBy enumerates the ways a repositories list can be ordered. */
export enum RepositoryOrderBy {
    REPOSITORY_NAME = 'REPOSITORY_NAME',
    REPO_CREATED_AT = 'REPO_CREATED_AT',
    /** deprecated (use the equivalent REPOSITORY_CREATED_AT) */
    REPOSITORY_CREATED_AT = 'REPOSITORY_CREATED_AT',
}

/** The possible types of alerts (Alert.type values). */
export enum AlertType {
    INFO = 'INFO',
    WARNING = 'WARNING',
    ERROR = 'ERROR',
}

/** A user event. */
export enum UserEvent {
    PAGEVIEW = 'PAGEVIEW',
    SEARCHQUERY = 'SEARCHQUERY',
    CODEINTEL = 'CODEINTEL',
    CODEINTELREFS = 'CODEINTELREFS',
    CODEINTELINTEGRATION = 'CODEINTELINTEGRATION',
    CODEINTELINTEGRATIONREFS = 'CODEINTELINTEGRATIONREFS',
    /** Product stages */
    STAGEMANAGE = 'STAGEMANAGE',
    STAGEPLAN = 'STAGEPLAN',
    STAGECODE = 'STAGECODE',
    STAGEREVIEW = 'STAGEREVIEW',
    STAGEVERIFY = 'STAGEVERIFY',
    STAGEPACKAGE = 'STAGEPACKAGE',
    STAGEDEPLOY = 'STAGEDEPLOY',
    STAGECONFIGURE = 'STAGECONFIGURE',
    STAGEMONITOR = 'STAGEMONITOR',
    STAGESECURE = 'STAGESECURE',
    STAGEAUTOMATE = 'STAGEAUTOMATE',
}

/** A period of time in which a set of users have been active. */
export enum UserActivePeriod {
    /** Since today at 00:00 UTC. */
    TODAY = 'TODAY',
    /** Since the latest Monday at 00:00 UTC. */
    THIS_WEEK = 'THIS_WEEK',
    /** Since the first day of the current month at 00:00 UTC. */
    THIS_MONTH = 'THIS_MONTH',
    /** All time. */
    ALL_TIME = 'ALL_TIME',
}

/** A pre-defined periods to get site analytics. */
export enum AnalyticsDateRange {
    /** Last 3 monthes date range. */
    LAST_THREE_MONTHS = 'LAST_THREE_MONTHS',
    /** Last month date range. */
    LAST_MONTH = 'LAST_MONTH',
    /** Last week date range. */
    LAST_WEEK = 'LAST_WEEK',
    /** Custom date range. */
    CUSTOM = 'CUSTOM',
}

/** Group site analytics by period. */
export enum AnalyticsGrouping {
    /** Group data by day. */
    DAILY = 'DAILY',
    /** Group data by week. */
    WEEKLY = 'WEEKLY',
}

/** SiteUserOrderBy enumerates the ways a users list can be ordered. */
export enum SiteUserOrderBy {
    USERNAME = 'USERNAME',
    /** User's primary email. */
    EMAIL = 'EMAIL',
    /** The total number of user's event_logs. */
    EVENTS_COUNT = 'EVENTS_COUNT',
    /** The last event_log datetime. */
    LAST_ACTIVE_AT = 'LAST_ACTIVE_AT',
    /** The datetime when user was added to the system. */
    CREATED_AT = 'CREATED_AT',
    /** The datetime when user was soft deleted. */
    DELETED_AT = 'DELETED_AT',
    /** Whether the user is site admin or not. */
    SITE_ADMIN = 'SITE_ADMIN',
}

/** SiteUsersDateRangeInput argument to filter based on date range or date equals to null */
export interface SiteUsersDateRangeInput {
    /** Less than or equal to */
    lte?: Maybe<Scalars['DateTime']>
    /** Greater than or equal to */
    gte?: Maybe<Scalars['DateTime']>
    /** Negation */
    not?: Maybe<Scalars['Boolean']>
    /** Equal to Null */
    empty?: Maybe<Scalars['Boolean']>
}

/** SiteUsersNumberRangeInput argument to filter based on the number range */
export interface SiteUsersNumberRangeInput {
    /** Less than or equal to */
    gte?: Maybe<Scalars['Float']>
    /** Greater than or equal to */
    lte?: Maybe<Scalars['Float']>
}

/** The clone status of a repository. */
export enum CloneStatus {
    NOT_CLONED = 'NOT_CLONED',
    CLONING = 'CLONING',
    CLONED = 'CLONED',
}

/** SearchContextsOrderBy enumerates the ways a search contexts list can be ordered. */
export enum SearchContextsOrderBy {
    SEARCH_CONTEXT_SPEC = 'SEARCH_CONTEXT_SPEC',
    SEARCH_CONTEXT_UPDATED_AT = 'SEARCH_CONTEXT_UPDATED_AT',
}

/** Input for a new search context. */
export interface SearchContextInput {
    /**
     * Search context name. Not the same as the search context spec. Search context namespace and search context name
     * are used to construct the fully-qualified search context spec.
     * Example mappings from search context spec to search context name: global -> global, @user -> user, @org -> org,
     * @user/ctx1 -> ctx1, @org/ctxs/ctx -> ctxs/ctx.
     */
    name: Scalars['String']
    /** Search context description. */
    description: Scalars['String']
    /**
     * Public property controls the visibility of the search context. Public search context is available to
     * any user on the instance. If a public search context contains private repositories, those are filtered out
     * for unauthorized users. Private search contexts are only available to their owners. Private user search context
     * is available only to the user, private org search context is available only to the members of the org, and private
     * instance-level search contexts are available only to site-admins.
     */
    public: Scalars['Boolean']
    /** Namespace of the search context (user or org). If not set, search context is considered instance-level. */
    namespace?: Maybe<Scalars['ID']>
    /**
     * Sourcegraph search query that defines the search context.
     * e.g. "r:^github\.com/org (rev:bar or rev:HEAD) file:^sub/dir"
     */
    query: Scalars['String']
}

/** Input for editing an existing search context. */
export interface SearchContextEditInput {
    /**
     * Search context name. Not the same as the search context spec. Search context namespace and search context name
     * are used to construct the fully-qualified search context spec.
     * Example mappings from search context spec to search context name: global -> global, @user -> user, @org -> org,
     * @user/ctx1 -> ctx1, @org/ctxs/ctx -> ctxs/ctx.
     */
    name: Scalars['String']
    /** Search context description. */
    description: Scalars['String']
    /**
     * Public property controls the visibility of the search context. Public search context is available to
     * any user on the instance. If a public search context contains private repositories, those are filtered out
     * for unauthorized users. Private search contexts are only available to their owners. Private user search context
     * is available only to the user, private org search context is available only to the members of the org, and private
     * instance-level search contexts are available only to site-admins.
     */
    public: Scalars['Boolean']
    /**
     * Sourcegraph search query that defines the search context.
     * e.g. "r:^github\.com/org (rev:bar or rev:HEAD) file:^sub/dir"
     */
    query: Scalars['String']
}

/** Input for a set of revisions to be searched within a repository. */
export interface SearchContextRepositoryRevisionsInput {
    /** ID of the repository to be searched. */
    repositoryID: Scalars['ID']
    /** Revisions in the repository to be searched. */
    revisions: Array<Scalars['String']>
}

/** An enum describing what kind of type a given `__Type` is. */
export enum __TypeKind {
    /** Indicates this type is a scalar. */
    SCALAR = 'SCALAR',
    /** Indicates this type is an object. `fields` and `interfaces` are valid fields. */
    OBJECT = 'OBJECT',
    /** Indicates this type is an interface. `fields`, `interfaces`, and `possibleTypes` are valid fields. */
    INTERFACE = 'INTERFACE',
    /** Indicates this type is a union. `possibleTypes` is a valid field. */
    UNION = 'UNION',
    /** Indicates this type is an enum. `enumValues` is a valid field. */
    ENUM = 'ENUM',
    /** Indicates this type is an input object. `inputFields` is a valid field. */
    INPUT_OBJECT = 'INPUT_OBJECT',
    /** Indicates this type is a list. `ofType` is a valid field. */
    LIST = 'LIST',
    /** Indicates this type is a non-null. `ofType` is a valid field. */
    NON_NULL = 'NON_NULL',
}

export type CurrentAuthStateVariables = Exact<{ [key: string]: never }>

export type CurrentAuthStateResult = {
    __typename?: 'Query'
    currentUser: Maybe<{
        __typename: 'User'
        id: string
        databaseID: number
        username: string
        avatarURL: Maybe<string>
        email: string
        displayName: Maybe<string>
        siteAdmin: boolean
        tags: Array<string>
        url: string
        settingsURL: Maybe<string>
        viewerCanAdminister: boolean
        tosAccepted: boolean
        searchable: boolean
        organizations: {
            __typename?: 'OrgConnection'
            nodes: Array<{
                __typename?: 'Org'
                id: string
                name: string
                displayName: Maybe<string>
                url: string
                settingsURL: Maybe<string>
            }>
        }
        session: { __typename?: 'Session'; canSignOut: boolean }
        emails: Array<{ __typename?: 'UserEmail'; email: string; verified: boolean }>
    }>
}

export type HighlightedFileVariables = Exact<{
    repoName: Scalars['String']
    commitID: Scalars['String']
    filePath: Scalars['String']
    disableTimeout: Scalars['Boolean']
    ranges: Array<HighlightLineRange>
    format: HighlightResponseFormat
}>

export type HighlightedFileResult = {
    __typename?: 'Query'
    repository: Maybe<{
        __typename?: 'Repository'
        commit: Maybe<{
            __typename?: 'GitCommit'
            file: Maybe<
                | {
                      __typename?: 'GitBlob'
                      isDirectory: boolean
                      richHTML: string
                      highlight: { __typename?: 'HighlightedFile'; aborted: boolean; lineRanges: Array<Array<string>> }
                  }
                | {
                      __typename?: 'VirtualFile'
                      isDirectory: boolean
                      richHTML: string
                      highlight: { __typename?: 'HighlightedFile'; aborted: boolean; lineRanges: Array<Array<string>> }
                  }
            >
        }>
    }>
}

export type HighlightedFileVSCEVariables = Exact<{
    repoName: Scalars['String']
    commitID: Scalars['String']
    filePath: Scalars['String']
    disableTimeout: Scalars['Boolean']
    ranges: Array<HighlightLineRange>
}>

export type HighlightedFileVSCEResult = {
    __typename?: 'Query'
    repository: Maybe<{
        __typename?: 'Repository'
        commit: Maybe<{
            __typename?: 'GitCommit'
            file: Maybe<
                | {
                      __typename?: 'GitBlob'
                      isDirectory: boolean
                      richHTML: string
                      highlight: { __typename?: 'HighlightedFile'; aborted: boolean; lineRanges: Array<Array<string>> }
                  }
                | {
                      __typename?: 'VirtualFile'
                      isDirectory: boolean
                      richHTML: string
                      highlight: { __typename?: 'HighlightedFile'; aborted: boolean; lineRanges: Array<Array<string>> }
                  }
            >
        }>
    }>
}

export type ResolveRawRepoNameVariables = Exact<{
    repoName: Scalars['String']
}>

export type ResolveRawRepoNameResult = {
    __typename?: 'Query'
    repository: Maybe<{
        __typename?: 'Repository'
        uri: string
        mirrorInfo: { __typename?: 'MirrorRepositoryInfo'; cloned: boolean }
    }>
}

export type TreeEntriesVariables = Exact<{
    repoName: Scalars['String']
    revision: Scalars['String']
    commitID: Scalars['String']
    filePath: Scalars['String']
    first: Maybe<Scalars['Int']>
}>

export type TreeEntriesResult = {
    __typename?: 'Query'
    repository: Maybe<{
        __typename?: 'Repository'
        commit: Maybe<{ __typename?: 'GitCommit'; tree: Maybe<{ __typename?: 'GitTree' } & TreeFields> }>
    }>
}

export type TreeFields = {
    __typename?: 'GitTree'
    isRoot: boolean
    url: string
    entries: Array<
        | ({ __typename?: 'GitTree' } & TreeEntryFields_GitTree_)
        | ({ __typename?: 'GitBlob' } & TreeEntryFields_GitBlob_)
    >
}

type TreeEntryFields_GitTree_ = {
    __typename?: 'GitTree'
    name: string
    path: string
    isDirectory: boolean
    url: string
    isSingleChild: boolean
    submodule: Maybe<{ __typename?: 'Submodule'; url: string; commit: string }>
}

type TreeEntryFields_GitBlob_ = {
    __typename?: 'GitBlob'
    name: string
    path: string
    isDirectory: boolean
    url: string
    isSingleChild: boolean
    submodule: Maybe<{ __typename?: 'Submodule'; url: string; commit: string }>
}

export type TreeEntryFields = TreeEntryFields_GitTree_ | TreeEntryFields_GitBlob_

export type SettingsCascadeFields = {
    __typename?: 'SettingsCascade'
    final: string
    subjects: Array<
        | {
              __typename: 'User'
              id: string
              username: string
              displayName: Maybe<string>
              settingsURL: Maybe<string>
              viewerCanAdminister: boolean
              latestSettings: Maybe<{ __typename?: 'Settings'; id: number; contents: string }>
          }
        | {
              __typename: 'Org'
              id: string
              name: string
              displayName: Maybe<string>
              settingsURL: Maybe<string>
              viewerCanAdminister: boolean
              latestSettings: Maybe<{ __typename?: 'Settings'; id: number; contents: string }>
          }
        | {
              __typename: 'DefaultSettings'
              settingsURL: Maybe<string>
              viewerCanAdminister: boolean
              latestSettings: Maybe<{ __typename?: 'Settings'; id: number; contents: string }>
          }
        | {
              __typename: 'Site'
              id: string
              siteID: string
              allowSiteSettingsEdits: boolean
              settingsURL: Maybe<string>
              viewerCanAdminister: boolean
              latestSettings: Maybe<{ __typename?: 'Settings'; id: number; contents: string }>
          }
    >
}

export type ViewerSettingsVariables = Exact<{ [key: string]: never }>

export type ViewerSettingsResult = {
    __typename?: 'Query'
    viewerSettings: { __typename?: 'SettingsCascade' } & SettingsCascadeFields
}

export type LegacyDefinitionAndHoverVariables = Exact<{
    repository: Scalars['String']
    commit: Scalars['String']
    path: Scalars['String']
    line: Scalars['Int']
    character: Scalars['Int']
}>

export type LegacyDefinitionAndHoverResult = {
    __typename?: 'Query'
    repository: Maybe<{
        __typename?: 'Repository'
        commit: Maybe<{
            __typename?: 'GitCommit'
            blob: Maybe<{
                __typename?: 'GitBlob'
                lsif: Maybe<{
                    __typename?: 'GitBlobLSIFData'
                    definitions: {
                        __typename?: 'LocationConnection'
                        nodes: Array<{
                            __typename?: 'Location'
                            resource: {
                                __typename?: 'GitBlob'
                                path: string
                                repository: { __typename?: 'Repository'; name: string }
                                commit: { __typename?: 'GitCommit'; oid: string }
                            }
                            range: Maybe<{
                                __typename?: 'Range'
                                start: { __typename?: 'Position'; line: number; character: number }
                                end: { __typename?: 'Position'; line: number; character: number }
                            }>
                        }>
                    }
                    hover: Maybe<{
                        __typename?: 'Hover'
                        markdown: { __typename?: 'Markdown'; text: string }
                        range: {
                            __typename?: 'Range'
                            start: { __typename?: 'Position'; line: number; character: number }
                            end: { __typename?: 'Position'; line: number; character: number }
                        }
                    }>
                }>
            }>
        }>
    }>
}

export type LegacyImplementationsVariables = Exact<{
    repository: Scalars['String']
    commit: Scalars['String']
    path: Scalars['String']
    line: Scalars['Int']
    character: Scalars['Int']
    after: Maybe<Scalars['String']>
}>

export type LegacyImplementationsResult = {
    __typename?: 'Query'
    repository: Maybe<{
        __typename?: 'Repository'
        commit: Maybe<{
            __typename?: 'GitCommit'
            blob: Maybe<{
                __typename?: 'GitBlob'
                lsif: Maybe<{
                    __typename?: 'GitBlobLSIFData'
                    implementations: {
                        __typename?: 'LocationConnection'
                        nodes: Array<{
                            __typename?: 'Location'
                            resource: {
                                __typename?: 'GitBlob'
                                path: string
                                repository: { __typename?: 'Repository'; name: string }
                                commit: { __typename?: 'GitCommit'; oid: string }
                            }
                            range: Maybe<{
                                __typename?: 'Range'
                                start: { __typename?: 'Position'; line: number; character: number }
                                end: { __typename?: 'Position'; line: number; character: number }
                            }>
                        }>
                        pageInfo: { __typename?: 'PageInfo'; endCursor: Maybe<string> }
                    }
                }>
            }>
        }>
    }>
}

export type LegacyRangesVariables = Exact<{
    repository: Scalars['String']
    commit: Scalars['String']
    path: Scalars['String']
    startLine: Scalars['Int']
    endLine: Scalars['Int']
}>

export type LegacyRangesResult = {
    __typename?: 'Query'
    repository: Maybe<{
        __typename?: 'Repository'
        commit: Maybe<{
            __typename?: 'GitCommit'
            blob: Maybe<{
                __typename?: 'GitBlob'
                lsif: Maybe<{
                    __typename?: 'GitBlobLSIFData'
                    ranges: Maybe<{
                        __typename?: 'CodeIntelligenceRangeConnection'
                        nodes: Array<{
                            __typename?: 'CodeIntelligenceRange'
                            range: {
                                __typename?: 'Range'
                                start: { __typename?: 'Position'; line: number; character: number }
                                end: { __typename?: 'Position'; line: number; character: number }
                            }
                            definitions: {
                                __typename?: 'LocationConnection'
                                nodes: Array<{
                                    __typename?: 'Location'
                                    resource: { __typename?: 'GitBlob'; path: string }
                                    range: Maybe<{
                                        __typename?: 'Range'
                                        start: { __typename?: 'Position'; line: number; character: number }
                                        end: { __typename?: 'Position'; line: number; character: number }
                                    }>
                                }>
                            }
                            references: {
                                __typename?: 'LocationConnection'
                                nodes: Array<{
                                    __typename?: 'Location'
                                    resource: { __typename?: 'GitBlob'; path: string }
                                    range: Maybe<{
                                        __typename?: 'Range'
                                        start: { __typename?: 'Position'; line: number; character: number }
                                        end: { __typename?: 'Position'; line: number; character: number }
                                    }>
                                }>
                            }
                            hover: Maybe<{
                                __typename?: 'Hover'
                                markdown: { __typename?: 'Markdown'; text: string }
                                range: {
                                    __typename?: 'Range'
                                    start: { __typename?: 'Position'; line: number; character: number }
                                    end: { __typename?: 'Position'; line: number; character: number }
                                }
                            }>
                        }>
                    }>
                }>
            }>
        }>
    }>
}

export type LegacyReferencesVariables = Exact<{
    repository: Scalars['String']
    commit: Scalars['String']
    path: Scalars['String']
    line: Scalars['Int']
    character: Scalars['Int']
    after: Maybe<Scalars['String']>
}>

export type LegacyReferencesResult = {
    __typename?: 'Query'
    repository: Maybe<{
        __typename?: 'Repository'
        commit: Maybe<{
            __typename?: 'GitCommit'
            blob: Maybe<{
                __typename?: 'GitBlob'
                lsif: Maybe<{
                    __typename?: 'GitBlobLSIFData'
                    references: {
                        __typename?: 'LocationConnection'
                        nodes: Array<{
                            __typename?: 'Location'
                            resource: {
                                __typename?: 'GitBlob'
                                path: string
                                repository: { __typename?: 'Repository'; name: string }
                                commit: { __typename?: 'GitCommit'; oid: string }
                            }
                            range: Maybe<{
                                __typename?: 'Range'
                                start: { __typename?: 'Position'; line: number; character: number }
                                end: { __typename?: 'Position'; line: number; character: number }
                            }>
                        }>
                        pageInfo: { __typename?: 'PageInfo'; endCursor: Maybe<string> }
                    }
                }>
            }>
        }>
    }>
}

export type LegacyStencilVariables = Exact<{
    repository: Scalars['String']
    commit: Scalars['String']
    path: Scalars['String']
}>

export type LegacyStencilResult = {
    __typename?: 'Query'
    repository: Maybe<{
        __typename?: 'Repository'
        commit: Maybe<{
            __typename?: 'GitCommit'
            blob: Maybe<{
                __typename?: 'GitBlob'
                lsif: Maybe<{
                    __typename?: 'GitBlobLSIFData'
                    stencil: Array<{
                        __typename?: 'Range'
                        start: { __typename?: 'Position'; line: number; character: number }
                        end: { __typename?: 'Position'; line: number; character: number }
                    }>
                }>
            }>
        }>
    }>
}

export type LegacyResolveRepoVariables = Exact<{
    name: Scalars['String']
}>

export type LegacyResolveRepoResult = {
    __typename?: 'Query'
    repository: Maybe<{ __typename?: 'Repository'; id: string; name: string; isFork: boolean; isArchived: boolean }>
}

export type LegacyResolveRepo2Variables = Exact<{
    name: Scalars['String']
}>

export type LegacyResolveRepo2Result = {
    __typename?: 'Query'
    repository: Maybe<{ __typename?: 'Repository'; name: string }>
}

export type LegacyRepositoryIntrospectionVariables = Exact<{ [key: string]: never }>

export type LegacyRepositoryIntrospectionResult = {
    __typename?: 'Query'
    __type: Maybe<{ __typename?: '__Type'; fields: Maybe<Array<{ __typename?: '__Field'; name: string }>> }>
}

export type LegacyImplementationsIntrospectionQueryVariables = Exact<{ [key: string]: never }>

export type LegacyImplementationsIntrospectionQueryResult = {
    __typename?: 'Query'
    __type: Maybe<{ __typename?: '__Type'; fields: Maybe<Array<{ __typename?: '__Field'; name: string }>> }>
}

export type LegacyLocalCodeIntelIntrospectionQueryVariables = Exact<{ [key: string]: never }>

export type LegacyLocalCodeIntelIntrospectionQueryResult = {
    __typename?: 'Query'
    __type: Maybe<{ __typename?: '__Type'; fields: Maybe<Array<{ __typename?: '__Field'; name: string }>> }>
}

export type LegacySymbolInfoIntrospectionQueryVariables = Exact<{ [key: string]: never }>

export type LegacySymbolInfoIntrospectionQueryResult = {
    __typename?: 'Query'
    __type: Maybe<{ __typename?: '__Type'; fields: Maybe<Array<{ __typename?: '__Field'; name: string }>> }>
}

export type LegacySymbolLocationRangeIntrospectionQueryVariables = Exact<{ [key: string]: never }>

export type LegacySymbolLocationRangeIntrospectionQueryResult = {
    __typename?: 'Query'
    __type: Maybe<{ __typename?: '__Type'; fields: Maybe<Array<{ __typename?: '__Field'; name: string }>> }>
}

export type LegacyResolveRevVariables = Exact<{
    repoName: Scalars['String']
    rev: Scalars['String']
}>

export type LegacyResolveRevResult = {
    __typename?: 'Query'
    repository: Maybe<{ __typename?: 'Repository'; commit: Maybe<{ __typename?: 'GitCommit'; oid: string }> }>
}

export type LegacyCodeIntelSearchVariables = Exact<{
    query: Scalars['String']
}>

export type LegacyCodeIntelSearchResult = {
    __typename?: 'Query'
    search: Maybe<{
        __typename?: 'Search'
        results: {
            __typename?: 'SearchResults'
            results: Array<
                | { __typename?: 'FileMatch'; repository: { __typename?: 'Repository'; name: string } }
                | { __typename?: 'CommitSearchResult' }
                | { __typename?: 'Repository' }
            >
        }
    }>
}

export type LegacyExtensionManifestsVariables = Exact<{ [key: string]: never }>

export type LegacyExtensionManifestsResult = {
    __typename?: 'Query'
    extensionRegistry: {
        __typename?: 'ExtensionRegistry'
        extensions: {
            __typename?: 'RegistryExtensionConnection'
            nodes: Array<{
                __typename?: 'RegistryExtension'
                extensionID: string
                manifest: Maybe<{ __typename?: 'ExtensionManifest'; raw: string }>
            }>
        }
    }
}

export type LegacyProductVersionVariables = Exact<{ [key: string]: never }>

export type LegacyProductVersionResult = { __typename?: 'Query'; site: { __typename?: 'Site'; productVersion: string } }

export type LegacyCurrentUserVariables = Exact<{ [key: string]: never }>

export type LegacyCurrentUserResult = { __typename?: 'Query'; currentUser: Maybe<{ __typename?: 'User'; id: string }> }

export type LegacyCreateAccessTokenVariables = Exact<{
    user: Scalars['ID']
    note: Scalars['String']
    scopes: Array<Scalars['String']>
}>

export type LegacyCreateAccessTokenResult = {
    __typename?: 'Mutation'
    createAccessToken: { __typename?: 'CreateAccessTokenResult'; token: string }
}

export type LegacyFileContentVariables = Exact<{
    repo: Scalars['String']
    rev: Scalars['String']
    path: Scalars['String']
}>

export type LegacyFileContentResult = {
    __typename?: 'Query'
    repository: Maybe<{
        __typename?: 'Repository'
        commit: Maybe<{
            __typename?: 'GitCommit'
            file: Maybe<{ __typename?: 'GitBlob'; content: string } | { __typename?: 'VirtualFile'; content: string }>
        }>
    }>
}

export type LegacyStencilIntrospectionQueryVariables = Exact<{ [key: string]: never }>

export type LegacyStencilIntrospectionQueryResult = {
    __typename?: 'Query'
    __type: Maybe<{ __typename?: '__Type'; fields: Maybe<Array<{ __typename?: '__Field'; name: string }>> }>
}

export type SearchResults = {
    __typename?: 'Search'
    results: {
        __typename: 'SearchResults'
        results: Array<
            | {
                  __typename: 'FileMatch'
                  file: { __typename?: 'GitBlob'; path: string; commit: { __typename?: 'GitCommit'; oid: string } }
                  repository: { __typename?: 'Repository'; name: string }
                  symbols: Array<{
                      __typename?: 'Symbol'
                      name: string
                      kind: SymbolKind
                      location: {
                          __typename?: 'Location'
                          resource: { __typename?: 'GitBlob'; path: string }
                          range: Maybe<{
                              __typename?: 'Range'
                              start: { __typename?: 'Position'; line: number; character: number }
                              end: { __typename?: 'Position'; line: number; character: number }
                          }>
                      }
                  }>
                  lineMatches: Array<{
                      __typename?: 'LineMatch'
                      lineNumber: number
                      offsetAndLengths: Array<Array<number>>
                  }>
              }
            | { __typename?: 'CommitSearchResult' }
            | { __typename?: 'Repository' }
        >
    }
}

export type FileLocal = {
    __typename?: 'Search'
    results: {
        __typename: 'SearchResults'
        results: Array<
            | { __typename?: 'FileMatch'; symbols: Array<{ __typename?: 'Symbol'; fileLocal: boolean }> }
            | { __typename?: 'CommitSearchResult' }
            | { __typename?: 'Repository' }
        >
    }
}

export type LegacyCodeIntelSearch2Variables = Exact<{
    query: Scalars['String']
}>

export type LegacyCodeIntelSearch2Result = {
    __typename?: 'Query'
    search: Maybe<{ __typename?: 'Search' } & SearchResults & FileLocal>
}

export type LegacyCodeIntelSearch3Variables = Exact<{
    query: Scalars['String']
}>

export type LegacyCodeIntelSearch3Result = {
    __typename?: 'Query'
    search: Maybe<{ __typename?: 'Search' } & SearchResults>
}

export type LocalCodeIntelVariables = Exact<{
    repository: Scalars['String']
    commit: Scalars['String']
    path: Scalars['String']
}>

export type LocalCodeIntelResult = {
    __typename?: 'Query'
    repository: Maybe<{
        __typename?: 'Repository'
        commit: Maybe<{
            __typename?: 'GitCommit'
            blob: Maybe<{ __typename?: 'GitBlob'; localCodeIntel: Maybe<unknown> }>
        }>
    }>
}

export type LegacySymbolInfoVariables = Exact<{
    repository: Scalars['String']
    commit: Scalars['String']
    path: Scalars['String']
    line: Scalars['Int']
    character: Scalars['Int']
}>

export type LegacySymbolInfoResult = {
    __typename?: 'Query'
    repository: Maybe<{
        __typename?: 'Repository'
        commit: Maybe<{
            __typename?: 'GitCommit'
            blob: Maybe<{
                __typename?: 'GitBlob'
                symbolInfo: Maybe<{
                    __typename?: 'SymbolInfo'
                    hover: Maybe<string>
                    definition: Maybe<{
                        __typename?: 'SymbolLocation'
                        repo: string
                        commit: string
                        path: string
                        line: number
                        character: number
                        length: number
                    }>
                }>
            }>
        }>
    }>
}

export type LegacySymbolInfo2Variables = Exact<{
    repository: Scalars['String']
    commit: Scalars['String']
    path: Scalars['String']
    line: Scalars['Int']
    character: Scalars['Int']
}>

export type LegacySymbolInfo2Result = {
    __typename?: 'Query'
    repository: Maybe<{
        __typename?: 'Repository'
        commit: Maybe<{
            __typename?: 'GitCommit'
            blob: Maybe<{
                __typename?: 'GitBlob'
                symbolInfo: Maybe<{
                    __typename?: 'SymbolInfo'
                    hover: Maybe<string>
                    definition: Maybe<{
                        __typename?: 'SymbolLocation'
                        repo: string
                        commit: string
                        path: string
                        range: Maybe<{ __typename?: 'LineRange'; line: number; character: number; length: number }>
                    }>
                }>
            }>
        }>
    }>
}

export type ExtensionsVariables = Exact<{
    first: Scalars['Int']
    extensionIDs: Array<Scalars['String']>
    extensionManifestFields: Array<Scalars['String']>
}>

export type ExtensionsResult = {
    __typename?: 'Query'
    extensionRegistry: {
        __typename?: 'ExtensionRegistry'
        extensions: {
            __typename?: 'RegistryExtensionConnection'
            nodes: Array<{
                __typename?: 'RegistryExtension'
                id: string
                extensionID: string
                manifest: Maybe<{ __typename?: 'ExtensionManifest'; jsonFields: unknown }>
            }>
        }
    }
}

export type ExtensionsWithPrioritizeExtensionIDsParamAndNoJSONFieldsVariables = Exact<{
    first: Scalars['Int']
    extensionIDs: Array<Scalars['String']>
}>

export type ExtensionsWithPrioritizeExtensionIDsParamAndNoJSONFieldsResult = {
    __typename?: 'Query'
    extensionRegistry: {
        __typename?: 'ExtensionRegistry'
        extensions: {
            __typename?: 'RegistryExtensionConnection'
            nodes: Array<{
                __typename?: 'RegistryExtension'
                id: string
                extensionID: string
                manifest: Maybe<{ __typename?: 'ExtensionManifest'; raw: string }>
            }>
        }
    }
}

export type EditSettingsVariables = Exact<{
    subject: Scalars['ID']
    lastID: Maybe<Scalars['Int']>
    edit: ConfigurationEdit
}>

export type EditSettingsResult = {
    __typename?: 'Mutation'
    configurationMutation: Maybe<{
        __typename?: 'SettingsMutation'
        editConfiguration: Maybe<{
            __typename?: 'UpdateSettingsPayload'
            empty: Maybe<{ __typename?: 'EmptyResponse'; alwaysNil: Maybe<string> }>
        }>
    }>
}

export type OverwriteSettingsVariables = Exact<{
    subject: Scalars['ID']
    lastID: Maybe<Scalars['Int']>
    contents: Scalars['String']
}>

export type OverwriteSettingsResult = {
    __typename?: 'Mutation'
    settingsMutation: Maybe<{
        __typename?: 'SettingsMutation'
        overwriteSettings: Maybe<{
            __typename?: 'UpdateSettingsPayload'
            empty: Maybe<{ __typename?: 'EmptyResponse'; alwaysNil: Maybe<string> }>
        }>
    }>
}

export type GetTemporarySettingsVariables = Exact<{ [key: string]: never }>

export type GetTemporarySettingsResult = {
    __typename?: 'Query'
    temporarySettings: { __typename?: 'TemporarySettings'; contents: string }
}

export type EditTemporarySettingsVariables = Exact<{
    contents: Scalars['String']
}>

export type EditTemporarySettingsResult = {
    __typename?: 'Mutation'
    editTemporarySettings: { __typename?: 'EmptyResponse'; alwaysNil: Maybe<string> }
}

export type Unnamed_1_Variables = Exact<{ [key: string]: never }>

export type Unnamed_1_Result = {
    __typename?: 'Query'
    temporarySettings: { __typename?: 'TemporarySettings'; contents: string }
}

export type Unnamed_2_Variables = Exact<{ [key: string]: never }>

export type Unnamed_2_Result = {
    __typename?: 'Query'
    temporarySettings: { __typename?: 'TemporarySettings'; contents: string }
}

export type MutationKeySpecifier = (
    | 'SetMigrationDirection'
    | 'SetUserPublicRepos'
    | 'addExternalService'
    | 'addInsightViewToDashboard'
    | 'addPhabricatorRepo'
    | 'addRepoKeyValuePair'
    | 'addUserEmail'
    | 'addUserToOrganization'
    | 'applyBatchChange'
    | 'cancelBatchSpecExecution'
    | 'cancelBatchSpecWorkspaceExecution'
    | 'checkMirrorRepositoryConnection'
    | 'closeBatchChange'
    | 'closeChangesets'
    | 'configurationMutation'
    | 'createAccessToken'
    | 'createBatchChange'
    | 'createBatchChangesCredential'
    | 'createBatchSpec'
    | 'createBatchSpecFromRaw'
    | 'createChangesetComments'
    | 'createChangesetSpec'
    | 'createCodeIntelligenceConfigurationPolicy'
    | 'createCodeMonitor'
    | 'createEmptyBatchChange'
    | 'createFeatureFlag'
    | 'createFeatureFlagOverride'
    | 'createInsightsDashboard'
    | 'createLineChartSearchInsight'
    | 'createNotebook'
    | 'createNotebookStar'
    | 'createOrganization'
    | 'createPassword'
    | 'createPieChartSearchInsight'
    | 'createSavedSearch'
    | 'createSearchContext'
    | 'createUser'
    | 'deleteAccessToken'
    | 'deleteBatchChange'
    | 'deleteBatchChangesCredential'
    | 'deleteBatchSpec'
    | 'deleteCodeIntelligenceConfigurationPolicy'
    | 'deleteCodeMonitor'
    | 'deleteExternalAccount'
    | 'deleteExternalService'
    | 'deleteFeatureFlag'
    | 'deleteFeatureFlagOverride'
    | 'deleteInsightView'
    | 'deleteInsightsDashboard'
    | 'deleteLSIFIndex'
    | 'deleteLSIFUpload'
    | 'deleteNotebook'
    | 'deleteNotebookStar'
    | 'deleteOrganization'
    | 'deleteRepoKeyValuePair'
    | 'deleteRepositoryFromDisk'
    | 'deleteSavedSearch'
    | 'deleteSearchContext'
    | 'deleteUser'
    | 'deleteUsers'
    | 'detachChangesets'
    | 'dotcom'
    | 'editTemporarySettings'
    | 'enqueueBatchSpecWorkspaceExecution'
    | 'executeBatchSpec'
    | 'extensionRegistry'
    | 'invalidateSessionsByID'
    | 'invalidateSessionsByIDs'
    | 'inviteEmailToSourcegraph'
    | 'inviteUserToOrganization'
    | 'logEvent'
    | 'logEvents'
    | 'logUserEvent'
    | 'mergeChangesets'
    | 'moveBatchChange'
    | 'overwriteTemporarySettings'
    | 'publishChangesets'
    | 'queueAutoIndexJobsForRepo'
    | 'randomizeUserPassword'
    | 'recloneRepository'
    | 'reenqueueChangeset'
    | 'reenqueueChangesets'
    | 'reloadSite'
    | 'removeInsightViewFromDashboard'
    | 'removeUserEmail'
    | 'removeUserFromOrganization'
    | 'replaceBatchSpecInput'
    | 'requestLanguageSupport'
    | 'requestTrial'
    | 'resendOrganizationInvitationNotification'
    | 'resendVerificationEmail'
    | 'resetTriggerQueryTimestamps'
    | 'resolvePhabricatorDiff'
    | 'respondToOrganizationInvitation'
    | 'retryBatchSpecExecution'
    | 'retryBatchSpecWorkspaceExecution'
    | 'revokeOrganizationInvitation'
    | 'scheduleRepositoryPermissionsSync'
    | 'scheduleUserPermissionsSync'
    | 'sendTestEmail'
    | 'setExternalServiceRepos'
    | 'setRepositoryPermissionsForBitbucketProject'
    | 'setRepositoryPermissionsForUsers'
    | 'setRepositoryPermissionsUnrestricted'
    | 'setSearchable'
    | 'setSubRepositoryPermissionsForUsers'
    | 'setTag'
    | 'setTosAccepted'
    | 'setUserEmailPrimary'
    | 'setUserEmailVerified'
    | 'setUserIsSiteAdmin'
    | 'settingsMutation'
    | 'submitHappinessFeedback'
    | 'submitSurvey'
    | 'syncChangeset'
    | 'syncExternalService'
    | 'toggleBatchSpecAutoApply'
    | 'toggleCodeMonitor'
    | 'triggerObservabilityTestAlert'
    | 'triggerTestEmailAction'
    | 'triggerTestSlackWebhookAction'
    | 'triggerTestWebhookAction'
    | 'updateCodeIntelligenceConfigurationPolicy'
    | 'updateCodeMonitor'
    | 'updateExternalService'
    | 'updateFeatureFlag'
    | 'updateFeatureFlagOverride'
    | 'updateInsightSeries'
    | 'updateInsightsDashboard'
    | 'updateLineChartSearchInsight'
    | 'updateMirrorRepository'
    | 'updateNotebook'
    | 'updateOrganization'
    | 'updatePassword'
    | 'updatePieChartSearchInsight'
    | 'updateRepoKeyValuePair'
    | 'updateRepositoryIndexConfiguration'
    | 'updateSavedSearch'
    | 'updateSearchContext'
    | 'updateSiteConfiguration'
    | 'updateUser'
    | 'upsertBatchSpecInput'
    | 'upsertEmptyBatchChange'
    | MutationKeySpecifier
)[]
export type MutationFieldPolicy = {
    SetMigrationDirection?: FieldPolicy<any> | FieldReadFunction<any>
    SetUserPublicRepos?: FieldPolicy<any> | FieldReadFunction<any>
    addExternalService?: FieldPolicy<any> | FieldReadFunction<any>
    addInsightViewToDashboard?: FieldPolicy<any> | FieldReadFunction<any>
    addPhabricatorRepo?: FieldPolicy<any> | FieldReadFunction<any>
    addRepoKeyValuePair?: FieldPolicy<any> | FieldReadFunction<any>
    addUserEmail?: FieldPolicy<any> | FieldReadFunction<any>
    addUserToOrganization?: FieldPolicy<any> | FieldReadFunction<any>
    applyBatchChange?: FieldPolicy<any> | FieldReadFunction<any>
    cancelBatchSpecExecution?: FieldPolicy<any> | FieldReadFunction<any>
    cancelBatchSpecWorkspaceExecution?: FieldPolicy<any> | FieldReadFunction<any>
    checkMirrorRepositoryConnection?: FieldPolicy<any> | FieldReadFunction<any>
    closeBatchChange?: FieldPolicy<any> | FieldReadFunction<any>
    closeChangesets?: FieldPolicy<any> | FieldReadFunction<any>
    configurationMutation?: FieldPolicy<any> | FieldReadFunction<any>
    createAccessToken?: FieldPolicy<any> | FieldReadFunction<any>
    createBatchChange?: FieldPolicy<any> | FieldReadFunction<any>
    createBatchChangesCredential?: FieldPolicy<any> | FieldReadFunction<any>
    createBatchSpec?: FieldPolicy<any> | FieldReadFunction<any>
    createBatchSpecFromRaw?: FieldPolicy<any> | FieldReadFunction<any>
    createChangesetComments?: FieldPolicy<any> | FieldReadFunction<any>
    createChangesetSpec?: FieldPolicy<any> | FieldReadFunction<any>
    createCodeIntelligenceConfigurationPolicy?: FieldPolicy<any> | FieldReadFunction<any>
    createCodeMonitor?: FieldPolicy<any> | FieldReadFunction<any>
    createEmptyBatchChange?: FieldPolicy<any> | FieldReadFunction<any>
    createFeatureFlag?: FieldPolicy<any> | FieldReadFunction<any>
    createFeatureFlagOverride?: FieldPolicy<any> | FieldReadFunction<any>
    createInsightsDashboard?: FieldPolicy<any> | FieldReadFunction<any>
    createLineChartSearchInsight?: FieldPolicy<any> | FieldReadFunction<any>
    createNotebook?: FieldPolicy<any> | FieldReadFunction<any>
    createNotebookStar?: FieldPolicy<any> | FieldReadFunction<any>
    createOrganization?: FieldPolicy<any> | FieldReadFunction<any>
    createPassword?: FieldPolicy<any> | FieldReadFunction<any>
    createPieChartSearchInsight?: FieldPolicy<any> | FieldReadFunction<any>
    createSavedSearch?: FieldPolicy<any> | FieldReadFunction<any>
    createSearchContext?: FieldPolicy<any> | FieldReadFunction<any>
    createUser?: FieldPolicy<any> | FieldReadFunction<any>
    deleteAccessToken?: FieldPolicy<any> | FieldReadFunction<any>
    deleteBatchChange?: FieldPolicy<any> | FieldReadFunction<any>
    deleteBatchChangesCredential?: FieldPolicy<any> | FieldReadFunction<any>
    deleteBatchSpec?: FieldPolicy<any> | FieldReadFunction<any>
    deleteCodeIntelligenceConfigurationPolicy?: FieldPolicy<any> | FieldReadFunction<any>
    deleteCodeMonitor?: FieldPolicy<any> | FieldReadFunction<any>
    deleteExternalAccount?: FieldPolicy<any> | FieldReadFunction<any>
    deleteExternalService?: FieldPolicy<any> | FieldReadFunction<any>
    deleteFeatureFlag?: FieldPolicy<any> | FieldReadFunction<any>
    deleteFeatureFlagOverride?: FieldPolicy<any> | FieldReadFunction<any>
    deleteInsightView?: FieldPolicy<any> | FieldReadFunction<any>
    deleteInsightsDashboard?: FieldPolicy<any> | FieldReadFunction<any>
    deleteLSIFIndex?: FieldPolicy<any> | FieldReadFunction<any>
    deleteLSIFUpload?: FieldPolicy<any> | FieldReadFunction<any>
    deleteNotebook?: FieldPolicy<any> | FieldReadFunction<any>
    deleteNotebookStar?: FieldPolicy<any> | FieldReadFunction<any>
    deleteOrganization?: FieldPolicy<any> | FieldReadFunction<any>
    deleteRepoKeyValuePair?: FieldPolicy<any> | FieldReadFunction<any>
    deleteRepositoryFromDisk?: FieldPolicy<any> | FieldReadFunction<any>
    deleteSavedSearch?: FieldPolicy<any> | FieldReadFunction<any>
    deleteSearchContext?: FieldPolicy<any> | FieldReadFunction<any>
    deleteUser?: FieldPolicy<any> | FieldReadFunction<any>
    deleteUsers?: FieldPolicy<any> | FieldReadFunction<any>
    detachChangesets?: FieldPolicy<any> | FieldReadFunction<any>
    dotcom?: FieldPolicy<any> | FieldReadFunction<any>
    editTemporarySettings?: FieldPolicy<any> | FieldReadFunction<any>
    enqueueBatchSpecWorkspaceExecution?: FieldPolicy<any> | FieldReadFunction<any>
    executeBatchSpec?: FieldPolicy<any> | FieldReadFunction<any>
    extensionRegistry?: FieldPolicy<any> | FieldReadFunction<any>
    invalidateSessionsByID?: FieldPolicy<any> | FieldReadFunction<any>
    invalidateSessionsByIDs?: FieldPolicy<any> | FieldReadFunction<any>
    inviteEmailToSourcegraph?: FieldPolicy<any> | FieldReadFunction<any>
    inviteUserToOrganization?: FieldPolicy<any> | FieldReadFunction<any>
    logEvent?: FieldPolicy<any> | FieldReadFunction<any>
    logEvents?: FieldPolicy<any> | FieldReadFunction<any>
    logUserEvent?: FieldPolicy<any> | FieldReadFunction<any>
    mergeChangesets?: FieldPolicy<any> | FieldReadFunction<any>
    moveBatchChange?: FieldPolicy<any> | FieldReadFunction<any>
    overwriteTemporarySettings?: FieldPolicy<any> | FieldReadFunction<any>
    publishChangesets?: FieldPolicy<any> | FieldReadFunction<any>
    queueAutoIndexJobsForRepo?: FieldPolicy<any> | FieldReadFunction<any>
    randomizeUserPassword?: FieldPolicy<any> | FieldReadFunction<any>
    recloneRepository?: FieldPolicy<any> | FieldReadFunction<any>
    reenqueueChangeset?: FieldPolicy<any> | FieldReadFunction<any>
    reenqueueChangesets?: FieldPolicy<any> | FieldReadFunction<any>
    reloadSite?: FieldPolicy<any> | FieldReadFunction<any>
    removeInsightViewFromDashboard?: FieldPolicy<any> | FieldReadFunction<any>
    removeUserEmail?: FieldPolicy<any> | FieldReadFunction<any>
    removeUserFromOrganization?: FieldPolicy<any> | FieldReadFunction<any>
    replaceBatchSpecInput?: FieldPolicy<any> | FieldReadFunction<any>
    requestLanguageSupport?: FieldPolicy<any> | FieldReadFunction<any>
    requestTrial?: FieldPolicy<any> | FieldReadFunction<any>
    resendOrganizationInvitationNotification?: FieldPolicy<any> | FieldReadFunction<any>
    resendVerificationEmail?: FieldPolicy<any> | FieldReadFunction<any>
    resetTriggerQueryTimestamps?: FieldPolicy<any> | FieldReadFunction<any>
    resolvePhabricatorDiff?: FieldPolicy<any> | FieldReadFunction<any>
    respondToOrganizationInvitation?: FieldPolicy<any> | FieldReadFunction<any>
    retryBatchSpecExecution?: FieldPolicy<any> | FieldReadFunction<any>
    retryBatchSpecWorkspaceExecution?: FieldPolicy<any> | FieldReadFunction<any>
    revokeOrganizationInvitation?: FieldPolicy<any> | FieldReadFunction<any>
    scheduleRepositoryPermissionsSync?: FieldPolicy<any> | FieldReadFunction<any>
    scheduleUserPermissionsSync?: FieldPolicy<any> | FieldReadFunction<any>
    sendTestEmail?: FieldPolicy<any> | FieldReadFunction<any>
    setExternalServiceRepos?: FieldPolicy<any> | FieldReadFunction<any>
    setRepositoryPermissionsForBitbucketProject?: FieldPolicy<any> | FieldReadFunction<any>
    setRepositoryPermissionsForUsers?: FieldPolicy<any> | FieldReadFunction<any>
    setRepositoryPermissionsUnrestricted?: FieldPolicy<any> | FieldReadFunction<any>
    setSearchable?: FieldPolicy<any> | FieldReadFunction<any>
    setSubRepositoryPermissionsForUsers?: FieldPolicy<any> | FieldReadFunction<any>
    setTag?: FieldPolicy<any> | FieldReadFunction<any>
    setTosAccepted?: FieldPolicy<any> | FieldReadFunction<any>
    setUserEmailPrimary?: FieldPolicy<any> | FieldReadFunction<any>
    setUserEmailVerified?: FieldPolicy<any> | FieldReadFunction<any>
    setUserIsSiteAdmin?: FieldPolicy<any> | FieldReadFunction<any>
    settingsMutation?: FieldPolicy<any> | FieldReadFunction<any>
    submitHappinessFeedback?: FieldPolicy<any> | FieldReadFunction<any>
    submitSurvey?: FieldPolicy<any> | FieldReadFunction<any>
    syncChangeset?: FieldPolicy<any> | FieldReadFunction<any>
    syncExternalService?: FieldPolicy<any> | FieldReadFunction<any>
    toggleBatchSpecAutoApply?: FieldPolicy<any> | FieldReadFunction<any>
    toggleCodeMonitor?: FieldPolicy<any> | FieldReadFunction<any>
    triggerObservabilityTestAlert?: FieldPolicy<any> | FieldReadFunction<any>
    triggerTestEmailAction?: FieldPolicy<any> | FieldReadFunction<any>
    triggerTestSlackWebhookAction?: FieldPolicy<any> | FieldReadFunction<any>
    triggerTestWebhookAction?: FieldPolicy<any> | FieldReadFunction<any>
    updateCodeIntelligenceConfigurationPolicy?: FieldPolicy<any> | FieldReadFunction<any>
    updateCodeMonitor?: FieldPolicy<any> | FieldReadFunction<any>
    updateExternalService?: FieldPolicy<any> | FieldReadFunction<any>
    updateFeatureFlag?: FieldPolicy<any> | FieldReadFunction<any>
    updateFeatureFlagOverride?: FieldPolicy<any> | FieldReadFunction<any>
    updateInsightSeries?: FieldPolicy<any> | FieldReadFunction<any>
    updateInsightsDashboard?: FieldPolicy<any> | FieldReadFunction<any>
    updateLineChartSearchInsight?: FieldPolicy<any> | FieldReadFunction<any>
    updateMirrorRepository?: FieldPolicy<any> | FieldReadFunction<any>
    updateNotebook?: FieldPolicy<any> | FieldReadFunction<any>
    updateOrganization?: FieldPolicy<any> | FieldReadFunction<any>
    updatePassword?: FieldPolicy<any> | FieldReadFunction<any>
    updatePieChartSearchInsight?: FieldPolicy<any> | FieldReadFunction<any>
    updateRepoKeyValuePair?: FieldPolicy<any> | FieldReadFunction<any>
    updateRepositoryIndexConfiguration?: FieldPolicy<any> | FieldReadFunction<any>
    updateSavedSearch?: FieldPolicy<any> | FieldReadFunction<any>
    updateSearchContext?: FieldPolicy<any> | FieldReadFunction<any>
    updateSiteConfiguration?: FieldPolicy<any> | FieldReadFunction<any>
    updateUser?: FieldPolicy<any> | FieldReadFunction<any>
    upsertBatchSpecInput?: FieldPolicy<any> | FieldReadFunction<any>
    upsertEmptyBatchChange?: FieldPolicy<any> | FieldReadFunction<any>
}
export type QueryKeySpecifier = (
    | 'affiliatedRepositories'
    | 'areExecutorsConfigured'
    | 'authorizedUserRepositories'
    | 'autoDefinedSearchContexts'
    | 'autocompleteMembersSearch'
    | 'availableBulkOperations'
    | 'batchChange'
    | 'batchChanges'
    | 'batchChangesCodeHosts'
    | 'batchSpecs'
    | 'bitbucketProjectPermissionJobs'
    | 'checkBatchChangesCredential'
    | 'clientConfiguration'
    | 'codeHostSyncDue'
    | 'codeIntelligenceConfigurationPolicies'
    | 'compute'
    | 'currentUser'
    | 'dotcom'
    | 'enterpriseLicenseHasFeature'
    | 'evaluateFeatureFlag'
    | 'evaluatedFeatureFlags'
    | 'executors'
    | 'extensionRegistry'
    | 'externalServices'
    | 'featureFlag'
    | 'featureFlags'
    | 'highlightCode'
    | 'insightSeriesQueryStatus'
    | 'insightViews'
    | 'insights'
    | 'insightsDashboards'
    | 'invitationByToken'
    | 'isSearchContextAvailable'
    | 'lsifIndexes'
    | 'lsifUploads'
    | 'maxUnlicensedChangesets'
    | 'namespace'
    | 'namespaceByName'
    | 'node'
    | 'notebooks'
    | 'orgMembersSummary'
    | 'organization'
    | 'organizationFeatureFlagOverrides'
    | 'organizationFeatureFlagValue'
    | 'organizations'
    | 'outOfBandMigrations'
    | 'parseSearchQuery'
    | 'pendingInvitations'
    | 'phabricatorRepo'
    | 'previewRepositoryFilter'
    | 'renderMarkdown'
    | 'repositories'
    | 'repository'
    | 'repositoryRedirect'
    | 'repositoryStats'
    | 'requestedLanguageSupport'
    | 'resolveWorkspacesForBatchSpec'
    | 'root'
    | 'savedSearches'
    | 'search'
    | 'searchContextBySpec'
    | 'searchContexts'
    | 'searchInsightLivePreview'
    | 'searchInsightPreview'
    | 'searchQueryAggregate'
    | 'settingsSubject'
    | 'site'
    | 'statusMessages'
    | 'surveyResponses'
    | 'temporarySettings'
    | 'user'
    | 'users'
    | 'usersWithPendingPermissions'
    | 'viewerConfiguration'
    | 'viewerSettings'
    | 'webhookLogs'
    | QueryKeySpecifier
)[]
export type QueryFieldPolicy = {
    affiliatedRepositories?: FieldPolicy<any> | FieldReadFunction<any>
    areExecutorsConfigured?: FieldPolicy<any> | FieldReadFunction<any>
    authorizedUserRepositories?: FieldPolicy<any> | FieldReadFunction<any>
    autoDefinedSearchContexts?: FieldPolicy<any> | FieldReadFunction<any>
    autocompleteMembersSearch?: FieldPolicy<any> | FieldReadFunction<any>
    availableBulkOperations?: FieldPolicy<any> | FieldReadFunction<any>
    batchChange?: FieldPolicy<any> | FieldReadFunction<any>
    batchChanges?: FieldPolicy<any> | FieldReadFunction<any>
    batchChangesCodeHosts?: FieldPolicy<any> | FieldReadFunction<any>
    batchSpecs?: FieldPolicy<any> | FieldReadFunction<any>
    bitbucketProjectPermissionJobs?: FieldPolicy<any> | FieldReadFunction<any>
    checkBatchChangesCredential?: FieldPolicy<any> | FieldReadFunction<any>
    clientConfiguration?: FieldPolicy<any> | FieldReadFunction<any>
    codeHostSyncDue?: FieldPolicy<any> | FieldReadFunction<any>
    codeIntelligenceConfigurationPolicies?: FieldPolicy<any> | FieldReadFunction<any>
    compute?: FieldPolicy<any> | FieldReadFunction<any>
    currentUser?: FieldPolicy<any> | FieldReadFunction<any>
    dotcom?: FieldPolicy<any> | FieldReadFunction<any>
    enterpriseLicenseHasFeature?: FieldPolicy<any> | FieldReadFunction<any>
    evaluateFeatureFlag?: FieldPolicy<any> | FieldReadFunction<any>
    evaluatedFeatureFlags?: FieldPolicy<any> | FieldReadFunction<any>
    executors?: FieldPolicy<any> | FieldReadFunction<any>
    extensionRegistry?: FieldPolicy<any> | FieldReadFunction<any>
    externalServices?: FieldPolicy<any> | FieldReadFunction<any>
    featureFlag?: FieldPolicy<any> | FieldReadFunction<any>
    featureFlags?: FieldPolicy<any> | FieldReadFunction<any>
    highlightCode?: FieldPolicy<any> | FieldReadFunction<any>
    insightSeriesQueryStatus?: FieldPolicy<any> | FieldReadFunction<any>
    insightViews?: FieldPolicy<any> | FieldReadFunction<any>
    insights?: FieldPolicy<any> | FieldReadFunction<any>
    insightsDashboards?: FieldPolicy<any> | FieldReadFunction<any>
    invitationByToken?: FieldPolicy<any> | FieldReadFunction<any>
    isSearchContextAvailable?: FieldPolicy<any> | FieldReadFunction<any>
    lsifIndexes?: FieldPolicy<any> | FieldReadFunction<any>
    lsifUploads?: FieldPolicy<any> | FieldReadFunction<any>
    maxUnlicensedChangesets?: FieldPolicy<any> | FieldReadFunction<any>
    namespace?: FieldPolicy<any> | FieldReadFunction<any>
    namespaceByName?: FieldPolicy<any> | FieldReadFunction<any>
    node?: FieldPolicy<any> | FieldReadFunction<any>
    notebooks?: FieldPolicy<any> | FieldReadFunction<any>
    orgMembersSummary?: FieldPolicy<any> | FieldReadFunction<any>
    organization?: FieldPolicy<any> | FieldReadFunction<any>
    organizationFeatureFlagOverrides?: FieldPolicy<any> | FieldReadFunction<any>
    organizationFeatureFlagValue?: FieldPolicy<any> | FieldReadFunction<any>
    organizations?: FieldPolicy<any> | FieldReadFunction<any>
    outOfBandMigrations?: FieldPolicy<any> | FieldReadFunction<any>
    parseSearchQuery?: FieldPolicy<any> | FieldReadFunction<any>
    pendingInvitations?: FieldPolicy<any> | FieldReadFunction<any>
    phabricatorRepo?: FieldPolicy<any> | FieldReadFunction<any>
    previewRepositoryFilter?: FieldPolicy<any> | FieldReadFunction<any>
    renderMarkdown?: FieldPolicy<any> | FieldReadFunction<any>
    repositories?: FieldPolicy<any> | FieldReadFunction<any>
    repository?: FieldPolicy<any> | FieldReadFunction<any>
    repositoryRedirect?: FieldPolicy<any> | FieldReadFunction<any>
    repositoryStats?: FieldPolicy<any> | FieldReadFunction<any>
    requestedLanguageSupport?: FieldPolicy<any> | FieldReadFunction<any>
    resolveWorkspacesForBatchSpec?: FieldPolicy<any> | FieldReadFunction<any>
    root?: FieldPolicy<any> | FieldReadFunction<any>
    savedSearches?: FieldPolicy<any> | FieldReadFunction<any>
    search?: FieldPolicy<any> | FieldReadFunction<any>
    searchContextBySpec?: FieldPolicy<any> | FieldReadFunction<any>
    searchContexts?: FieldPolicy<any> | FieldReadFunction<any>
    searchInsightLivePreview?: FieldPolicy<any> | FieldReadFunction<any>
    searchInsightPreview?: FieldPolicy<any> | FieldReadFunction<any>
    searchQueryAggregate?: FieldPolicy<any> | FieldReadFunction<any>
    settingsSubject?: FieldPolicy<any> | FieldReadFunction<any>
    site?: FieldPolicy<any> | FieldReadFunction<any>
    statusMessages?: FieldPolicy<any> | FieldReadFunction<any>
    surveyResponses?: FieldPolicy<any> | FieldReadFunction<any>
    temporarySettings?: FieldPolicy<any> | FieldReadFunction<any>
    user?: FieldPolicy<any> | FieldReadFunction<any>
    users?: FieldPolicy<any> | FieldReadFunction<any>
    usersWithPendingPermissions?: FieldPolicy<any> | FieldReadFunction<any>
    viewerConfiguration?: FieldPolicy<any> | FieldReadFunction<any>
    viewerSettings?: FieldPolicy<any> | FieldReadFunction<any>
    webhookLogs?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RepositoryKeySpecifier = (
    | 'authorizedUsers'
    | 'batchChanges'
    | 'batchChangesDiffStat'
    | 'branches'
    | 'changesetsStats'
    | 'cloneInProgress'
    | 'codeIntelSummary'
    | 'codeIntelligenceCommitGraph'
    | 'commit'
    | 'comparison'
    | 'contributors'
    | 'createdAt'
    | 'defaultBranch'
    | 'description'
    | 'detail'
    | 'diskSizeBytes'
    | 'externalRepository'
    | 'externalServices'
    | 'externalURLs'
    | 'gitRefs'
    | 'id'
    | 'indexConfiguration'
    | 'isArchived'
    | 'isFork'
    | 'isPrivate'
    | 'keyValuePairs'
    | 'label'
    | 'language'
    | 'lsifIndexes'
    | 'lsifUploads'
    | 'matches'
    | 'mirrorInfo'
    | 'name'
    | 'permissionsInfo'
    | 'previewGitObjectFilter'
    | 'stars'
    | 'tags'
    | 'textSearchIndex'
    | 'updatedAt'
    | 'uri'
    | 'url'
    | 'viewerCanAdminister'
    | RepositoryKeySpecifier
)[]
export type RepositoryFieldPolicy = {
    authorizedUsers?: FieldPolicy<any> | FieldReadFunction<any>
    batchChanges?: FieldPolicy<any> | FieldReadFunction<any>
    batchChangesDiffStat?: FieldPolicy<any> | FieldReadFunction<any>
    branches?: FieldPolicy<any> | FieldReadFunction<any>
    changesetsStats?: FieldPolicy<any> | FieldReadFunction<any>
    cloneInProgress?: FieldPolicy<any> | FieldReadFunction<any>
    codeIntelSummary?: FieldPolicy<any> | FieldReadFunction<any>
    codeIntelligenceCommitGraph?: FieldPolicy<any> | FieldReadFunction<any>
    commit?: FieldPolicy<any> | FieldReadFunction<any>
    comparison?: FieldPolicy<any> | FieldReadFunction<any>
    contributors?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    defaultBranch?: FieldPolicy<any> | FieldReadFunction<any>
    description?: FieldPolicy<any> | FieldReadFunction<any>
    detail?: FieldPolicy<any> | FieldReadFunction<any>
    diskSizeBytes?: FieldPolicy<any> | FieldReadFunction<any>
    externalRepository?: FieldPolicy<any> | FieldReadFunction<any>
    externalServices?: FieldPolicy<any> | FieldReadFunction<any>
    externalURLs?: FieldPolicy<any> | FieldReadFunction<any>
    gitRefs?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    indexConfiguration?: FieldPolicy<any> | FieldReadFunction<any>
    isArchived?: FieldPolicy<any> | FieldReadFunction<any>
    isFork?: FieldPolicy<any> | FieldReadFunction<any>
    isPrivate?: FieldPolicy<any> | FieldReadFunction<any>
    keyValuePairs?: FieldPolicy<any> | FieldReadFunction<any>
    label?: FieldPolicy<any> | FieldReadFunction<any>
    language?: FieldPolicy<any> | FieldReadFunction<any>
    lsifIndexes?: FieldPolicy<any> | FieldReadFunction<any>
    lsifUploads?: FieldPolicy<any> | FieldReadFunction<any>
    matches?: FieldPolicy<any> | FieldReadFunction<any>
    mirrorInfo?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    permissionsInfo?: FieldPolicy<any> | FieldReadFunction<any>
    previewGitObjectFilter?: FieldPolicy<any> | FieldReadFunction<any>
    stars?: FieldPolicy<any> | FieldReadFunction<any>
    tags?: FieldPolicy<any> | FieldReadFunction<any>
    textSearchIndex?: FieldPolicy<any> | FieldReadFunction<any>
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
    uri?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
    viewerCanAdminister?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserKeySpecifier = (
    | 'accessTokens'
    | 'avatarURL'
    | 'batchChanges'
    | 'batchChangesCodeHosts'
    | 'builtinAuth'
    | 'configurationCascade'
    | 'createdAt'
    | 'databaseID'
    | 'displayName'
    | 'email'
    | 'emails'
    | 'eventLogs'
    | 'externalAccounts'
    | 'id'
    | 'invitableCollaborators'
    | 'latestSettings'
    | 'monitors'
    | 'namespaceName'
    | 'organizationMemberships'
    | 'organizations'
    | 'permissionsInfo'
    | 'publicRepositories'
    | 'searchable'
    | 'session'
    | 'settingsCascade'
    | 'settingsURL'
    | 'siteAdmin'
    | 'surveyResponses'
    | 'tags'
    | 'tosAccepted'
    | 'updatedAt'
    | 'url'
    | 'urlForSiteAdminBilling'
    | 'usageStatistics'
    | 'username'
    | 'viewerCanAdminister'
    | 'viewerCanChangeUsername'
    | UserKeySpecifier
)[]
export type UserFieldPolicy = {
    accessTokens?: FieldPolicy<any> | FieldReadFunction<any>
    avatarURL?: FieldPolicy<any> | FieldReadFunction<any>
    batchChanges?: FieldPolicy<any> | FieldReadFunction<any>
    batchChangesCodeHosts?: FieldPolicy<any> | FieldReadFunction<any>
    builtinAuth?: FieldPolicy<any> | FieldReadFunction<any>
    configurationCascade?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    databaseID?: FieldPolicy<any> | FieldReadFunction<any>
    displayName?: FieldPolicy<any> | FieldReadFunction<any>
    email?: FieldPolicy<any> | FieldReadFunction<any>
    emails?: FieldPolicy<any> | FieldReadFunction<any>
    eventLogs?: FieldPolicy<any> | FieldReadFunction<any>
    externalAccounts?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    invitableCollaborators?: FieldPolicy<any> | FieldReadFunction<any>
    latestSettings?: FieldPolicy<any> | FieldReadFunction<any>
    monitors?: FieldPolicy<any> | FieldReadFunction<any>
    namespaceName?: FieldPolicy<any> | FieldReadFunction<any>
    organizationMemberships?: FieldPolicy<any> | FieldReadFunction<any>
    organizations?: FieldPolicy<any> | FieldReadFunction<any>
    permissionsInfo?: FieldPolicy<any> | FieldReadFunction<any>
    publicRepositories?: FieldPolicy<any> | FieldReadFunction<any>
    searchable?: FieldPolicy<any> | FieldReadFunction<any>
    session?: FieldPolicy<any> | FieldReadFunction<any>
    settingsCascade?: FieldPolicy<any> | FieldReadFunction<any>
    settingsURL?: FieldPolicy<any> | FieldReadFunction<any>
    siteAdmin?: FieldPolicy<any> | FieldReadFunction<any>
    surveyResponses?: FieldPolicy<any> | FieldReadFunction<any>
    tags?: FieldPolicy<any> | FieldReadFunction<any>
    tosAccepted?: FieldPolicy<any> | FieldReadFunction<any>
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
    urlForSiteAdminBilling?: FieldPolicy<any> | FieldReadFunction<any>
    usageStatistics?: FieldPolicy<any> | FieldReadFunction<any>
    username?: FieldPolicy<any> | FieldReadFunction<any>
    viewerCanAdminister?: FieldPolicy<any> | FieldReadFunction<any>
    viewerCanChangeUsername?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserPermissionKeySpecifier = ('bindID' | 'permission' | UserPermissionKeySpecifier)[]
export type UserPermissionFieldPolicy = {
    bindID?: FieldPolicy<any> | FieldReadFunction<any>
    permission?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PermissionsInfoKeySpecifier = (
    | 'permissions'
    | 'syncedAt'
    | 'updatedAt'
    | 'unrestricted'
    | PermissionsInfoKeySpecifier
)[]
export type PermissionsInfoFieldPolicy = {
    permissions?: FieldPolicy<any> | FieldReadFunction<any>
    syncedAt?: FieldPolicy<any> | FieldReadFunction<any>
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
    unrestricted?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BitbucketProjectPermissionJobsKeySpecifier = (
    | 'totalCount'
    | 'nodes'
    | BitbucketProjectPermissionJobsKeySpecifier
)[]
export type BitbucketProjectPermissionJobsFieldPolicy = {
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BitbucketProjectPermissionJobKeySpecifier = (
    | 'InternalJobID'
    | 'State'
    | 'FailureMessage'
    | 'QueuedAt'
    | 'StartedAt'
    | 'FinishedAt'
    | 'ProcessAfter'
    | 'NumResets'
    | 'NumFailures'
    | 'ProjectKey'
    | 'ExternalServiceID'
    | 'Permissions'
    | 'Unrestricted'
    | BitbucketProjectPermissionJobKeySpecifier
)[]
export type BitbucketProjectPermissionJobFieldPolicy = {
    InternalJobID?: FieldPolicy<any> | FieldReadFunction<any>
    State?: FieldPolicy<any> | FieldReadFunction<any>
    FailureMessage?: FieldPolicy<any> | FieldReadFunction<any>
    QueuedAt?: FieldPolicy<any> | FieldReadFunction<any>
    StartedAt?: FieldPolicy<any> | FieldReadFunction<any>
    FinishedAt?: FieldPolicy<any> | FieldReadFunction<any>
    ProcessAfter?: FieldPolicy<any> | FieldReadFunction<any>
    NumResets?: FieldPolicy<any> | FieldReadFunction<any>
    NumFailures?: FieldPolicy<any> | FieldReadFunction<any>
    ProjectKey?: FieldPolicy<any> | FieldReadFunction<any>
    ExternalServiceID?: FieldPolicy<any> | FieldReadFunction<any>
    Permissions?: FieldPolicy<any> | FieldReadFunction<any>
    Unrestricted?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ChangesetCountsKeySpecifier = (
    | 'date'
    | 'total'
    | 'merged'
    | 'closed'
    | 'draft'
    | 'open'
    | 'openApproved'
    | 'openChangesRequested'
    | 'openPending'
    | ChangesetCountsKeySpecifier
)[]
export type ChangesetCountsFieldPolicy = {
    date?: FieldPolicy<any> | FieldReadFunction<any>
    total?: FieldPolicy<any> | FieldReadFunction<any>
    merged?: FieldPolicy<any> | FieldReadFunction<any>
    closed?: FieldPolicy<any> | FieldReadFunction<any>
    draft?: FieldPolicy<any> | FieldReadFunction<any>
    open?: FieldPolicy<any> | FieldReadFunction<any>
    openApproved?: FieldPolicy<any> | FieldReadFunction<any>
    openChangesRequested?: FieldPolicy<any> | FieldReadFunction<any>
    openPending?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ChangesetLabelKeySpecifier = ('text' | 'color' | 'description' | ChangesetLabelKeySpecifier)[]
export type ChangesetLabelFieldPolicy = {
    text?: FieldPolicy<any> | FieldReadFunction<any>
    color?: FieldPolicy<any> | FieldReadFunction<any>
    description?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ChangesetKeySpecifier = (
    | 'id'
    | 'batchChanges'
    | 'state'
    | 'createdAt'
    | 'updatedAt'
    | 'nextSyncAt'
    | ChangesetKeySpecifier
)[]
export type ChangesetFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    batchChanges?: FieldPolicy<any> | FieldReadFunction<any>
    state?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
    nextSyncAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type HiddenExternalChangesetKeySpecifier = (
    | 'id'
    | 'batchChanges'
    | 'state'
    | 'createdAt'
    | 'updatedAt'
    | 'nextSyncAt'
    | HiddenExternalChangesetKeySpecifier
)[]
export type HiddenExternalChangesetFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    batchChanges?: FieldPolicy<any> | FieldReadFunction<any>
    state?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
    nextSyncAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExternalChangesetKeySpecifier = (
    | 'id'
    | 'externalID'
    | 'repository'
    | 'batchChanges'
    | 'events'
    | 'createdAt'
    | 'updatedAt'
    | 'nextSyncAt'
    | 'scheduleEstimateAt'
    | 'title'
    | 'body'
    | 'author'
    | 'state'
    | 'labels'
    | 'externalURL'
    | 'forkNamespace'
    | 'reviewState'
    | 'diff'
    | 'diffStat'
    | 'checkState'
    | 'error'
    | 'syncerError'
    | 'currentSpec'
    | ExternalChangesetKeySpecifier
)[]
export type ExternalChangesetFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    externalID?: FieldPolicy<any> | FieldReadFunction<any>
    repository?: FieldPolicy<any> | FieldReadFunction<any>
    batchChanges?: FieldPolicy<any> | FieldReadFunction<any>
    events?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
    nextSyncAt?: FieldPolicy<any> | FieldReadFunction<any>
    scheduleEstimateAt?: FieldPolicy<any> | FieldReadFunction<any>
    title?: FieldPolicy<any> | FieldReadFunction<any>
    body?: FieldPolicy<any> | FieldReadFunction<any>
    author?: FieldPolicy<any> | FieldReadFunction<any>
    state?: FieldPolicy<any> | FieldReadFunction<any>
    labels?: FieldPolicy<any> | FieldReadFunction<any>
    externalURL?: FieldPolicy<any> | FieldReadFunction<any>
    forkNamespace?: FieldPolicy<any> | FieldReadFunction<any>
    reviewState?: FieldPolicy<any> | FieldReadFunction<any>
    diff?: FieldPolicy<any> | FieldReadFunction<any>
    diffStat?: FieldPolicy<any> | FieldReadFunction<any>
    checkState?: FieldPolicy<any> | FieldReadFunction<any>
    error?: FieldPolicy<any> | FieldReadFunction<any>
    syncerError?: FieldPolicy<any> | FieldReadFunction<any>
    currentSpec?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ChangesetsStatsKeySpecifier = (
    | 'unpublished'
    | 'draft'
    | 'open'
    | 'merged'
    | 'closed'
    | 'deleted'
    | 'retrying'
    | 'failed'
    | 'scheduled'
    | 'processing'
    | 'archived'
    | 'total'
    | ChangesetsStatsKeySpecifier
)[]
export type ChangesetsStatsFieldPolicy = {
    unpublished?: FieldPolicy<any> | FieldReadFunction<any>
    draft?: FieldPolicy<any> | FieldReadFunction<any>
    open?: FieldPolicy<any> | FieldReadFunction<any>
    merged?: FieldPolicy<any> | FieldReadFunction<any>
    closed?: FieldPolicy<any> | FieldReadFunction<any>
    deleted?: FieldPolicy<any> | FieldReadFunction<any>
    retrying?: FieldPolicy<any> | FieldReadFunction<any>
    failed?: FieldPolicy<any> | FieldReadFunction<any>
    scheduled?: FieldPolicy<any> | FieldReadFunction<any>
    processing?: FieldPolicy<any> | FieldReadFunction<any>
    archived?: FieldPolicy<any> | FieldReadFunction<any>
    total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RepoChangesetsStatsKeySpecifier = (
    | 'unpublished'
    | 'draft'
    | 'open'
    | 'merged'
    | 'closed'
    | 'total'
    | RepoChangesetsStatsKeySpecifier
)[]
export type RepoChangesetsStatsFieldPolicy = {
    unpublished?: FieldPolicy<any> | FieldReadFunction<any>
    draft?: FieldPolicy<any> | FieldReadFunction<any>
    open?: FieldPolicy<any> | FieldReadFunction<any>
    merged?: FieldPolicy<any> | FieldReadFunction<any>
    closed?: FieldPolicy<any> | FieldReadFunction<any>
    total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ChangesetConnectionKeySpecifier = ('nodes' | 'totalCount' | 'pageInfo' | ChangesetConnectionKeySpecifier)[]
export type ChangesetConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ChangesetEventKeySpecifier = ('id' | 'changeset' | 'createdAt' | ChangesetEventKeySpecifier)[]
export type ChangesetEventFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    changeset?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ChangesetEventConnectionKeySpecifier = (
    | 'nodes'
    | 'totalCount'
    | 'pageInfo'
    | ChangesetEventConnectionKeySpecifier
)[]
export type ChangesetEventConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ChangesetSpecDeltaKeySpecifier = (
    | 'titleChanged'
    | 'bodyChanged'
    | 'undraft'
    | 'baseRefChanged'
    | 'diffChanged'
    | 'commitMessageChanged'
    | 'authorNameChanged'
    | 'authorEmailChanged'
    | ChangesetSpecDeltaKeySpecifier
)[]
export type ChangesetSpecDeltaFieldPolicy = {
    titleChanged?: FieldPolicy<any> | FieldReadFunction<any>
    bodyChanged?: FieldPolicy<any> | FieldReadFunction<any>
    undraft?: FieldPolicy<any> | FieldReadFunction<any>
    baseRefChanged?: FieldPolicy<any> | FieldReadFunction<any>
    diffChanged?: FieldPolicy<any> | FieldReadFunction<any>
    commitMessageChanged?: FieldPolicy<any> | FieldReadFunction<any>
    authorNameChanged?: FieldPolicy<any> | FieldReadFunction<any>
    authorEmailChanged?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ChangesetSpecKeySpecifier = ('id' | 'type' | 'expiresAt' | ChangesetSpecKeySpecifier)[]
export type ChangesetSpecFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    type?: FieldPolicy<any> | FieldReadFunction<any>
    expiresAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type HiddenChangesetSpecKeySpecifier = ('id' | 'type' | 'expiresAt' | HiddenChangesetSpecKeySpecifier)[]
export type HiddenChangesetSpecFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    type?: FieldPolicy<any> | FieldReadFunction<any>
    expiresAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type VisibleChangesetSpecKeySpecifier = (
    | 'id'
    | 'type'
    | 'description'
    | 'expiresAt'
    | 'forkTarget'
    | 'workspace'
    | VisibleChangesetSpecKeySpecifier
)[]
export type VisibleChangesetSpecFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    type?: FieldPolicy<any> | FieldReadFunction<any>
    description?: FieldPolicy<any> | FieldReadFunction<any>
    expiresAt?: FieldPolicy<any> | FieldReadFunction<any>
    forkTarget?: FieldPolicy<any> | FieldReadFunction<any>
    workspace?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExistingChangesetReferenceKeySpecifier = (
    | 'baseRepository'
    | 'externalID'
    | ExistingChangesetReferenceKeySpecifier
)[]
export type ExistingChangesetReferenceFieldPolicy = {
    baseRepository?: FieldPolicy<any> | FieldReadFunction<any>
    externalID?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GitBranchChangesetDescriptionKeySpecifier = (
    | 'baseRepository'
    | 'baseRef'
    | 'baseRev'
    | 'headRef'
    | 'title'
    | 'body'
    | 'commits'
    | 'diff'
    | 'diffStat'
    | 'published'
    | GitBranchChangesetDescriptionKeySpecifier
)[]
export type GitBranchChangesetDescriptionFieldPolicy = {
    baseRepository?: FieldPolicy<any> | FieldReadFunction<any>
    baseRef?: FieldPolicy<any> | FieldReadFunction<any>
    baseRev?: FieldPolicy<any> | FieldReadFunction<any>
    headRef?: FieldPolicy<any> | FieldReadFunction<any>
    title?: FieldPolicy<any> | FieldReadFunction<any>
    body?: FieldPolicy<any> | FieldReadFunction<any>
    commits?: FieldPolicy<any> | FieldReadFunction<any>
    diff?: FieldPolicy<any> | FieldReadFunction<any>
    diffStat?: FieldPolicy<any> | FieldReadFunction<any>
    published?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GitCommitDescriptionKeySpecifier = (
    | 'message'
    | 'subject'
    | 'body'
    | 'author'
    | 'diff'
    | GitCommitDescriptionKeySpecifier
)[]
export type GitCommitDescriptionFieldPolicy = {
    message?: FieldPolicy<any> | FieldReadFunction<any>
    subject?: FieldPolicy<any> | FieldReadFunction<any>
    body?: FieldPolicy<any> | FieldReadFunction<any>
    author?: FieldPolicy<any> | FieldReadFunction<any>
    diff?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ChangesetSpecConnectionKeySpecifier = (
    | 'totalCount'
    | 'pageInfo'
    | 'nodes'
    | ChangesetSpecConnectionKeySpecifier
)[]
export type ChangesetSpecConnectionFieldPolicy = {
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
}
export type VisibleApplyPreviewTargetsAttachKeySpecifier = (
    | 'changesetSpec'
    | VisibleApplyPreviewTargetsAttachKeySpecifier
)[]
export type VisibleApplyPreviewTargetsAttachFieldPolicy = {
    changesetSpec?: FieldPolicy<any> | FieldReadFunction<any>
}
export type VisibleApplyPreviewTargetsUpdateKeySpecifier = (
    | 'changesetSpec'
    | 'changeset'
    | VisibleApplyPreviewTargetsUpdateKeySpecifier
)[]
export type VisibleApplyPreviewTargetsUpdateFieldPolicy = {
    changesetSpec?: FieldPolicy<any> | FieldReadFunction<any>
    changeset?: FieldPolicy<any> | FieldReadFunction<any>
}
export type VisibleApplyPreviewTargetsDetachKeySpecifier = (
    | 'changeset'
    | VisibleApplyPreviewTargetsDetachKeySpecifier
)[]
export type VisibleApplyPreviewTargetsDetachFieldPolicy = {
    changeset?: FieldPolicy<any> | FieldReadFunction<any>
}
export type HiddenApplyPreviewTargetsAttachKeySpecifier = (
    | 'changesetSpec'
    | HiddenApplyPreviewTargetsAttachKeySpecifier
)[]
export type HiddenApplyPreviewTargetsAttachFieldPolicy = {
    changesetSpec?: FieldPolicy<any> | FieldReadFunction<any>
}
export type HiddenApplyPreviewTargetsUpdateKeySpecifier = (
    | 'changesetSpec'
    | 'changeset'
    | HiddenApplyPreviewTargetsUpdateKeySpecifier
)[]
export type HiddenApplyPreviewTargetsUpdateFieldPolicy = {
    changesetSpec?: FieldPolicy<any> | FieldReadFunction<any>
    changeset?: FieldPolicy<any> | FieldReadFunction<any>
}
export type HiddenApplyPreviewTargetsDetachKeySpecifier = ('changeset' | HiddenApplyPreviewTargetsDetachKeySpecifier)[]
export type HiddenApplyPreviewTargetsDetachFieldPolicy = {
    changeset?: FieldPolicy<any> | FieldReadFunction<any>
}
export type HiddenChangesetApplyPreviewKeySpecifier = (
    | 'operations'
    | 'delta'
    | 'targets'
    | HiddenChangesetApplyPreviewKeySpecifier
)[]
export type HiddenChangesetApplyPreviewFieldPolicy = {
    operations?: FieldPolicy<any> | FieldReadFunction<any>
    delta?: FieldPolicy<any> | FieldReadFunction<any>
    targets?: FieldPolicy<any> | FieldReadFunction<any>
}
export type VisibleChangesetApplyPreviewKeySpecifier = (
    | 'operations'
    | 'delta'
    | 'targets'
    | VisibleChangesetApplyPreviewKeySpecifier
)[]
export type VisibleChangesetApplyPreviewFieldPolicy = {
    operations?: FieldPolicy<any> | FieldReadFunction<any>
    delta?: FieldPolicy<any> | FieldReadFunction<any>
    targets?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ChangesetApplyPreviewConnectionStatsKeySpecifier = (
    | 'push'
    | 'update'
    | 'undraft'
    | 'publish'
    | 'publishDraft'
    | 'sync'
    | 'import'
    | 'close'
    | 'reopen'
    | 'sleep'
    | 'detach'
    | 'archive'
    | 'added'
    | 'modified'
    | 'removed'
    | 'reattach'
    | ChangesetApplyPreviewConnectionStatsKeySpecifier
)[]
export type ChangesetApplyPreviewConnectionStatsFieldPolicy = {
    push?: FieldPolicy<any> | FieldReadFunction<any>
    update?: FieldPolicy<any> | FieldReadFunction<any>
    undraft?: FieldPolicy<any> | FieldReadFunction<any>
    publish?: FieldPolicy<any> | FieldReadFunction<any>
    publishDraft?: FieldPolicy<any> | FieldReadFunction<any>
    sync?: FieldPolicy<any> | FieldReadFunction<any>
    import?: FieldPolicy<any> | FieldReadFunction<any>
    close?: FieldPolicy<any> | FieldReadFunction<any>
    reopen?: FieldPolicy<any> | FieldReadFunction<any>
    sleep?: FieldPolicy<any> | FieldReadFunction<any>
    detach?: FieldPolicy<any> | FieldReadFunction<any>
    archive?: FieldPolicy<any> | FieldReadFunction<any>
    added?: FieldPolicy<any> | FieldReadFunction<any>
    modified?: FieldPolicy<any> | FieldReadFunction<any>
    removed?: FieldPolicy<any> | FieldReadFunction<any>
    reattach?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ChangesetApplyPreviewConnectionKeySpecifier = (
    | 'totalCount'
    | 'pageInfo'
    | 'nodes'
    | 'stats'
    | ChangesetApplyPreviewConnectionKeySpecifier
)[]
export type ChangesetApplyPreviewConnectionFieldPolicy = {
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    stats?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ForkTargetKeySpecifier = ('pushUser' | 'namespace' | ForkTargetKeySpecifier)[]
export type ForkTargetFieldPolicy = {
    pushUser?: FieldPolicy<any> | FieldReadFunction<any>
    namespace?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ResolvedBatchSpecWorkspaceKeySpecifier = (
    | 'repository'
    | 'branch'
    | 'path'
    | 'onlyFetchWorkspace'
    | 'ignored'
    | 'unsupported'
    | 'searchResultPaths'
    | ResolvedBatchSpecWorkspaceKeySpecifier
)[]
export type ResolvedBatchSpecWorkspaceFieldPolicy = {
    repository?: FieldPolicy<any> | FieldReadFunction<any>
    branch?: FieldPolicy<any> | FieldReadFunction<any>
    path?: FieldPolicy<any> | FieldReadFunction<any>
    onlyFetchWorkspace?: FieldPolicy<any> | FieldReadFunction<any>
    ignored?: FieldPolicy<any> | FieldReadFunction<any>
    unsupported?: FieldPolicy<any> | FieldReadFunction<any>
    searchResultPaths?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BatchSpecWorkspaceResolutionKeySpecifier = (
    | 'failureMessage'
    | 'startedAt'
    | 'finishedAt'
    | 'state'
    | 'workspaces'
    | 'recentlyCompleted'
    | 'recentlyErrored'
    | BatchSpecWorkspaceResolutionKeySpecifier
)[]
export type BatchSpecWorkspaceResolutionFieldPolicy = {
    failureMessage?: FieldPolicy<any> | FieldReadFunction<any>
    startedAt?: FieldPolicy<any> | FieldReadFunction<any>
    finishedAt?: FieldPolicy<any> | FieldReadFunction<any>
    state?: FieldPolicy<any> | FieldReadFunction<any>
    workspaces?: FieldPolicy<any> | FieldReadFunction<any>
    recentlyCompleted?: FieldPolicy<any> | FieldReadFunction<any>
    recentlyErrored?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BatchSpecWorkspacesStatsKeySpecifier = (
    | 'errored'
    | 'completed'
    | 'processing'
    | 'queued'
    | 'ignored'
    | BatchSpecWorkspacesStatsKeySpecifier
)[]
export type BatchSpecWorkspacesStatsFieldPolicy = {
    errored?: FieldPolicy<any> | FieldReadFunction<any>
    completed?: FieldPolicy<any> | FieldReadFunction<any>
    processing?: FieldPolicy<any> | FieldReadFunction<any>
    queued?: FieldPolicy<any> | FieldReadFunction<any>
    ignored?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BatchSpecWorkspaceConnectionKeySpecifier = (
    | 'totalCount'
    | 'pageInfo'
    | 'nodes'
    | 'stats'
    | BatchSpecWorkspaceConnectionKeySpecifier
)[]
export type BatchSpecWorkspaceConnectionFieldPolicy = {
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    stats?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BatchSpecWorkspaceStagesKeySpecifier = (
    | 'setup'
    | 'srcExec'
    | 'teardown'
    | BatchSpecWorkspaceStagesKeySpecifier
)[]
export type BatchSpecWorkspaceStagesFieldPolicy = {
    setup?: FieldPolicy<any> | FieldReadFunction<any>
    srcExec?: FieldPolicy<any> | FieldReadFunction<any>
    teardown?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BatchSpecWorkspaceKeySpecifier = (
    | 'id'
    | 'batchSpec'
    | 'onlyFetchWorkspace'
    | 'ignored'
    | 'unsupported'
    | 'cachedResultFound'
    | 'stepCacheResultCount'
    | 'queuedAt'
    | 'startedAt'
    | 'finishedAt'
    | 'state'
    | 'placeInQueue'
    | 'placeInGlobalQueue'
    | 'diffStat'
    | BatchSpecWorkspaceKeySpecifier
)[]
export type BatchSpecWorkspaceFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    batchSpec?: FieldPolicy<any> | FieldReadFunction<any>
    onlyFetchWorkspace?: FieldPolicy<any> | FieldReadFunction<any>
    ignored?: FieldPolicy<any> | FieldReadFunction<any>
    unsupported?: FieldPolicy<any> | FieldReadFunction<any>
    cachedResultFound?: FieldPolicy<any> | FieldReadFunction<any>
    stepCacheResultCount?: FieldPolicy<any> | FieldReadFunction<any>
    queuedAt?: FieldPolicy<any> | FieldReadFunction<any>
    startedAt?: FieldPolicy<any> | FieldReadFunction<any>
    finishedAt?: FieldPolicy<any> | FieldReadFunction<any>
    state?: FieldPolicy<any> | FieldReadFunction<any>
    placeInQueue?: FieldPolicy<any> | FieldReadFunction<any>
    placeInGlobalQueue?: FieldPolicy<any> | FieldReadFunction<any>
    diffStat?: FieldPolicy<any> | FieldReadFunction<any>
}
export type VisibleBatchSpecWorkspaceKeySpecifier = (
    | 'id'
    | 'repository'
    | 'batchSpec'
    | 'branch'
    | 'path'
    | 'onlyFetchWorkspace'
    | 'ignored'
    | 'unsupported'
    | 'cachedResultFound'
    | 'stepCacheResultCount'
    | 'stages'
    | 'steps'
    | 'step'
    | 'searchResultPaths'
    | 'queuedAt'
    | 'startedAt'
    | 'finishedAt'
    | 'failureMessage'
    | 'state'
    | 'changesetSpecs'
    | 'placeInQueue'
    | 'placeInGlobalQueue'
    | 'diffStat'
    | 'executor'
    | VisibleBatchSpecWorkspaceKeySpecifier
)[]
export type VisibleBatchSpecWorkspaceFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    repository?: FieldPolicy<any> | FieldReadFunction<any>
    batchSpec?: FieldPolicy<any> | FieldReadFunction<any>
    branch?: FieldPolicy<any> | FieldReadFunction<any>
    path?: FieldPolicy<any> | FieldReadFunction<any>
    onlyFetchWorkspace?: FieldPolicy<any> | FieldReadFunction<any>
    ignored?: FieldPolicy<any> | FieldReadFunction<any>
    unsupported?: FieldPolicy<any> | FieldReadFunction<any>
    cachedResultFound?: FieldPolicy<any> | FieldReadFunction<any>
    stepCacheResultCount?: FieldPolicy<any> | FieldReadFunction<any>
    stages?: FieldPolicy<any> | FieldReadFunction<any>
    steps?: FieldPolicy<any> | FieldReadFunction<any>
    step?: FieldPolicy<any> | FieldReadFunction<any>
    searchResultPaths?: FieldPolicy<any> | FieldReadFunction<any>
    queuedAt?: FieldPolicy<any> | FieldReadFunction<any>
    startedAt?: FieldPolicy<any> | FieldReadFunction<any>
    finishedAt?: FieldPolicy<any> | FieldReadFunction<any>
    failureMessage?: FieldPolicy<any> | FieldReadFunction<any>
    state?: FieldPolicy<any> | FieldReadFunction<any>
    changesetSpecs?: FieldPolicy<any> | FieldReadFunction<any>
    placeInQueue?: FieldPolicy<any> | FieldReadFunction<any>
    placeInGlobalQueue?: FieldPolicy<any> | FieldReadFunction<any>
    diffStat?: FieldPolicy<any> | FieldReadFunction<any>
    executor?: FieldPolicy<any> | FieldReadFunction<any>
}
export type HiddenBatchSpecWorkspaceKeySpecifier = (
    | 'id'
    | 'batchSpec'
    | 'onlyFetchWorkspace'
    | 'ignored'
    | 'unsupported'
    | 'cachedResultFound'
    | 'stepCacheResultCount'
    | 'queuedAt'
    | 'startedAt'
    | 'finishedAt'
    | 'state'
    | 'placeInQueue'
    | 'placeInGlobalQueue'
    | 'diffStat'
    | HiddenBatchSpecWorkspaceKeySpecifier
)[]
export type HiddenBatchSpecWorkspaceFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    batchSpec?: FieldPolicy<any> | FieldReadFunction<any>
    onlyFetchWorkspace?: FieldPolicy<any> | FieldReadFunction<any>
    ignored?: FieldPolicy<any> | FieldReadFunction<any>
    unsupported?: FieldPolicy<any> | FieldReadFunction<any>
    cachedResultFound?: FieldPolicy<any> | FieldReadFunction<any>
    stepCacheResultCount?: FieldPolicy<any> | FieldReadFunction<any>
    queuedAt?: FieldPolicy<any> | FieldReadFunction<any>
    startedAt?: FieldPolicy<any> | FieldReadFunction<any>
    finishedAt?: FieldPolicy<any> | FieldReadFunction<any>
    state?: FieldPolicy<any> | FieldReadFunction<any>
    placeInQueue?: FieldPolicy<any> | FieldReadFunction<any>
    placeInGlobalQueue?: FieldPolicy<any> | FieldReadFunction<any>
    diffStat?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BatchSpecWorkspaceStepKeySpecifier = (
    | 'number'
    | 'run'
    | 'container'
    | 'ifCondition'
    | 'cachedResultFound'
    | 'skipped'
    | 'outputLines'
    | 'startedAt'
    | 'finishedAt'
    | 'exitCode'
    | 'environment'
    | 'outputVariables'
    | 'diffStat'
    | 'diff'
    | BatchSpecWorkspaceStepKeySpecifier
)[]
export type BatchSpecWorkspaceStepFieldPolicy = {
    number?: FieldPolicy<any> | FieldReadFunction<any>
    run?: FieldPolicy<any> | FieldReadFunction<any>
    container?: FieldPolicy<any> | FieldReadFunction<any>
    ifCondition?: FieldPolicy<any> | FieldReadFunction<any>
    cachedResultFound?: FieldPolicy<any> | FieldReadFunction<any>
    skipped?: FieldPolicy<any> | FieldReadFunction<any>
    outputLines?: FieldPolicy<any> | FieldReadFunction<any>
    startedAt?: FieldPolicy<any> | FieldReadFunction<any>
    finishedAt?: FieldPolicy<any> | FieldReadFunction<any>
    exitCode?: FieldPolicy<any> | FieldReadFunction<any>
    environment?: FieldPolicy<any> | FieldReadFunction<any>
    outputVariables?: FieldPolicy<any> | FieldReadFunction<any>
    diffStat?: FieldPolicy<any> | FieldReadFunction<any>
    diff?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BatchSpecWorkspaceOutputVariableKeySpecifier = (
    | 'name'
    | 'value'
    | BatchSpecWorkspaceOutputVariableKeySpecifier
)[]
export type BatchSpecWorkspaceOutputVariableFieldPolicy = {
    name?: FieldPolicy<any> | FieldReadFunction<any>
    value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BatchSpecWorkspaceEnvironmentVariableKeySpecifier = (
    | 'name'
    | 'value'
    | BatchSpecWorkspaceEnvironmentVariableKeySpecifier
)[]
export type BatchSpecWorkspaceEnvironmentVariableFieldPolicy = {
    name?: FieldPolicy<any> | FieldReadFunction<any>
    value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BatchChangeKeySpecifier = (
    | 'id'
    | 'namespace'
    | 'name'
    | 'description'
    | 'state'
    | 'creator'
    | 'lastApplier'
    | 'viewerCanAdminister'
    | 'url'
    | 'createdAt'
    | 'updatedAt'
    | 'lastAppliedAt'
    | 'closedAt'
    | 'changesetsStats'
    | 'changesets'
    | 'changesetCountsOverTime'
    | 'diffStat'
    | 'currentSpec'
    | 'bulkOperations'
    | 'batchSpecs'
    | BatchChangeKeySpecifier
)[]
export type BatchChangeFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    namespace?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    description?: FieldPolicy<any> | FieldReadFunction<any>
    state?: FieldPolicy<any> | FieldReadFunction<any>
    creator?: FieldPolicy<any> | FieldReadFunction<any>
    lastApplier?: FieldPolicy<any> | FieldReadFunction<any>
    viewerCanAdminister?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
    lastAppliedAt?: FieldPolicy<any> | FieldReadFunction<any>
    closedAt?: FieldPolicy<any> | FieldReadFunction<any>
    changesetsStats?: FieldPolicy<any> | FieldReadFunction<any>
    changesets?: FieldPolicy<any> | FieldReadFunction<any>
    changesetCountsOverTime?: FieldPolicy<any> | FieldReadFunction<any>
    diffStat?: FieldPolicy<any> | FieldReadFunction<any>
    currentSpec?: FieldPolicy<any> | FieldReadFunction<any>
    bulkOperations?: FieldPolicy<any> | FieldReadFunction<any>
    batchSpecs?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BulkOperationConnectionKeySpecifier = (
    | 'totalCount'
    | 'pageInfo'
    | 'nodes'
    | BulkOperationConnectionKeySpecifier
)[]
export type BulkOperationConnectionFieldPolicy = {
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BulkOperationKeySpecifier = (
    | 'id'
    | 'type'
    | 'state'
    | 'progress'
    | 'errors'
    | 'createdAt'
    | 'finishedAt'
    | 'initiator'
    | 'changesetCount'
    | BulkOperationKeySpecifier
)[]
export type BulkOperationFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    type?: FieldPolicy<any> | FieldReadFunction<any>
    state?: FieldPolicy<any> | FieldReadFunction<any>
    progress?: FieldPolicy<any> | FieldReadFunction<any>
    errors?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    finishedAt?: FieldPolicy<any> | FieldReadFunction<any>
    initiator?: FieldPolicy<any> | FieldReadFunction<any>
    changesetCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ChangesetJobErrorKeySpecifier = ('changeset' | 'error' | ChangesetJobErrorKeySpecifier)[]
export type ChangesetJobErrorFieldPolicy = {
    changeset?: FieldPolicy<any> | FieldReadFunction<any>
    error?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BatchSpecConnectionKeySpecifier = ('totalCount' | 'pageInfo' | 'nodes' | BatchSpecConnectionKeySpecifier)[]
export type BatchSpecConnectionFieldPolicy = {
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BatchSpecKeySpecifier = (
    | 'id'
    | 'autoApplyEnabled'
    | 'state'
    | 'originalInput'
    | 'parsedInput'
    | 'description'
    | 'applyPreview'
    | 'changesetSpecs'
    | 'creator'
    | 'createdAt'
    | 'startedAt'
    | 'finishedAt'
    | 'namespace'
    | 'expiresAt'
    | 'applyURL'
    | 'viewerCanAdminister'
    | 'diffStat'
    | 'appliesToBatchChange'
    | 'supersedingBatchSpec'
    | 'viewerBatchChangesCodeHosts'
    | 'workspaceResolution'
    | 'importingChangesets'
    | 'failureMessage'
    | 'allowIgnored'
    | 'allowUnsupported'
    | 'viewerCanRetry'
    | 'source'
    | BatchSpecKeySpecifier
)[]
export type BatchSpecFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    autoApplyEnabled?: FieldPolicy<any> | FieldReadFunction<any>
    state?: FieldPolicy<any> | FieldReadFunction<any>
    originalInput?: FieldPolicy<any> | FieldReadFunction<any>
    parsedInput?: FieldPolicy<any> | FieldReadFunction<any>
    description?: FieldPolicy<any> | FieldReadFunction<any>
    applyPreview?: FieldPolicy<any> | FieldReadFunction<any>
    changesetSpecs?: FieldPolicy<any> | FieldReadFunction<any>
    creator?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    startedAt?: FieldPolicy<any> | FieldReadFunction<any>
    finishedAt?: FieldPolicy<any> | FieldReadFunction<any>
    namespace?: FieldPolicy<any> | FieldReadFunction<any>
    expiresAt?: FieldPolicy<any> | FieldReadFunction<any>
    applyURL?: FieldPolicy<any> | FieldReadFunction<any>
    viewerCanAdminister?: FieldPolicy<any> | FieldReadFunction<any>
    diffStat?: FieldPolicy<any> | FieldReadFunction<any>
    appliesToBatchChange?: FieldPolicy<any> | FieldReadFunction<any>
    supersedingBatchSpec?: FieldPolicy<any> | FieldReadFunction<any>
    viewerBatchChangesCodeHosts?: FieldPolicy<any> | FieldReadFunction<any>
    workspaceResolution?: FieldPolicy<any> | FieldReadFunction<any>
    importingChangesets?: FieldPolicy<any> | FieldReadFunction<any>
    failureMessage?: FieldPolicy<any> | FieldReadFunction<any>
    allowIgnored?: FieldPolicy<any> | FieldReadFunction<any>
    allowUnsupported?: FieldPolicy<any> | FieldReadFunction<any>
    viewerCanRetry?: FieldPolicy<any> | FieldReadFunction<any>
    source?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BatchChangeConnectionKeySpecifier = (
    | 'nodes'
    | 'totalCount'
    | 'pageInfo'
    | BatchChangeConnectionKeySpecifier
)[]
export type BatchChangeConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type OrgKeySpecifier = (
    | 'batchChanges'
    | 'configurationCascade'
    | 'createdAt'
    | 'displayName'
    | 'id'
    | 'latestSettings'
    | 'members'
    | 'name'
    | 'namespaceName'
    | 'settingsCascade'
    | 'settingsURL'
    | 'url'
    | 'viewerCanAdminister'
    | 'viewerIsMember'
    | 'viewerNeedsCodeHostUpdate'
    | 'viewerPendingInvitation'
    | OrgKeySpecifier
)[]
export type OrgFieldPolicy = {
    batchChanges?: FieldPolicy<any> | FieldReadFunction<any>
    configurationCascade?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    displayName?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
    latestSettings?: FieldPolicy<any> | FieldReadFunction<any>
    members?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    namespaceName?: FieldPolicy<any> | FieldReadFunction<any>
    settingsCascade?: FieldPolicy<any> | FieldReadFunction<any>
    settingsURL?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
    viewerCanAdminister?: FieldPolicy<any> | FieldReadFunction<any>
    viewerIsMember?: FieldPolicy<any> | FieldReadFunction<any>
    viewerNeedsCodeHostUpdate?: FieldPolicy<any> | FieldReadFunction<any>
    viewerPendingInvitation?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BatchChangesCodeHostConnectionKeySpecifier = (
    | 'nodes'
    | 'totalCount'
    | 'pageInfo'
    | BatchChangesCodeHostConnectionKeySpecifier
)[]
export type BatchChangesCodeHostConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BatchChangesCodeHostKeySpecifier = (
    | 'externalServiceKind'
    | 'externalServiceURL'
    | 'credential'
    | 'requiresSSH'
    | 'requiresUsername'
    | 'hasWebhooks'
    | BatchChangesCodeHostKeySpecifier
)[]
export type BatchChangesCodeHostFieldPolicy = {
    externalServiceKind?: FieldPolicy<any> | FieldReadFunction<any>
    externalServiceURL?: FieldPolicy<any> | FieldReadFunction<any>
    credential?: FieldPolicy<any> | FieldReadFunction<any>
    requiresSSH?: FieldPolicy<any> | FieldReadFunction<any>
    requiresUsername?: FieldPolicy<any> | FieldReadFunction<any>
    hasWebhooks?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BatchChangesCredentialKeySpecifier = (
    | 'id'
    | 'externalServiceKind'
    | 'externalServiceURL'
    | 'sshPublicKey'
    | 'createdAt'
    | 'isSiteCredential'
    | BatchChangesCredentialKeySpecifier
)[]
export type BatchChangesCredentialFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    externalServiceKind?: FieldPolicy<any> | FieldReadFunction<any>
    externalServiceURL?: FieldPolicy<any> | FieldReadFunction<any>
    sshPublicKey?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    isSiteCredential?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BatchChangeDescriptionKeySpecifier = ('name' | 'description' | BatchChangeDescriptionKeySpecifier)[]
export type BatchChangeDescriptionFieldPolicy = {
    name?: FieldPolicy<any> | FieldReadFunction<any>
    description?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MonitorConnectionKeySpecifier = ('nodes' | 'totalCount' | 'pageInfo' | MonitorConnectionKeySpecifier)[]
export type MonitorConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MonitorKeySpecifier = (
    | 'id'
    | 'createdBy'
    | 'createdAt'
    | 'description'
    | 'owner'
    | 'enabled'
    | 'trigger'
    | 'actions'
    | MonitorKeySpecifier
)[]
export type MonitorFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    createdBy?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    description?: FieldPolicy<any> | FieldReadFunction<any>
    owner?: FieldPolicy<any> | FieldReadFunction<any>
    enabled?: FieldPolicy<any> | FieldReadFunction<any>
    trigger?: FieldPolicy<any> | FieldReadFunction<any>
    actions?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MonitorQueryKeySpecifier = ('id' | 'query' | 'events' | MonitorQueryKeySpecifier)[]
export type MonitorQueryFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    query?: FieldPolicy<any> | FieldReadFunction<any>
    events?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MonitorTriggerEventConnectionKeySpecifier = (
    | 'nodes'
    | 'totalCount'
    | 'pageInfo'
    | MonitorTriggerEventConnectionKeySpecifier
)[]
export type MonitorTriggerEventConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MonitorTriggerEventKeySpecifier = (
    | 'id'
    | 'status'
    | 'message'
    | 'timestamp'
    | 'query'
    | 'resultCount'
    | 'actions'
    | MonitorTriggerEventKeySpecifier
)[]
export type MonitorTriggerEventFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    status?: FieldPolicy<any> | FieldReadFunction<any>
    message?: FieldPolicy<any> | FieldReadFunction<any>
    timestamp?: FieldPolicy<any> | FieldReadFunction<any>
    query?: FieldPolicy<any> | FieldReadFunction<any>
    resultCount?: FieldPolicy<any> | FieldReadFunction<any>
    actions?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MonitorActionConnectionKeySpecifier = (
    | 'nodes'
    | 'totalCount'
    | 'pageInfo'
    | MonitorActionConnectionKeySpecifier
)[]
export type MonitorActionConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MonitorEmailKeySpecifier = (
    | 'id'
    | 'enabled'
    | 'includeResults'
    | 'priority'
    | 'header'
    | 'recipients'
    | 'events'
    | MonitorEmailKeySpecifier
)[]
export type MonitorEmailFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    enabled?: FieldPolicy<any> | FieldReadFunction<any>
    includeResults?: FieldPolicy<any> | FieldReadFunction<any>
    priority?: FieldPolicy<any> | FieldReadFunction<any>
    header?: FieldPolicy<any> | FieldReadFunction<any>
    recipients?: FieldPolicy<any> | FieldReadFunction<any>
    events?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MonitorWebhookKeySpecifier = (
    | 'id'
    | 'enabled'
    | 'includeResults'
    | 'url'
    | 'events'
    | MonitorWebhookKeySpecifier
)[]
export type MonitorWebhookFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    enabled?: FieldPolicy<any> | FieldReadFunction<any>
    includeResults?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
    events?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MonitorSlackWebhookKeySpecifier = (
    | 'id'
    | 'enabled'
    | 'includeResults'
    | 'url'
    | 'events'
    | MonitorSlackWebhookKeySpecifier
)[]
export type MonitorSlackWebhookFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    enabled?: FieldPolicy<any> | FieldReadFunction<any>
    includeResults?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
    events?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MonitorActionEmailRecipientsConnectionKeySpecifier = (
    | 'nodes'
    | 'totalCount'
    | 'pageInfo'
    | MonitorActionEmailRecipientsConnectionKeySpecifier
)[]
export type MonitorActionEmailRecipientsConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MonitorActionEventConnectionKeySpecifier = (
    | 'nodes'
    | 'totalCount'
    | 'pageInfo'
    | MonitorActionEventConnectionKeySpecifier
)[]
export type MonitorActionEventConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MonitorActionEventKeySpecifier = (
    | 'id'
    | 'status'
    | 'message'
    | 'timestamp'
    | MonitorActionEventKeySpecifier
)[]
export type MonitorActionEventFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    status?: FieldPolicy<any> | FieldReadFunction<any>
    message?: FieldPolicy<any> | FieldReadFunction<any>
    timestamp?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RepositoryFilterPreviewKeySpecifier = (
    | 'nodes'
    | 'totalCount'
    | 'pageInfo'
    | 'limit'
    | 'totalMatches'
    | RepositoryFilterPreviewKeySpecifier
)[]
export type RepositoryFilterPreviewFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    limit?: FieldPolicy<any> | FieldReadFunction<any>
    totalMatches?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CodeIntelligenceConfigurationPolicyConnectionKeySpecifier = (
    | 'nodes'
    | 'totalCount'
    | 'pageInfo'
    | CodeIntelligenceConfigurationPolicyConnectionKeySpecifier
)[]
export type CodeIntelligenceConfigurationPolicyConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CodeIntelligenceConfigurationPolicyKeySpecifier = (
    | 'id'
    | 'name'
    | 'repository'
    | 'repositoryPatterns'
    | 'type'
    | 'pattern'
    | 'protected'
    | 'retentionEnabled'
    | 'retentionDurationHours'
    | 'retainIntermediateCommits'
    | 'indexingEnabled'
    | 'indexCommitMaxAgeHours'
    | 'indexIntermediateCommits'
    | CodeIntelligenceConfigurationPolicyKeySpecifier
)[]
export type CodeIntelligenceConfigurationPolicyFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    repository?: FieldPolicy<any> | FieldReadFunction<any>
    repositoryPatterns?: FieldPolicy<any> | FieldReadFunction<any>
    type?: FieldPolicy<any> | FieldReadFunction<any>
    pattern?: FieldPolicy<any> | FieldReadFunction<any>
    protected?: FieldPolicy<any> | FieldReadFunction<any>
    retentionEnabled?: FieldPolicy<any> | FieldReadFunction<any>
    retentionDurationHours?: FieldPolicy<any> | FieldReadFunction<any>
    retainIntermediateCommits?: FieldPolicy<any> | FieldReadFunction<any>
    indexingEnabled?: FieldPolicy<any> | FieldReadFunction<any>
    indexCommitMaxAgeHours?: FieldPolicy<any> | FieldReadFunction<any>
    indexIntermediateCommits?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CodeIntelligenceRetentionPolicyMatchKeySpecifier = (
    | 'configurationPolicy'
    | 'matches'
    | 'protectingCommits'
    | CodeIntelligenceRetentionPolicyMatchKeySpecifier
)[]
export type CodeIntelligenceRetentionPolicyMatchFieldPolicy = {
    configurationPolicy?: FieldPolicy<any> | FieldReadFunction<any>
    matches?: FieldPolicy<any> | FieldReadFunction<any>
    protectingCommits?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CodeIntelligenceRetentionPolicyMatchesConnectionKeySpecifier = (
    | 'nodes'
    | 'totalCount'
    | 'pageInfo'
    | CodeIntelligenceRetentionPolicyMatchesConnectionKeySpecifier
)[]
export type CodeIntelligenceRetentionPolicyMatchesConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TreeEntryKeySpecifier = (
    | 'canonicalURL'
    | 'externalURLs'
    | 'isDirectory'
    | 'isSingleChild'
    | 'lsif'
    | 'name'
    | 'path'
    | 'submodule'
    | 'symbols'
    | 'url'
    | TreeEntryKeySpecifier
)[]
export type TreeEntryFieldPolicy = {
    canonicalURL?: FieldPolicy<any> | FieldReadFunction<any>
    externalURLs?: FieldPolicy<any> | FieldReadFunction<any>
    isDirectory?: FieldPolicy<any> | FieldReadFunction<any>
    isSingleChild?: FieldPolicy<any> | FieldReadFunction<any>
    lsif?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    path?: FieldPolicy<any> | FieldReadFunction<any>
    submodule?: FieldPolicy<any> | FieldReadFunction<any>
    symbols?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GitTreeKeySpecifier = (
    | 'canonicalURL'
    | 'codeIntelInfo'
    | 'commit'
    | 'directories'
    | 'entries'
    | 'externalURLs'
    | 'files'
    | 'isDirectory'
    | 'isRoot'
    | 'isSingleChild'
    | 'lsif'
    | 'name'
    | 'path'
    | 'rawZipArchiveURL'
    | 'repository'
    | 'submodule'
    | 'symbols'
    | 'url'
    | GitTreeKeySpecifier
)[]
export type GitTreeFieldPolicy = {
    canonicalURL?: FieldPolicy<any> | FieldReadFunction<any>
    codeIntelInfo?: FieldPolicy<any> | FieldReadFunction<any>
    commit?: FieldPolicy<any> | FieldReadFunction<any>
    directories?: FieldPolicy<any> | FieldReadFunction<any>
    entries?: FieldPolicy<any> | FieldReadFunction<any>
    externalURLs?: FieldPolicy<any> | FieldReadFunction<any>
    files?: FieldPolicy<any> | FieldReadFunction<any>
    isDirectory?: FieldPolicy<any> | FieldReadFunction<any>
    isRoot?: FieldPolicy<any> | FieldReadFunction<any>
    isSingleChild?: FieldPolicy<any> | FieldReadFunction<any>
    lsif?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    path?: FieldPolicy<any> | FieldReadFunction<any>
    rawZipArchiveURL?: FieldPolicy<any> | FieldReadFunction<any>
    repository?: FieldPolicy<any> | FieldReadFunction<any>
    submodule?: FieldPolicy<any> | FieldReadFunction<any>
    symbols?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GitBlobKeySpecifier = (
    | 'binary'
    | 'blame'
    | 'byteSize'
    | 'canonicalURL'
    | 'codeIntelSupport'
    | 'commit'
    | 'content'
    | 'externalURLs'
    | 'highlight'
    | 'isDirectory'
    | 'isSingleChild'
    | 'localCodeIntel'
    | 'lsif'
    | 'name'
    | 'path'
    | 'repository'
    | 'richHTML'
    | 'submodule'
    | 'symbol'
    | 'symbolInfo'
    | 'symbols'
    | 'url'
    | GitBlobKeySpecifier
)[]
export type GitBlobFieldPolicy = {
    binary?: FieldPolicy<any> | FieldReadFunction<any>
    blame?: FieldPolicy<any> | FieldReadFunction<any>
    byteSize?: FieldPolicy<any> | FieldReadFunction<any>
    canonicalURL?: FieldPolicy<any> | FieldReadFunction<any>
    codeIntelSupport?: FieldPolicy<any> | FieldReadFunction<any>
    commit?: FieldPolicy<any> | FieldReadFunction<any>
    content?: FieldPolicy<any> | FieldReadFunction<any>
    externalURLs?: FieldPolicy<any> | FieldReadFunction<any>
    highlight?: FieldPolicy<any> | FieldReadFunction<any>
    isDirectory?: FieldPolicy<any> | FieldReadFunction<any>
    isSingleChild?: FieldPolicy<any> | FieldReadFunction<any>
    localCodeIntel?: FieldPolicy<any> | FieldReadFunction<any>
    lsif?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    path?: FieldPolicy<any> | FieldReadFunction<any>
    repository?: FieldPolicy<any> | FieldReadFunction<any>
    richHTML?: FieldPolicy<any> | FieldReadFunction<any>
    submodule?: FieldPolicy<any> | FieldReadFunction<any>
    symbol?: FieldPolicy<any> | FieldReadFunction<any>
    symbolInfo?: FieldPolicy<any> | FieldReadFunction<any>
    symbols?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SymbolInfoKeySpecifier = ('definition' | 'hover' | SymbolInfoKeySpecifier)[]
export type SymbolInfoFieldPolicy = {
    definition?: FieldPolicy<any> | FieldReadFunction<any>
    hover?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SymbolLocationKeySpecifier = (
    | 'repo'
    | 'commit'
    | 'path'
    | 'range'
    | 'line'
    | 'character'
    | 'length'
    | SymbolLocationKeySpecifier
)[]
export type SymbolLocationFieldPolicy = {
    repo?: FieldPolicy<any> | FieldReadFunction<any>
    commit?: FieldPolicy<any> | FieldReadFunction<any>
    path?: FieldPolicy<any> | FieldReadFunction<any>
    range?: FieldPolicy<any> | FieldReadFunction<any>
    line?: FieldPolicy<any> | FieldReadFunction<any>
    character?: FieldPolicy<any> | FieldReadFunction<any>
    length?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LineRangeKeySpecifier = ('line' | 'character' | 'length' | LineRangeKeySpecifier)[]
export type LineRangeFieldPolicy = {
    line?: FieldPolicy<any> | FieldReadFunction<any>
    character?: FieldPolicy<any> | FieldReadFunction<any>
    length?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CodeIntelRepositorySummaryKeySpecifier = (
    | 'recentUploads'
    | 'recentIndexes'
    | 'lastUploadRetentionScan'
    | 'lastIndexScan'
    | CodeIntelRepositorySummaryKeySpecifier
)[]
export type CodeIntelRepositorySummaryFieldPolicy = {
    recentUploads?: FieldPolicy<any> | FieldReadFunction<any>
    recentIndexes?: FieldPolicy<any> | FieldReadFunction<any>
    lastUploadRetentionScan?: FieldPolicy<any> | FieldReadFunction<any>
    lastIndexScan?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LSIFUploadsWithRepositoryNamespaceKeySpecifier = (
    | 'root'
    | 'indexer'
    | 'uploads'
    | LSIFUploadsWithRepositoryNamespaceKeySpecifier
)[]
export type LSIFUploadsWithRepositoryNamespaceFieldPolicy = {
    root?: FieldPolicy<any> | FieldReadFunction<any>
    indexer?: FieldPolicy<any> | FieldReadFunction<any>
    uploads?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LSIFIndexesWithRepositoryNamespaceKeySpecifier = (
    | 'root'
    | 'indexer'
    | 'indexes'
    | LSIFIndexesWithRepositoryNamespaceKeySpecifier
)[]
export type LSIFIndexesWithRepositoryNamespaceFieldPolicy = {
    root?: FieldPolicy<any> | FieldReadFunction<any>
    indexer?: FieldPolicy<any> | FieldReadFunction<any>
    indexes?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GitObjectFilterPreviewKeySpecifier = ('name' | 'rev' | GitObjectFilterPreviewKeySpecifier)[]
export type GitObjectFilterPreviewFieldPolicy = {
    name?: FieldPolicy<any> | FieldReadFunction<any>
    rev?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TreeEntryLSIFDataKeySpecifier = ('diagnostics' | 'lsifUploads' | TreeEntryLSIFDataKeySpecifier)[]
export type TreeEntryLSIFDataFieldPolicy = {
    diagnostics?: FieldPolicy<any> | FieldReadFunction<any>
    lsifUploads?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GitTreeLSIFDataKeySpecifier = ('diagnostics' | 'lsifUploads' | GitTreeLSIFDataKeySpecifier)[]
export type GitTreeLSIFDataFieldPolicy = {
    diagnostics?: FieldPolicy<any> | FieldReadFunction<any>
    lsifUploads?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GitBlobLSIFDataKeySpecifier = (
    | 'stencil'
    | 'ranges'
    | 'definitions'
    | 'references'
    | 'implementations'
    | 'hover'
    | 'diagnostics'
    | 'lsifUploads'
    | GitBlobLSIFDataKeySpecifier
)[]
export type GitBlobLSIFDataFieldPolicy = {
    stencil?: FieldPolicy<any> | FieldReadFunction<any>
    ranges?: FieldPolicy<any> | FieldReadFunction<any>
    definitions?: FieldPolicy<any> | FieldReadFunction<any>
    references?: FieldPolicy<any> | FieldReadFunction<any>
    implementations?: FieldPolicy<any> | FieldReadFunction<any>
    hover?: FieldPolicy<any> | FieldReadFunction<any>
    diagnostics?: FieldPolicy<any> | FieldReadFunction<any>
    lsifUploads?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LSIFUploadKeySpecifier = (
    | 'id'
    | 'projectRoot'
    | 'inputCommit'
    | 'tags'
    | 'inputRoot'
    | 'inputIndexer'
    | 'indexer'
    | 'state'
    | 'uploadedAt'
    | 'startedAt'
    | 'finishedAt'
    | 'failure'
    | 'isLatestForRepo'
    | 'placeInQueue'
    | 'associatedIndex'
    | 'retentionPolicyOverview'
    | 'documentPaths'
    | 'auditLogs'
    | LSIFUploadKeySpecifier
)[]
export type LSIFUploadFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    projectRoot?: FieldPolicy<any> | FieldReadFunction<any>
    inputCommit?: FieldPolicy<any> | FieldReadFunction<any>
    tags?: FieldPolicy<any> | FieldReadFunction<any>
    inputRoot?: FieldPolicy<any> | FieldReadFunction<any>
    inputIndexer?: FieldPolicy<any> | FieldReadFunction<any>
    indexer?: FieldPolicy<any> | FieldReadFunction<any>
    state?: FieldPolicy<any> | FieldReadFunction<any>
    uploadedAt?: FieldPolicy<any> | FieldReadFunction<any>
    startedAt?: FieldPolicy<any> | FieldReadFunction<any>
    finishedAt?: FieldPolicy<any> | FieldReadFunction<any>
    failure?: FieldPolicy<any> | FieldReadFunction<any>
    isLatestForRepo?: FieldPolicy<any> | FieldReadFunction<any>
    placeInQueue?: FieldPolicy<any> | FieldReadFunction<any>
    associatedIndex?: FieldPolicy<any> | FieldReadFunction<any>
    retentionPolicyOverview?: FieldPolicy<any> | FieldReadFunction<any>
    documentPaths?: FieldPolicy<any> | FieldReadFunction<any>
    auditLogs?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LSIFUploadConnectionKeySpecifier = (
    | 'nodes'
    | 'totalCount'
    | 'pageInfo'
    | LSIFUploadConnectionKeySpecifier
)[]
export type LSIFUploadConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LSIFUploadDocumentPathsConnectionKeySpecifier = (
    | 'nodes'
    | 'totalCount'
    | LSIFUploadDocumentPathsConnectionKeySpecifier
)[]
export type LSIFUploadDocumentPathsConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LSIFUploadAuditLogKeySpecifier = (
    | 'logTimestamp'
    | 'uploadDeletedAt'
    | 'reason'
    | 'changedColumns'
    | 'uploadId'
    | 'inputCommit'
    | 'inputRoot'
    | 'inputIndexer'
    | 'uploadedAt'
    | 'operation'
    | LSIFUploadAuditLogKeySpecifier
)[]
export type LSIFUploadAuditLogFieldPolicy = {
    logTimestamp?: FieldPolicy<any> | FieldReadFunction<any>
    uploadDeletedAt?: FieldPolicy<any> | FieldReadFunction<any>
    reason?: FieldPolicy<any> | FieldReadFunction<any>
    changedColumns?: FieldPolicy<any> | FieldReadFunction<any>
    uploadId?: FieldPolicy<any> | FieldReadFunction<any>
    inputCommit?: FieldPolicy<any> | FieldReadFunction<any>
    inputRoot?: FieldPolicy<any> | FieldReadFunction<any>
    inputIndexer?: FieldPolicy<any> | FieldReadFunction<any>
    uploadedAt?: FieldPolicy<any> | FieldReadFunction<any>
    operation?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AuditLogColumnChangeKeySpecifier = ('column' | 'old' | 'new' | AuditLogColumnChangeKeySpecifier)[]
export type AuditLogColumnChangeFieldPolicy = {
    column?: FieldPolicy<any> | FieldReadFunction<any>
    old?: FieldPolicy<any> | FieldReadFunction<any>
    new?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LSIFIndexKeySpecifier = (
    | 'id'
    | 'projectRoot'
    | 'inputCommit'
    | 'tags'
    | 'inputRoot'
    | 'inputIndexer'
    | 'indexer'
    | 'state'
    | 'queuedAt'
    | 'startedAt'
    | 'finishedAt'
    | 'failure'
    | 'steps'
    | 'placeInQueue'
    | 'associatedUpload'
    | LSIFIndexKeySpecifier
)[]
export type LSIFIndexFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    projectRoot?: FieldPolicy<any> | FieldReadFunction<any>
    inputCommit?: FieldPolicy<any> | FieldReadFunction<any>
    tags?: FieldPolicy<any> | FieldReadFunction<any>
    inputRoot?: FieldPolicy<any> | FieldReadFunction<any>
    inputIndexer?: FieldPolicy<any> | FieldReadFunction<any>
    indexer?: FieldPolicy<any> | FieldReadFunction<any>
    state?: FieldPolicy<any> | FieldReadFunction<any>
    queuedAt?: FieldPolicy<any> | FieldReadFunction<any>
    startedAt?: FieldPolicy<any> | FieldReadFunction<any>
    finishedAt?: FieldPolicy<any> | FieldReadFunction<any>
    failure?: FieldPolicy<any> | FieldReadFunction<any>
    steps?: FieldPolicy<any> | FieldReadFunction<any>
    placeInQueue?: FieldPolicy<any> | FieldReadFunction<any>
    associatedUpload?: FieldPolicy<any> | FieldReadFunction<any>
}
export type IndexStepsKeySpecifier = ('setup' | 'preIndex' | 'index' | 'upload' | 'teardown' | IndexStepsKeySpecifier)[]
export type IndexStepsFieldPolicy = {
    setup?: FieldPolicy<any> | FieldReadFunction<any>
    preIndex?: FieldPolicy<any> | FieldReadFunction<any>
    index?: FieldPolicy<any> | FieldReadFunction<any>
    upload?: FieldPolicy<any> | FieldReadFunction<any>
    teardown?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PreIndexStepKeySpecifier = ('root' | 'image' | 'commands' | 'logEntry' | PreIndexStepKeySpecifier)[]
export type PreIndexStepFieldPolicy = {
    root?: FieldPolicy<any> | FieldReadFunction<any>
    image?: FieldPolicy<any> | FieldReadFunction<any>
    commands?: FieldPolicy<any> | FieldReadFunction<any>
    logEntry?: FieldPolicy<any> | FieldReadFunction<any>
}
export type IndexStepKeySpecifier = ('indexerArgs' | 'outfile' | 'logEntry' | IndexStepKeySpecifier)[]
export type IndexStepFieldPolicy = {
    indexerArgs?: FieldPolicy<any> | FieldReadFunction<any>
    outfile?: FieldPolicy<any> | FieldReadFunction<any>
    logEntry?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LSIFIndexConnectionKeySpecifier = ('nodes' | 'totalCount' | 'pageInfo' | LSIFIndexConnectionKeySpecifier)[]
export type LSIFIndexConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type IndexConfigurationKeySpecifier = (
    | 'configuration'
    | 'inferredConfiguration'
    | IndexConfigurationKeySpecifier
)[]
export type IndexConfigurationFieldPolicy = {
    configuration?: FieldPolicy<any> | FieldReadFunction<any>
    inferredConfiguration?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GitTreeCodeIntelInfoKeySpecifier = (
    | 'searchBasedSupport'
    | 'preciseSupport'
    | GitTreeCodeIntelInfoKeySpecifier
)[]
export type GitTreeCodeIntelInfoFieldPolicy = {
    searchBasedSupport?: FieldPolicy<any> | FieldReadFunction<any>
    preciseSupport?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GitTreeSearchBasedCoverageKeySpecifier = (
    | 'coveredPaths'
    | 'support'
    | GitTreeSearchBasedCoverageKeySpecifier
)[]
export type GitTreeSearchBasedCoverageFieldPolicy = {
    coveredPaths?: FieldPolicy<any> | FieldReadFunction<any>
    support?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GitTreePreciseCoverageKeySpecifier = ('support' | 'confidence' | GitTreePreciseCoverageKeySpecifier)[]
export type GitTreePreciseCoverageFieldPolicy = {
    support?: FieldPolicy<any> | FieldReadFunction<any>
    confidence?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CodeIntelSupportKeySpecifier = ('searchBasedSupport' | 'preciseSupport' | CodeIntelSupportKeySpecifier)[]
export type CodeIntelSupportFieldPolicy = {
    searchBasedSupport?: FieldPolicy<any> | FieldReadFunction<any>
    preciseSupport?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PreciseCodeIntelSupportKeySpecifier = ('supportLevel' | 'indexers' | PreciseCodeIntelSupportKeySpecifier)[]
export type PreciseCodeIntelSupportFieldPolicy = {
    supportLevel?: FieldPolicy<any> | FieldReadFunction<any>
    indexers?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CodeIntelIndexerKeySpecifier = ('name' | 'url' | CodeIntelIndexerKeySpecifier)[]
export type CodeIntelIndexerFieldPolicy = {
    name?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SearchBasedCodeIntelSupportKeySpecifier = (
    | 'supportLevel'
    | 'language'
    | SearchBasedCodeIntelSupportKeySpecifier
)[]
export type SearchBasedCodeIntelSupportFieldPolicy = {
    supportLevel?: FieldPolicy<any> | FieldReadFunction<any>
    language?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ComputeMatchContextKeySpecifier = (
    | 'repository'
    | 'commit'
    | 'path'
    | 'matches'
    | ComputeMatchContextKeySpecifier
)[]
export type ComputeMatchContextFieldPolicy = {
    repository?: FieldPolicy<any> | FieldReadFunction<any>
    commit?: FieldPolicy<any> | FieldReadFunction<any>
    path?: FieldPolicy<any> | FieldReadFunction<any>
    matches?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ComputeMatchKeySpecifier = ('value' | 'range' | 'environment' | ComputeMatchKeySpecifier)[]
export type ComputeMatchFieldPolicy = {
    value?: FieldPolicy<any> | FieldReadFunction<any>
    range?: FieldPolicy<any> | FieldReadFunction<any>
    environment?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ComputeEnvironmentEntryKeySpecifier = (
    | 'variable'
    | 'value'
    | 'range'
    | ComputeEnvironmentEntryKeySpecifier
)[]
export type ComputeEnvironmentEntryFieldPolicy = {
    variable?: FieldPolicy<any> | FieldReadFunction<any>
    value?: FieldPolicy<any> | FieldReadFunction<any>
    range?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ComputeTextKeySpecifier = ('repository' | 'commit' | 'path' | 'kind' | 'value' | ComputeTextKeySpecifier)[]
export type ComputeTextFieldPolicy = {
    repository?: FieldPolicy<any> | FieldReadFunction<any>
    commit?: FieldPolicy<any> | FieldReadFunction<any>
    path?: FieldPolicy<any> | FieldReadFunction<any>
    kind?: FieldPolicy<any> | FieldReadFunction<any>
    value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type DotcomMutationKeySpecifier = (
    | 'setUserBilling'
    | 'createProductSubscription'
    | 'setProductSubscriptionBilling'
    | 'generateProductLicenseForSubscription'
    | 'createPaidProductSubscription'
    | 'updatePaidProductSubscription'
    | 'archiveProductSubscription'
    | DotcomMutationKeySpecifier
)[]
export type DotcomMutationFieldPolicy = {
    setUserBilling?: FieldPolicy<any> | FieldReadFunction<any>
    createProductSubscription?: FieldPolicy<any> | FieldReadFunction<any>
    setProductSubscriptionBilling?: FieldPolicy<any> | FieldReadFunction<any>
    generateProductLicenseForSubscription?: FieldPolicy<any> | FieldReadFunction<any>
    createPaidProductSubscription?: FieldPolicy<any> | FieldReadFunction<any>
    updatePaidProductSubscription?: FieldPolicy<any> | FieldReadFunction<any>
    archiveProductSubscription?: FieldPolicy<any> | FieldReadFunction<any>
}
export type DotcomQueryKeySpecifier = (
    | 'productSubscription'
    | 'productSubscriptions'
    | 'previewProductSubscriptionInvoice'
    | 'productLicenses'
    | 'productPlans'
    | DotcomQueryKeySpecifier
)[]
export type DotcomQueryFieldPolicy = {
    productSubscription?: FieldPolicy<any> | FieldReadFunction<any>
    productSubscriptions?: FieldPolicy<any> | FieldReadFunction<any>
    previewProductSubscriptionInvoice?: FieldPolicy<any> | FieldReadFunction<any>
    productLicenses?: FieldPolicy<any> | FieldReadFunction<any>
    productPlans?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ProductSubscriptionConnectionKeySpecifier = (
    | 'nodes'
    | 'totalCount'
    | 'pageInfo'
    | ProductSubscriptionConnectionKeySpecifier
)[]
export type ProductSubscriptionConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ProductSubscriptionPreviewInvoiceKeySpecifier = (
    | 'price'
    | 'prorationDate'
    | 'isDowngradeRequiringManualIntervention'
    | 'beforeInvoiceItem'
    | 'afterInvoiceItem'
    | ProductSubscriptionPreviewInvoiceKeySpecifier
)[]
export type ProductSubscriptionPreviewInvoiceFieldPolicy = {
    price?: FieldPolicy<any> | FieldReadFunction<any>
    prorationDate?: FieldPolicy<any> | FieldReadFunction<any>
    isDowngradeRequiringManualIntervention?: FieldPolicy<any> | FieldReadFunction<any>
    beforeInvoiceItem?: FieldPolicy<any> | FieldReadFunction<any>
    afterInvoiceItem?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ProductLicenseKeySpecifier = (
    | 'id'
    | 'subscription'
    | 'info'
    | 'licenseKey'
    | 'createdAt'
    | ProductLicenseKeySpecifier
)[]
export type ProductLicenseFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    subscription?: FieldPolicy<any> | FieldReadFunction<any>
    info?: FieldPolicy<any> | FieldReadFunction<any>
    licenseKey?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ProductLicenseConnectionKeySpecifier = (
    | 'nodes'
    | 'totalCount'
    | 'pageInfo'
    | ProductLicenseConnectionKeySpecifier
)[]
export type ProductLicenseConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ProductPlanKeySpecifier = (
    | 'billingPlanID'
    | 'productPlanID'
    | 'name'
    | 'nameWithBrand'
    | 'pricePerUserPerYear'
    | 'minQuantity'
    | 'maxQuantity'
    | 'tiersMode'
    | 'planTiers'
    | ProductPlanKeySpecifier
)[]
export type ProductPlanFieldPolicy = {
    billingPlanID?: FieldPolicy<any> | FieldReadFunction<any>
    productPlanID?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    nameWithBrand?: FieldPolicy<any> | FieldReadFunction<any>
    pricePerUserPerYear?: FieldPolicy<any> | FieldReadFunction<any>
    minQuantity?: FieldPolicy<any> | FieldReadFunction<any>
    maxQuantity?: FieldPolicy<any> | FieldReadFunction<any>
    tiersMode?: FieldPolicy<any> | FieldReadFunction<any>
    planTiers?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PlanTierKeySpecifier = ('unitAmount' | 'upTo' | 'flatAmount' | PlanTierKeySpecifier)[]
export type PlanTierFieldPolicy = {
    unitAmount?: FieldPolicy<any> | FieldReadFunction<any>
    upTo?: FieldPolicy<any> | FieldReadFunction<any>
    flatAmount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ProductSubscriptionInvoiceItemKeySpecifier = (
    | 'plan'
    | 'userCount'
    | 'expiresAt'
    | ProductSubscriptionInvoiceItemKeySpecifier
)[]
export type ProductSubscriptionInvoiceItemFieldPolicy = {
    plan?: FieldPolicy<any> | FieldReadFunction<any>
    userCount?: FieldPolicy<any> | FieldReadFunction<any>
    expiresAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CreatePaidProductSubscriptionResultKeySpecifier = (
    | 'productSubscription'
    | CreatePaidProductSubscriptionResultKeySpecifier
)[]
export type CreatePaidProductSubscriptionResultFieldPolicy = {
    productSubscription?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UpdatePaidProductSubscriptionResultKeySpecifier = (
    | 'productSubscription'
    | UpdatePaidProductSubscriptionResultKeySpecifier
)[]
export type UpdatePaidProductSubscriptionResultFieldPolicy = {
    productSubscription?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ProductSubscriptionEventKeySpecifier = (
    | 'id'
    | 'date'
    | 'title'
    | 'description'
    | 'url'
    | ProductSubscriptionEventKeySpecifier
)[]
export type ProductSubscriptionEventFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    date?: FieldPolicy<any> | FieldReadFunction<any>
    title?: FieldPolicy<any> | FieldReadFunction<any>
    description?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ProductSubscriptionKeySpecifier = (
    | 'id'
    | 'uuid'
    | 'name'
    | 'account'
    | 'invoiceItem'
    | 'events'
    | 'activeLicense'
    | 'productLicenses'
    | 'createdAt'
    | 'isArchived'
    | 'url'
    | 'urlForSiteAdmin'
    | 'urlForSiteAdminBilling'
    | ProductSubscriptionKeySpecifier
)[]
export type ProductSubscriptionFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    uuid?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    account?: FieldPolicy<any> | FieldReadFunction<any>
    invoiceItem?: FieldPolicy<any> | FieldReadFunction<any>
    events?: FieldPolicy<any> | FieldReadFunction<any>
    activeLicense?: FieldPolicy<any> | FieldReadFunction<any>
    productLicenses?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    isArchived?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
    urlForSiteAdmin?: FieldPolicy<any> | FieldReadFunction<any>
    urlForSiteAdminBilling?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightConnectionKeySpecifier = ('nodes' | 'totalCount' | 'pageInfo' | InsightConnectionKeySpecifier)[]
export type InsightConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightKeySpecifier = ('title' | 'description' | 'series' | 'id' | InsightKeySpecifier)[]
export type InsightFieldPolicy = {
    title?: FieldPolicy<any> | FieldReadFunction<any>
    description?: FieldPolicy<any> | FieldReadFunction<any>
    series?: FieldPolicy<any> | FieldReadFunction<any>
    id?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightsSeriesKeySpecifier = (
    | 'seriesId'
    | 'label'
    | 'points'
    | 'status'
    | 'dirtyMetadata'
    | InsightsSeriesKeySpecifier
)[]
export type InsightsSeriesFieldPolicy = {
    seriesId?: FieldPolicy<any> | FieldReadFunction<any>
    label?: FieldPolicy<any> | FieldReadFunction<any>
    points?: FieldPolicy<any> | FieldReadFunction<any>
    status?: FieldPolicy<any> | FieldReadFunction<any>
    dirtyMetadata?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightDataPointKeySpecifier = ('dateTime' | 'value' | InsightDataPointKeySpecifier)[]
export type InsightDataPointFieldPolicy = {
    dateTime?: FieldPolicy<any> | FieldReadFunction<any>
    value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightDirtyQueryMetadataKeySpecifier = (
    | 'count'
    | 'reason'
    | 'time'
    | InsightDirtyQueryMetadataKeySpecifier
)[]
export type InsightDirtyQueryMetadataFieldPolicy = {
    count?: FieldPolicy<any> | FieldReadFunction<any>
    reason?: FieldPolicy<any> | FieldReadFunction<any>
    time?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightSeriesStatusKeySpecifier = (
    | 'totalPoints'
    | 'pendingJobs'
    | 'completedJobs'
    | 'failedJobs'
    | 'backfillQueuedAt'
    | InsightSeriesStatusKeySpecifier
)[]
export type InsightSeriesStatusFieldPolicy = {
    totalPoints?: FieldPolicy<any> | FieldReadFunction<any>
    pendingJobs?: FieldPolicy<any> | FieldReadFunction<any>
    completedJobs?: FieldPolicy<any> | FieldReadFunction<any>
    failedJobs?: FieldPolicy<any> | FieldReadFunction<any>
    backfillQueuedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightsDashboardConnectionKeySpecifier = ('nodes' | 'pageInfo' | InsightsDashboardConnectionKeySpecifier)[]
export type InsightsDashboardConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightsDashboardKeySpecifier = ('id' | 'title' | 'views' | 'grants' | InsightsDashboardKeySpecifier)[]
export type InsightsDashboardFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    title?: FieldPolicy<any> | FieldReadFunction<any>
    views?: FieldPolicy<any> | FieldReadFunction<any>
    grants?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightsPermissionGrantsKeySpecifier = (
    | 'users'
    | 'organizations'
    | 'global'
    | InsightsPermissionGrantsKeySpecifier
)[]
export type InsightsPermissionGrantsFieldPolicy = {
    users?: FieldPolicy<any> | FieldReadFunction<any>
    organizations?: FieldPolicy<any> | FieldReadFunction<any>
    global?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightViewConnectionKeySpecifier = ('nodes' | 'pageInfo' | InsightViewConnectionKeySpecifier)[]
export type InsightViewConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightsDashboardPayloadKeySpecifier = ('dashboard' | InsightsDashboardPayloadKeySpecifier)[]
export type InsightsDashboardPayloadFieldPolicy = {
    dashboard?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightSeriesMetadataKeySpecifier = ('seriesId' | 'query' | 'enabled' | InsightSeriesMetadataKeySpecifier)[]
export type InsightSeriesMetadataFieldPolicy = {
    seriesId?: FieldPolicy<any> | FieldReadFunction<any>
    query?: FieldPolicy<any> | FieldReadFunction<any>
    enabled?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightSeriesMetadataPayloadKeySpecifier = ('series' | InsightSeriesMetadataPayloadKeySpecifier)[]
export type InsightSeriesMetadataPayloadFieldPolicy = {
    series?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightSeriesQueryStatusKeySpecifier = (
    | 'seriesId'
    | 'query'
    | 'enabled'
    | 'errored'
    | 'completed'
    | 'processing'
    | 'failed'
    | 'queued'
    | InsightSeriesQueryStatusKeySpecifier
)[]
export type InsightSeriesQueryStatusFieldPolicy = {
    seriesId?: FieldPolicy<any> | FieldReadFunction<any>
    query?: FieldPolicy<any> | FieldReadFunction<any>
    enabled?: FieldPolicy<any> | FieldReadFunction<any>
    errored?: FieldPolicy<any> | FieldReadFunction<any>
    completed?: FieldPolicy<any> | FieldReadFunction<any>
    processing?: FieldPolicy<any> | FieldReadFunction<any>
    failed?: FieldPolicy<any> | FieldReadFunction<any>
    queued?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SeriesDisplayOptionsKeySpecifier = ('sortOptions' | 'limit' | SeriesDisplayOptionsKeySpecifier)[]
export type SeriesDisplayOptionsFieldPolicy = {
    sortOptions?: FieldPolicy<any> | FieldReadFunction<any>
    limit?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SeriesSortOptionsKeySpecifier = ('mode' | 'direction' | SeriesSortOptionsKeySpecifier)[]
export type SeriesSortOptionsFieldPolicy = {
    mode?: FieldPolicy<any> | FieldReadFunction<any>
    direction?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightViewPayloadKeySpecifier = ('view' | InsightViewPayloadKeySpecifier)[]
export type InsightViewPayloadFieldPolicy = {
    view?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightViewKeySpecifier = (
    | 'id'
    | 'defaultFilters'
    | 'appliedFilters'
    | 'dataSeries'
    | 'presentation'
    | 'dataSeriesDefinitions'
    | 'dashboardReferenceCount'
    | 'isFrozen'
    | 'defaultSeriesDisplayOptions'
    | 'appliedSeriesDisplayOptions'
    | 'dashboards'
    | 'seriesCount'
    | InsightViewKeySpecifier
)[]
export type InsightViewFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    defaultFilters?: FieldPolicy<any> | FieldReadFunction<any>
    appliedFilters?: FieldPolicy<any> | FieldReadFunction<any>
    dataSeries?: FieldPolicy<any> | FieldReadFunction<any>
    presentation?: FieldPolicy<any> | FieldReadFunction<any>
    dataSeriesDefinitions?: FieldPolicy<any> | FieldReadFunction<any>
    dashboardReferenceCount?: FieldPolicy<any> | FieldReadFunction<any>
    isFrozen?: FieldPolicy<any> | FieldReadFunction<any>
    defaultSeriesDisplayOptions?: FieldPolicy<any> | FieldReadFunction<any>
    appliedSeriesDisplayOptions?: FieldPolicy<any> | FieldReadFunction<any>
    dashboards?: FieldPolicy<any> | FieldReadFunction<any>
    seriesCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightRepositoryScopeKeySpecifier = ('repositories' | InsightRepositoryScopeKeySpecifier)[]
export type InsightRepositoryScopeFieldPolicy = {
    repositories?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightIntervalTimeScopeKeySpecifier = ('unit' | 'value' | InsightIntervalTimeScopeKeySpecifier)[]
export type InsightIntervalTimeScopeFieldPolicy = {
    unit?: FieldPolicy<any> | FieldReadFunction<any>
    value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SearchInsightDataSeriesDefinitionKeySpecifier = (
    | 'seriesId'
    | 'query'
    | 'repositoryScope'
    | 'timeScope'
    | 'generatedFromCaptureGroups'
    | 'isCalculated'
    | 'groupBy'
    | SearchInsightDataSeriesDefinitionKeySpecifier
)[]
export type SearchInsightDataSeriesDefinitionFieldPolicy = {
    seriesId?: FieldPolicy<any> | FieldReadFunction<any>
    query?: FieldPolicy<any> | FieldReadFunction<any>
    repositoryScope?: FieldPolicy<any> | FieldReadFunction<any>
    timeScope?: FieldPolicy<any> | FieldReadFunction<any>
    generatedFromCaptureGroups?: FieldPolicy<any> | FieldReadFunction<any>
    isCalculated?: FieldPolicy<any> | FieldReadFunction<any>
    groupBy?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LineChartInsightViewPresentationKeySpecifier = (
    | 'title'
    | 'seriesPresentation'
    | LineChartInsightViewPresentationKeySpecifier
)[]
export type LineChartInsightViewPresentationFieldPolicy = {
    title?: FieldPolicy<any> | FieldReadFunction<any>
    seriesPresentation?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LineChartDataSeriesPresentationKeySpecifier = (
    | 'seriesId'
    | 'label'
    | 'color'
    | LineChartDataSeriesPresentationKeySpecifier
)[]
export type LineChartDataSeriesPresentationFieldPolicy = {
    seriesId?: FieldPolicy<any> | FieldReadFunction<any>
    label?: FieldPolicy<any> | FieldReadFunction<any>
    color?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PieChartInsightViewPresentationKeySpecifier = (
    | 'title'
    | 'otherThreshold'
    | PieChartInsightViewPresentationKeySpecifier
)[]
export type PieChartInsightViewPresentationFieldPolicy = {
    title?: FieldPolicy<any> | FieldReadFunction<any>
    otherThreshold?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InsightViewFiltersKeySpecifier = (
    | 'includeRepoRegex'
    | 'excludeRepoRegex'
    | 'searchContexts'
    | InsightViewFiltersKeySpecifier
)[]
export type InsightViewFiltersFieldPolicy = {
    includeRepoRegex?: FieldPolicy<any> | FieldReadFunction<any>
    excludeRepoRegex?: FieldPolicy<any> | FieldReadFunction<any>
    searchContexts?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SearchInsightLivePreviewSeriesKeySpecifier = (
    | 'points'
    | 'label'
    | SearchInsightLivePreviewSeriesKeySpecifier
)[]
export type SearchInsightLivePreviewSeriesFieldPolicy = {
    points?: FieldPolicy<any> | FieldReadFunction<any>
    label?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SearchQueryAggregateKeySpecifier = (
    | 'modeAvailability'
    | 'aggregations'
    | SearchQueryAggregateKeySpecifier
)[]
export type SearchQueryAggregateFieldPolicy = {
    modeAvailability?: FieldPolicy<any> | FieldReadFunction<any>
    aggregations?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AggregationModeAvailabilityKeySpecifier = (
    | 'mode'
    | 'available'
    | 'reasonUnavailable'
    | AggregationModeAvailabilityKeySpecifier
)[]
export type AggregationModeAvailabilityFieldPolicy = {
    mode?: FieldPolicy<any> | FieldReadFunction<any>
    available?: FieldPolicy<any> | FieldReadFunction<any>
    reasonUnavailable?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExhaustiveSearchAggregationResultKeySpecifier = (
    | 'groups'
    | 'supportsPersistence'
    | 'otherResultCount'
    | 'otherGroupCount'
    | 'mode'
    | ExhaustiveSearchAggregationResultKeySpecifier
)[]
export type ExhaustiveSearchAggregationResultFieldPolicy = {
    groups?: FieldPolicy<any> | FieldReadFunction<any>
    supportsPersistence?: FieldPolicy<any> | FieldReadFunction<any>
    otherResultCount?: FieldPolicy<any> | FieldReadFunction<any>
    otherGroupCount?: FieldPolicy<any> | FieldReadFunction<any>
    mode?: FieldPolicy<any> | FieldReadFunction<any>
}
export type NonExhaustiveSearchAggregationResultKeySpecifier = (
    | 'groups'
    | 'supportsPersistence'
    | 'otherResultCount'
    | 'approximateOtherGroupCount'
    | 'mode'
    | NonExhaustiveSearchAggregationResultKeySpecifier
)[]
export type NonExhaustiveSearchAggregationResultFieldPolicy = {
    groups?: FieldPolicy<any> | FieldReadFunction<any>
    supportsPersistence?: FieldPolicy<any> | FieldReadFunction<any>
    otherResultCount?: FieldPolicy<any> | FieldReadFunction<any>
    approximateOtherGroupCount?: FieldPolicy<any> | FieldReadFunction<any>
    mode?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AggregationGroupKeySpecifier = ('label' | 'count' | 'query' | AggregationGroupKeySpecifier)[]
export type AggregationGroupFieldPolicy = {
    label?: FieldPolicy<any> | FieldReadFunction<any>
    count?: FieldPolicy<any> | FieldReadFunction<any>
    query?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SearchAggregationNotAvailableKeySpecifier = (
    | 'reason'
    | 'reasonType'
    | 'mode'
    | SearchAggregationNotAvailableKeySpecifier
)[]
export type SearchAggregationNotAvailableFieldPolicy = {
    reason?: FieldPolicy<any> | FieldReadFunction<any>
    reasonType?: FieldPolicy<any> | FieldReadFunction<any>
    mode?: FieldPolicy<any> | FieldReadFunction<any>
}
export type NotebookConnectionKeySpecifier = ('nodes' | 'totalCount' | 'pageInfo' | NotebookConnectionKeySpecifier)[]
export type NotebookConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MarkdownBlockKeySpecifier = ('id' | 'markdownInput' | MarkdownBlockKeySpecifier)[]
export type MarkdownBlockFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    markdownInput?: FieldPolicy<any> | FieldReadFunction<any>
}
export type QueryBlockKeySpecifier = ('id' | 'queryInput' | QueryBlockKeySpecifier)[]
export type QueryBlockFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    queryInput?: FieldPolicy<any> | FieldReadFunction<any>
}
export type FileBlockLineRangeKeySpecifier = ('startLine' | 'endLine' | FileBlockLineRangeKeySpecifier)[]
export type FileBlockLineRangeFieldPolicy = {
    startLine?: FieldPolicy<any> | FieldReadFunction<any>
    endLine?: FieldPolicy<any> | FieldReadFunction<any>
}
export type FileBlockInputKeySpecifier = (
    | 'repositoryName'
    | 'filePath'
    | 'revision'
    | 'lineRange'
    | FileBlockInputKeySpecifier
)[]
export type FileBlockInputFieldPolicy = {
    repositoryName?: FieldPolicy<any> | FieldReadFunction<any>
    filePath?: FieldPolicy<any> | FieldReadFunction<any>
    revision?: FieldPolicy<any> | FieldReadFunction<any>
    lineRange?: FieldPolicy<any> | FieldReadFunction<any>
}
export type FileBlockKeySpecifier = ('id' | 'fileInput' | FileBlockKeySpecifier)[]
export type FileBlockFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    fileInput?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SymbolBlockInputKeySpecifier = (
    | 'repositoryName'
    | 'filePath'
    | 'revision'
    | 'lineContext'
    | 'symbolName'
    | 'symbolContainerName'
    | 'symbolKind'
    | SymbolBlockInputKeySpecifier
)[]
export type SymbolBlockInputFieldPolicy = {
    repositoryName?: FieldPolicy<any> | FieldReadFunction<any>
    filePath?: FieldPolicy<any> | FieldReadFunction<any>
    revision?: FieldPolicy<any> | FieldReadFunction<any>
    lineContext?: FieldPolicy<any> | FieldReadFunction<any>
    symbolName?: FieldPolicy<any> | FieldReadFunction<any>
    symbolContainerName?: FieldPolicy<any> | FieldReadFunction<any>
    symbolKind?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SymbolBlockKeySpecifier = ('id' | 'symbolInput' | SymbolBlockKeySpecifier)[]
export type SymbolBlockFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    symbolInput?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ComputeBlockKeySpecifier = ('id' | 'computeInput' | ComputeBlockKeySpecifier)[]
export type ComputeBlockFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    computeInput?: FieldPolicy<any> | FieldReadFunction<any>
}
export type NotebookKeySpecifier = (
    | 'id'
    | 'title'
    | 'blocks'
    | 'creator'
    | 'updater'
    | 'namespace'
    | 'public'
    | 'updatedAt'
    | 'createdAt'
    | 'viewerCanManage'
    | 'viewerHasStarred'
    | 'stars'
    | NotebookKeySpecifier
)[]
export type NotebookFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    title?: FieldPolicy<any> | FieldReadFunction<any>
    blocks?: FieldPolicy<any> | FieldReadFunction<any>
    creator?: FieldPolicy<any> | FieldReadFunction<any>
    updater?: FieldPolicy<any> | FieldReadFunction<any>
    namespace?: FieldPolicy<any> | FieldReadFunction<any>
    public?: FieldPolicy<any> | FieldReadFunction<any>
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    viewerCanManage?: FieldPolicy<any> | FieldReadFunction<any>
    viewerHasStarred?: FieldPolicy<any> | FieldReadFunction<any>
    stars?: FieldPolicy<any> | FieldReadFunction<any>
}
export type NotebookStarConnectionKeySpecifier = (
    | 'nodes'
    | 'totalCount'
    | 'pageInfo'
    | NotebookStarConnectionKeySpecifier
)[]
export type NotebookStarConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type NotebookStarKeySpecifier = ('user' | 'createdAt' | NotebookStarKeySpecifier)[]
export type NotebookStarFieldPolicy = {
    user?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ErrorKeySpecifier = ('message' | 'path' | 'extensions' | ErrorKeySpecifier)[]
export type ErrorFieldPolicy = {
    message?: FieldPolicy<any> | FieldReadFunction<any>
    path?: FieldPolicy<any> | FieldReadFunction<any>
    extensions?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ErrorExtensionsKeySpecifier = ('code' | ErrorExtensionsKeySpecifier)[]
export type ErrorExtensionsFieldPolicy = {
    code?: FieldPolicy<any> | FieldReadFunction<any>
}
export type EmptyResponseKeySpecifier = ('alwaysNil' | EmptyResponseKeySpecifier)[]
export type EmptyResponseFieldPolicy = {
    alwaysNil?: FieldPolicy<any> | FieldReadFunction<any>
}
export type NodeKeySpecifier = ('id' | NodeKeySpecifier)[]
export type NodeFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SettingsMutationKeySpecifier = (
    | 'editSettings'
    | 'editConfiguration'
    | 'overwriteSettings'
    | SettingsMutationKeySpecifier
)[]
export type SettingsMutationFieldPolicy = {
    editSettings?: FieldPolicy<any> | FieldReadFunction<any>
    editConfiguration?: FieldPolicy<any> | FieldReadFunction<any>
    overwriteSettings?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UpdateSettingsPayloadKeySpecifier = ('empty' | UpdateSettingsPayloadKeySpecifier)[]
export type UpdateSettingsPayloadFieldPolicy = {
    empty?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CreateAccessTokenResultKeySpecifier = ('id' | 'token' | CreateAccessTokenResultKeySpecifier)[]
export type CreateAccessTokenResultFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    token?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CheckMirrorRepositoryConnectionResultKeySpecifier = (
    | 'error'
    | CheckMirrorRepositoryConnectionResultKeySpecifier
)[]
export type CheckMirrorRepositoryConnectionResultFieldPolicy = {
    error?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CreateUserResultKeySpecifier = ('user' | 'resetPasswordURL' | CreateUserResultKeySpecifier)[]
export type CreateUserResultFieldPolicy = {
    user?: FieldPolicy<any> | FieldReadFunction<any>
    resetPasswordURL?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RandomizeUserPasswordResultKeySpecifier = ('resetPasswordURL' | RandomizeUserPasswordResultKeySpecifier)[]
export type RandomizeUserPasswordResultFieldPolicy = {
    resetPasswordURL?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExecutorConnectionKeySpecifier = ('nodes' | 'totalCount' | 'pageInfo' | ExecutorConnectionKeySpecifier)[]
export type ExecutorConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExecutorKeySpecifier = (
    | 'id'
    | 'hostname'
    | 'queueName'
    | 'active'
    | 'os'
    | 'architecture'
    | 'dockerVersion'
    | 'executorVersion'
    | 'gitVersion'
    | 'igniteVersion'
    | 'srcCliVersion'
    | 'firstSeenAt'
    | 'lastSeenAt'
    | ExecutorKeySpecifier
)[]
export type ExecutorFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    hostname?: FieldPolicy<any> | FieldReadFunction<any>
    queueName?: FieldPolicy<any> | FieldReadFunction<any>
    active?: FieldPolicy<any> | FieldReadFunction<any>
    os?: FieldPolicy<any> | FieldReadFunction<any>
    architecture?: FieldPolicy<any> | FieldReadFunction<any>
    dockerVersion?: FieldPolicy<any> | FieldReadFunction<any>
    executorVersion?: FieldPolicy<any> | FieldReadFunction<any>
    gitVersion?: FieldPolicy<any> | FieldReadFunction<any>
    igniteVersion?: FieldPolicy<any> | FieldReadFunction<any>
    srcCliVersion?: FieldPolicy<any> | FieldReadFunction<any>
    firstSeenAt?: FieldPolicy<any> | FieldReadFunction<any>
    lastSeenAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type FeatureFlagBooleanKeySpecifier = ('name' | 'value' | 'overrides' | FeatureFlagBooleanKeySpecifier)[]
export type FeatureFlagBooleanFieldPolicy = {
    name?: FieldPolicy<any> | FieldReadFunction<any>
    value?: FieldPolicy<any> | FieldReadFunction<any>
    overrides?: FieldPolicy<any> | FieldReadFunction<any>
}
export type FeatureFlagRolloutKeySpecifier = (
    | 'name'
    | 'rolloutBasisPoints'
    | 'overrides'
    | FeatureFlagRolloutKeySpecifier
)[]
export type FeatureFlagRolloutFieldPolicy = {
    name?: FieldPolicy<any> | FieldReadFunction<any>
    rolloutBasisPoints?: FieldPolicy<any> | FieldReadFunction<any>
    overrides?: FieldPolicy<any> | FieldReadFunction<any>
}
export type FeatureFlagOverrideKeySpecifier = (
    | 'id'
    | 'namespace'
    | 'targetFlag'
    | 'value'
    | FeatureFlagOverrideKeySpecifier
)[]
export type FeatureFlagOverrideFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    namespace?: FieldPolicy<any> | FieldReadFunction<any>
    targetFlag?: FieldPolicy<any> | FieldReadFunction<any>
    value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type EvaluatedFeatureFlagKeySpecifier = ('name' | 'value' | EvaluatedFeatureFlagKeySpecifier)[]
export type EvaluatedFeatureFlagFieldPolicy = {
    name?: FieldPolicy<any> | FieldReadFunction<any>
    value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type OutOfBandMigrationKeySpecifier = (
    | 'id'
    | 'team'
    | 'component'
    | 'description'
    | 'introduced'
    | 'deprecated'
    | 'progress'
    | 'created'
    | 'lastUpdated'
    | 'nonDestructive'
    | 'applyReverse'
    | 'errors'
    | OutOfBandMigrationKeySpecifier
)[]
export type OutOfBandMigrationFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    team?: FieldPolicy<any> | FieldReadFunction<any>
    component?: FieldPolicy<any> | FieldReadFunction<any>
    description?: FieldPolicy<any> | FieldReadFunction<any>
    introduced?: FieldPolicy<any> | FieldReadFunction<any>
    deprecated?: FieldPolicy<any> | FieldReadFunction<any>
    progress?: FieldPolicy<any> | FieldReadFunction<any>
    created?: FieldPolicy<any> | FieldReadFunction<any>
    lastUpdated?: FieldPolicy<any> | FieldReadFunction<any>
    nonDestructive?: FieldPolicy<any> | FieldReadFunction<any>
    applyReverse?: FieldPolicy<any> | FieldReadFunction<any>
    errors?: FieldPolicy<any> | FieldReadFunction<any>
}
export type OutOfBandMigrationErrorKeySpecifier = ('message' | 'created' | OutOfBandMigrationErrorKeySpecifier)[]
export type OutOfBandMigrationErrorFieldPolicy = {
    message?: FieldPolicy<any> | FieldReadFunction<any>
    created?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ClientConfigurationDetailsKeySpecifier = (
    | 'contentScriptUrls'
    | 'parentSourcegraph'
    | ClientConfigurationDetailsKeySpecifier
)[]
export type ClientConfigurationDetailsFieldPolicy = {
    contentScriptUrls?: FieldPolicy<any> | FieldReadFunction<any>
    parentSourcegraph?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ParentSourcegraphDetailsKeySpecifier = ('url' | ParentSourcegraphDetailsKeySpecifier)[]
export type ParentSourcegraphDetailsFieldPolicy = {
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SearchKeySpecifier = ('results' | 'stats' | SearchKeySpecifier)[]
export type SearchFieldPolicy = {
    results?: FieldPolicy<any> | FieldReadFunction<any>
    stats?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MarkdownKeySpecifier = ('text' | 'html' | MarkdownKeySpecifier)[]
export type MarkdownFieldPolicy = {
    text?: FieldPolicy<any> | FieldReadFunction<any>
    html?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GenericSearchResultInterfaceKeySpecifier = (
    | 'label'
    | 'url'
    | 'detail'
    | 'matches'
    | GenericSearchResultInterfaceKeySpecifier
)[]
export type GenericSearchResultInterfaceFieldPolicy = {
    label?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
    detail?: FieldPolicy<any> | FieldReadFunction<any>
    matches?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SearchResultMatchKeySpecifier = ('url' | 'body' | 'highlights' | SearchResultMatchKeySpecifier)[]
export type SearchResultMatchFieldPolicy = {
    url?: FieldPolicy<any> | FieldReadFunction<any>
    body?: FieldPolicy<any> | FieldReadFunction<any>
    highlights?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SearchResultsKeySpecifier = (
    | 'results'
    | 'matchCount'
    | 'resultCount'
    | 'approximateResultCount'
    | 'limitHit'
    | 'sparkline'
    | 'repositories'
    | 'repositoriesCount'
    | 'cloning'
    | 'missing'
    | 'timedout'
    | 'indexUnavailable'
    | 'alert'
    | 'elapsedMilliseconds'
    | 'dynamicFilters'
    | SearchResultsKeySpecifier
)[]
export type SearchResultsFieldPolicy = {
    results?: FieldPolicy<any> | FieldReadFunction<any>
    matchCount?: FieldPolicy<any> | FieldReadFunction<any>
    resultCount?: FieldPolicy<any> | FieldReadFunction<any>
    approximateResultCount?: FieldPolicy<any> | FieldReadFunction<any>
    limitHit?: FieldPolicy<any> | FieldReadFunction<any>
    sparkline?: FieldPolicy<any> | FieldReadFunction<any>
    repositories?: FieldPolicy<any> | FieldReadFunction<any>
    repositoriesCount?: FieldPolicy<any> | FieldReadFunction<any>
    cloning?: FieldPolicy<any> | FieldReadFunction<any>
    missing?: FieldPolicy<any> | FieldReadFunction<any>
    timedout?: FieldPolicy<any> | FieldReadFunction<any>
    indexUnavailable?: FieldPolicy<any> | FieldReadFunction<any>
    alert?: FieldPolicy<any> | FieldReadFunction<any>
    elapsedMilliseconds?: FieldPolicy<any> | FieldReadFunction<any>
    dynamicFilters?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SearchResultsStatsKeySpecifier = (
    | 'approximateResultCount'
    | 'sparkline'
    | 'languages'
    | SearchResultsStatsKeySpecifier
)[]
export type SearchResultsStatsFieldPolicy = {
    approximateResultCount?: FieldPolicy<any> | FieldReadFunction<any>
    sparkline?: FieldPolicy<any> | FieldReadFunction<any>
    languages?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SearchFilterKeySpecifier = ('value' | 'label' | 'count' | 'limitHit' | 'kind' | SearchFilterKeySpecifier)[]
export type SearchFilterFieldPolicy = {
    value?: FieldPolicy<any> | FieldReadFunction<any>
    label?: FieldPolicy<any> | FieldReadFunction<any>
    count?: FieldPolicy<any> | FieldReadFunction<any>
    limitHit?: FieldPolicy<any> | FieldReadFunction<any>
    kind?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LanguageKeySpecifier = ('name' | LanguageKeySpecifier)[]
export type LanguageFieldPolicy = {
    name?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SearchAlertKeySpecifier = ('title' | 'description' | 'kind' | 'proposedQueries' | SearchAlertKeySpecifier)[]
export type SearchAlertFieldPolicy = {
    title?: FieldPolicy<any> | FieldReadFunction<any>
    description?: FieldPolicy<any> | FieldReadFunction<any>
    kind?: FieldPolicy<any> | FieldReadFunction<any>
    proposedQueries?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SavedSearchKeySpecifier = (
    | 'id'
    | 'description'
    | 'query'
    | 'notify'
    | 'notifySlack'
    | 'namespace'
    | 'slackWebhookURL'
    | SavedSearchKeySpecifier
)[]
export type SavedSearchFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    description?: FieldPolicy<any> | FieldReadFunction<any>
    query?: FieldPolicy<any> | FieldReadFunction<any>
    notify?: FieldPolicy<any> | FieldReadFunction<any>
    notifySlack?: FieldPolicy<any> | FieldReadFunction<any>
    namespace?: FieldPolicy<any> | FieldReadFunction<any>
    slackWebhookURL?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SearchQueryDescriptionKeySpecifier = (
    | 'description'
    | 'query'
    | 'annotations'
    | SearchQueryDescriptionKeySpecifier
)[]
export type SearchQueryDescriptionFieldPolicy = {
    description?: FieldPolicy<any> | FieldReadFunction<any>
    query?: FieldPolicy<any> | FieldReadFunction<any>
    annotations?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SearchQueryAnnotationKeySpecifier = ('name' | 'value' | SearchQueryAnnotationKeySpecifier)[]
export type SearchQueryAnnotationFieldPolicy = {
    name?: FieldPolicy<any> | FieldReadFunction<any>
    value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type DiffKeySpecifier = ('repository' | 'range' | DiffKeySpecifier)[]
export type DiffFieldPolicy = {
    repository?: FieldPolicy<any> | FieldReadFunction<any>
    range?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CommitSearchResultKeySpecifier = (
    | 'label'
    | 'url'
    | 'detail'
    | 'matches'
    | 'commit'
    | 'refs'
    | 'sourceRefs'
    | 'messagePreview'
    | 'diffPreview'
    | CommitSearchResultKeySpecifier
)[]
export type CommitSearchResultFieldPolicy = {
    label?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
    detail?: FieldPolicy<any> | FieldReadFunction<any>
    matches?: FieldPolicy<any> | FieldReadFunction<any>
    commit?: FieldPolicy<any> | FieldReadFunction<any>
    refs?: FieldPolicy<any> | FieldReadFunction<any>
    sourceRefs?: FieldPolicy<any> | FieldReadFunction<any>
    messagePreview?: FieldPolicy<any> | FieldReadFunction<any>
    diffPreview?: FieldPolicy<any> | FieldReadFunction<any>
}
export type HighlightedStringKeySpecifier = ('value' | 'highlights' | HighlightedStringKeySpecifier)[]
export type HighlightedStringFieldPolicy = {
    value?: FieldPolicy<any> | FieldReadFunction<any>
    highlights?: FieldPolicy<any> | FieldReadFunction<any>
}
export type HighlightKeySpecifier = ('line' | 'character' | 'length' | HighlightKeySpecifier)[]
export type HighlightFieldPolicy = {
    line?: FieldPolicy<any> | FieldReadFunction<any>
    character?: FieldPolicy<any> | FieldReadFunction<any>
    length?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExternalServiceConnectionKeySpecifier = (
    | 'nodes'
    | 'totalCount'
    | 'pageInfo'
    | ExternalServiceConnectionKeySpecifier
)[]
export type ExternalServiceConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExternalServiceKeySpecifier = (
    | 'id'
    | 'kind'
    | 'displayName'
    | 'config'
    | 'createdAt'
    | 'updatedAt'
    | 'namespace'
    | 'repoCount'
    | 'webhookURL'
    | 'warning'
    | 'lastSyncError'
    | 'lastSyncAt'
    | 'nextSyncAt'
    | 'grantedScopes'
    | 'webhookLogs'
    | 'invitableCollaborators'
    | 'syncJobs'
    | ExternalServiceKeySpecifier
)[]
export type ExternalServiceFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    kind?: FieldPolicy<any> | FieldReadFunction<any>
    displayName?: FieldPolicy<any> | FieldReadFunction<any>
    config?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
    namespace?: FieldPolicy<any> | FieldReadFunction<any>
    repoCount?: FieldPolicy<any> | FieldReadFunction<any>
    webhookURL?: FieldPolicy<any> | FieldReadFunction<any>
    warning?: FieldPolicy<any> | FieldReadFunction<any>
    lastSyncError?: FieldPolicy<any> | FieldReadFunction<any>
    lastSyncAt?: FieldPolicy<any> | FieldReadFunction<any>
    nextSyncAt?: FieldPolicy<any> | FieldReadFunction<any>
    grantedScopes?: FieldPolicy<any> | FieldReadFunction<any>
    webhookLogs?: FieldPolicy<any> | FieldReadFunction<any>
    invitableCollaborators?: FieldPolicy<any> | FieldReadFunction<any>
    syncJobs?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExternalServiceSyncJobConnectionKeySpecifier = (
    | 'nodes'
    | 'totalCount'
    | 'pageInfo'
    | ExternalServiceSyncJobConnectionKeySpecifier
)[]
export type ExternalServiceSyncJobConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExternalServiceSyncJobKeySpecifier = (
    | 'id'
    | 'state'
    | 'queuedAt'
    | 'startedAt'
    | 'finishedAt'
    | 'failureMessage'
    | ExternalServiceSyncJobKeySpecifier
)[]
export type ExternalServiceSyncJobFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    state?: FieldPolicy<any> | FieldReadFunction<any>
    queuedAt?: FieldPolicy<any> | FieldReadFunction<any>
    startedAt?: FieldPolicy<any> | FieldReadFunction<any>
    finishedAt?: FieldPolicy<any> | FieldReadFunction<any>
    failureMessage?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RepositoryConnectionKeySpecifier = (
    | 'nodes'
    | 'totalCount'
    | 'pageInfo'
    | RepositoryConnectionKeySpecifier
)[]
export type RepositoryConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type KeyValuePairKeySpecifier = ('key' | 'value' | KeyValuePairKeySpecifier)[]
export type KeyValuePairFieldPolicy = {
    key?: FieldPolicy<any> | FieldReadFunction<any>
    value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CodeIntelligenceCommitGraphKeySpecifier = (
    | 'stale'
    | 'updatedAt'
    | CodeIntelligenceCommitGraphKeySpecifier
)[]
export type CodeIntelligenceCommitGraphFieldPolicy = {
    stale?: FieldPolicy<any> | FieldReadFunction<any>
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RedirectKeySpecifier = ('url' | RedirectKeySpecifier)[]
export type RedirectFieldPolicy = {
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExternalLinkKeySpecifier = ('url' | 'serviceKind' | 'serviceType' | ExternalLinkKeySpecifier)[]
export type ExternalLinkFieldPolicy = {
    url?: FieldPolicy<any> | FieldReadFunction<any>
    serviceKind?: FieldPolicy<any> | FieldReadFunction<any>
    serviceType?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MirrorRepositoryInfoKeySpecifier = (
    | 'remoteURL'
    | 'cloneInProgress'
    | 'cloneProgress'
    | 'cloned'
    | 'updatedAt'
    | 'updateSchedule'
    | 'updateQueue'
    | 'lastError'
    | 'byteSize'
    | MirrorRepositoryInfoKeySpecifier
)[]
export type MirrorRepositoryInfoFieldPolicy = {
    remoteURL?: FieldPolicy<any> | FieldReadFunction<any>
    cloneInProgress?: FieldPolicy<any> | FieldReadFunction<any>
    cloneProgress?: FieldPolicy<any> | FieldReadFunction<any>
    cloned?: FieldPolicy<any> | FieldReadFunction<any>
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
    updateSchedule?: FieldPolicy<any> | FieldReadFunction<any>
    updateQueue?: FieldPolicy<any> | FieldReadFunction<any>
    lastError?: FieldPolicy<any> | FieldReadFunction<any>
    byteSize?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UpdateScheduleKeySpecifier = ('intervalSeconds' | 'due' | 'index' | 'total' | UpdateScheduleKeySpecifier)[]
export type UpdateScheduleFieldPolicy = {
    intervalSeconds?: FieldPolicy<any> | FieldReadFunction<any>
    due?: FieldPolicy<any> | FieldReadFunction<any>
    index?: FieldPolicy<any> | FieldReadFunction<any>
    total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UpdateQueueKeySpecifier = ('index' | 'updating' | 'total' | UpdateQueueKeySpecifier)[]
export type UpdateQueueFieldPolicy = {
    index?: FieldPolicy<any> | FieldReadFunction<any>
    updating?: FieldPolicy<any> | FieldReadFunction<any>
    total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExternalRepositoryKeySpecifier = ('id' | 'serviceType' | 'serviceID' | ExternalRepositoryKeySpecifier)[]
export type ExternalRepositoryFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    serviceType?: FieldPolicy<any> | FieldReadFunction<any>
    serviceID?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RepositoryTextSearchIndexKeySpecifier = (
    | 'repository'
    | 'status'
    | 'refs'
    | RepositoryTextSearchIndexKeySpecifier
)[]
export type RepositoryTextSearchIndexFieldPolicy = {
    repository?: FieldPolicy<any> | FieldReadFunction<any>
    status?: FieldPolicy<any> | FieldReadFunction<any>
    refs?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RepositoryTextSearchIndexStatusKeySpecifier = (
    | 'updatedAt'
    | 'contentByteSize'
    | 'contentFilesCount'
    | 'indexByteSize'
    | 'indexShardsCount'
    | 'newLinesCount'
    | 'defaultBranchNewLinesCount'
    | 'otherBranchesNewLinesCount'
    | RepositoryTextSearchIndexStatusKeySpecifier
)[]
export type RepositoryTextSearchIndexStatusFieldPolicy = {
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
    contentByteSize?: FieldPolicy<any> | FieldReadFunction<any>
    contentFilesCount?: FieldPolicy<any> | FieldReadFunction<any>
    indexByteSize?: FieldPolicy<any> | FieldReadFunction<any>
    indexShardsCount?: FieldPolicy<any> | FieldReadFunction<any>
    newLinesCount?: FieldPolicy<any> | FieldReadFunction<any>
    defaultBranchNewLinesCount?: FieldPolicy<any> | FieldReadFunction<any>
    otherBranchesNewLinesCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RepositoryTextSearchIndexedRefKeySpecifier = (
    | 'ref'
    | 'indexed'
    | 'current'
    | 'indexedCommit'
    | 'skippedIndexed'
    | RepositoryTextSearchIndexedRefKeySpecifier
)[]
export type RepositoryTextSearchIndexedRefFieldPolicy = {
    ref?: FieldPolicy<any> | FieldReadFunction<any>
    indexed?: FieldPolicy<any> | FieldReadFunction<any>
    current?: FieldPolicy<any> | FieldReadFunction<any>
    indexedCommit?: FieldPolicy<any> | FieldReadFunction<any>
    skippedIndexed?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SkippedIndexedKeySpecifier = ('count' | 'query' | SkippedIndexedKeySpecifier)[]
export type SkippedIndexedFieldPolicy = {
    count?: FieldPolicy<any> | FieldReadFunction<any>
    query?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GitRefConnectionKeySpecifier = ('nodes' | 'totalCount' | 'pageInfo' | GitRefConnectionKeySpecifier)[]
export type GitRefConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PreviewRepositoryComparisonKeySpecifier = (
    | 'baseRepository'
    | 'fileDiffs'
    | PreviewRepositoryComparisonKeySpecifier
)[]
export type PreviewRepositoryComparisonFieldPolicy = {
    baseRepository?: FieldPolicy<any> | FieldReadFunction<any>
    fileDiffs?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RepositoryComparisonKeySpecifier = (
    | 'baseRepository'
    | 'headRepository'
    | 'range'
    | 'commits'
    | 'fileDiffs'
    | RepositoryComparisonKeySpecifier
)[]
export type RepositoryComparisonFieldPolicy = {
    baseRepository?: FieldPolicy<any> | FieldReadFunction<any>
    headRepository?: FieldPolicy<any> | FieldReadFunction<any>
    range?: FieldPolicy<any> | FieldReadFunction<any>
    commits?: FieldPolicy<any> | FieldReadFunction<any>
    fileDiffs?: FieldPolicy<any> | FieldReadFunction<any>
}
export type FileDiffConnectionKeySpecifier = (
    | 'nodes'
    | 'totalCount'
    | 'pageInfo'
    | 'diffStat'
    | 'rawDiff'
    | FileDiffConnectionKeySpecifier
)[]
export type FileDiffConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    diffStat?: FieldPolicy<any> | FieldReadFunction<any>
    rawDiff?: FieldPolicy<any> | FieldReadFunction<any>
}
export type FileDiffKeySpecifier = (
    | 'oldPath'
    | 'oldFile'
    | 'newPath'
    | 'newFile'
    | 'mostRelevantFile'
    | 'hunks'
    | 'stat'
    | 'internalID'
    | FileDiffKeySpecifier
)[]
export type FileDiffFieldPolicy = {
    oldPath?: FieldPolicy<any> | FieldReadFunction<any>
    oldFile?: FieldPolicy<any> | FieldReadFunction<any>
    newPath?: FieldPolicy<any> | FieldReadFunction<any>
    newFile?: FieldPolicy<any> | FieldReadFunction<any>
    mostRelevantFile?: FieldPolicy<any> | FieldReadFunction<any>
    hunks?: FieldPolicy<any> | FieldReadFunction<any>
    stat?: FieldPolicy<any> | FieldReadFunction<any>
    internalID?: FieldPolicy<any> | FieldReadFunction<any>
}
export type HighlightedDiffHunkLineKeySpecifier = ('html' | 'kind' | HighlightedDiffHunkLineKeySpecifier)[]
export type HighlightedDiffHunkLineFieldPolicy = {
    html?: FieldPolicy<any> | FieldReadFunction<any>
    kind?: FieldPolicy<any> | FieldReadFunction<any>
}
export type HighlightedDiffHunkBodyKeySpecifier = ('aborted' | 'lines' | HighlightedDiffHunkBodyKeySpecifier)[]
export type HighlightedDiffHunkBodyFieldPolicy = {
    aborted?: FieldPolicy<any> | FieldReadFunction<any>
    lines?: FieldPolicy<any> | FieldReadFunction<any>
}
export type FileDiffHunkKeySpecifier = (
    | 'oldRange'
    | 'oldNoNewlineAt'
    | 'newRange'
    | 'section'
    | 'body'
    | 'highlight'
    | FileDiffHunkKeySpecifier
)[]
export type FileDiffHunkFieldPolicy = {
    oldRange?: FieldPolicy<any> | FieldReadFunction<any>
    oldNoNewlineAt?: FieldPolicy<any> | FieldReadFunction<any>
    newRange?: FieldPolicy<any> | FieldReadFunction<any>
    section?: FieldPolicy<any> | FieldReadFunction<any>
    body?: FieldPolicy<any> | FieldReadFunction<any>
    highlight?: FieldPolicy<any> | FieldReadFunction<any>
}
export type FileDiffHunkRangeKeySpecifier = ('startLine' | 'lines' | FileDiffHunkRangeKeySpecifier)[]
export type FileDiffHunkRangeFieldPolicy = {
    startLine?: FieldPolicy<any> | FieldReadFunction<any>
    lines?: FieldPolicy<any> | FieldReadFunction<any>
}
export type DiffStatKeySpecifier = ('added' | 'deleted' | DiffStatKeySpecifier)[]
export type DiffStatFieldPolicy = {
    added?: FieldPolicy<any> | FieldReadFunction<any>
    deleted?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RepositoryContributorConnectionKeySpecifier = (
    | 'nodes'
    | 'totalCount'
    | 'pageInfo'
    | RepositoryContributorConnectionKeySpecifier
)[]
export type RepositoryContributorConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RepositoryContributorKeySpecifier = (
    | 'person'
    | 'count'
    | 'repository'
    | 'commits'
    | RepositoryContributorKeySpecifier
)[]
export type RepositoryContributorFieldPolicy = {
    person?: FieldPolicy<any> | FieldReadFunction<any>
    count?: FieldPolicy<any> | FieldReadFunction<any>
    repository?: FieldPolicy<any> | FieldReadFunction<any>
    commits?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SymbolKeySpecifier = (
    | 'name'
    | 'containerName'
    | 'kind'
    | 'language'
    | 'location'
    | 'url'
    | 'canonicalURL'
    | 'fileLocal'
    | SymbolKeySpecifier
)[]
export type SymbolFieldPolicy = {
    name?: FieldPolicy<any> | FieldReadFunction<any>
    containerName?: FieldPolicy<any> | FieldReadFunction<any>
    kind?: FieldPolicy<any> | FieldReadFunction<any>
    language?: FieldPolicy<any> | FieldReadFunction<any>
    location?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
    canonicalURL?: FieldPolicy<any> | FieldReadFunction<any>
    fileLocal?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LocationKeySpecifier = ('resource' | 'range' | 'url' | 'canonicalURL' | LocationKeySpecifier)[]
export type LocationFieldPolicy = {
    resource?: FieldPolicy<any> | FieldReadFunction<any>
    range?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
    canonicalURL?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RangeKeySpecifier = ('start' | 'end' | RangeKeySpecifier)[]
export type RangeFieldPolicy = {
    start?: FieldPolicy<any> | FieldReadFunction<any>
    end?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PositionKeySpecifier = ('line' | 'character' | PositionKeySpecifier)[]
export type PositionFieldPolicy = {
    line?: FieldPolicy<any> | FieldReadFunction<any>
    character?: FieldPolicy<any> | FieldReadFunction<any>
}
export type DiagnosticConnectionKeySpecifier = (
    | 'nodes'
    | 'totalCount'
    | 'pageInfo'
    | DiagnosticConnectionKeySpecifier
)[]
export type DiagnosticConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type DiagnosticKeySpecifier = (
    | 'location'
    | 'severity'
    | 'code'
    | 'source'
    | 'message'
    | DiagnosticKeySpecifier
)[]
export type DiagnosticFieldPolicy = {
    location?: FieldPolicy<any> | FieldReadFunction<any>
    severity?: FieldPolicy<any> | FieldReadFunction<any>
    code?: FieldPolicy<any> | FieldReadFunction<any>
    source?: FieldPolicy<any> | FieldReadFunction<any>
    message?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SymbolConnectionKeySpecifier = ('nodes' | 'pageInfo' | SymbolConnectionKeySpecifier)[]
export type SymbolConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GitRefKeySpecifier = (
    | 'id'
    | 'name'
    | 'abbrevName'
    | 'displayName'
    | 'prefix'
    | 'type'
    | 'target'
    | 'repository'
    | 'url'
    | GitRefKeySpecifier
)[]
export type GitRefFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    abbrevName?: FieldPolicy<any> | FieldReadFunction<any>
    displayName?: FieldPolicy<any> | FieldReadFunction<any>
    prefix?: FieldPolicy<any> | FieldReadFunction<any>
    type?: FieldPolicy<any> | FieldReadFunction<any>
    target?: FieldPolicy<any> | FieldReadFunction<any>
    repository?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GitObjectKeySpecifier = ('oid' | 'abbreviatedOID' | 'commit' | 'type' | GitObjectKeySpecifier)[]
export type GitObjectFieldPolicy = {
    oid?: FieldPolicy<any> | FieldReadFunction<any>
    abbreviatedOID?: FieldPolicy<any> | FieldReadFunction<any>
    commit?: FieldPolicy<any> | FieldReadFunction<any>
    type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GitRevSpecExprKeySpecifier = ('expr' | 'object' | GitRevSpecExprKeySpecifier)[]
export type GitRevSpecExprFieldPolicy = {
    expr?: FieldPolicy<any> | FieldReadFunction<any>
    object?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GitRevisionRangeKeySpecifier = (
    | 'expr'
    | 'base'
    | 'baseRevSpec'
    | 'head'
    | 'headRevSpec'
    | 'mergeBase'
    | GitRevisionRangeKeySpecifier
)[]
export type GitRevisionRangeFieldPolicy = {
    expr?: FieldPolicy<any> | FieldReadFunction<any>
    base?: FieldPolicy<any> | FieldReadFunction<any>
    baseRevSpec?: FieldPolicy<any> | FieldReadFunction<any>
    head?: FieldPolicy<any> | FieldReadFunction<any>
    headRevSpec?: FieldPolicy<any> | FieldReadFunction<any>
    mergeBase?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PhabricatorRepoKeySpecifier = ('name' | 'uri' | 'callsign' | 'url' | PhabricatorRepoKeySpecifier)[]
export type PhabricatorRepoFieldPolicy = {
    name?: FieldPolicy<any> | FieldReadFunction<any>
    uri?: FieldPolicy<any> | FieldReadFunction<any>
    callsign?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PageInfoKeySpecifier = ('endCursor' | 'hasNextPage' | PageInfoKeySpecifier)[]
export type PageInfoFieldPolicy = {
    endCursor?: FieldPolicy<any> | FieldReadFunction<any>
    hasNextPage?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GitCommitConnectionKeySpecifier = ('nodes' | 'totalCount' | 'pageInfo' | GitCommitConnectionKeySpecifier)[]
export type GitCommitConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LanguageStatisticsKeySpecifier = ('name' | 'totalBytes' | 'totalLines' | LanguageStatisticsKeySpecifier)[]
export type LanguageStatisticsFieldPolicy = {
    name?: FieldPolicy<any> | FieldReadFunction<any>
    totalBytes?: FieldPolicy<any> | FieldReadFunction<any>
    totalLines?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GitCommitKeySpecifier = (
    | 'id'
    | 'repository'
    | 'oid'
    | 'abbreviatedOID'
    | 'author'
    | 'committer'
    | 'message'
    | 'subject'
    | 'body'
    | 'parents'
    | 'url'
    | 'canonicalURL'
    | 'externalURLs'
    | 'path'
    | 'tree'
    | 'fileNames'
    | 'blob'
    | 'file'
    | 'languages'
    | 'languageStatistics'
    | 'ancestors'
    | 'behindAhead'
    | 'symbols'
    | 'diff'
    | GitCommitKeySpecifier
)[]
export type GitCommitFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    repository?: FieldPolicy<any> | FieldReadFunction<any>
    oid?: FieldPolicy<any> | FieldReadFunction<any>
    abbreviatedOID?: FieldPolicy<any> | FieldReadFunction<any>
    author?: FieldPolicy<any> | FieldReadFunction<any>
    committer?: FieldPolicy<any> | FieldReadFunction<any>
    message?: FieldPolicy<any> | FieldReadFunction<any>
    subject?: FieldPolicy<any> | FieldReadFunction<any>
    body?: FieldPolicy<any> | FieldReadFunction<any>
    parents?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
    canonicalURL?: FieldPolicy<any> | FieldReadFunction<any>
    externalURLs?: FieldPolicy<any> | FieldReadFunction<any>
    path?: FieldPolicy<any> | FieldReadFunction<any>
    tree?: FieldPolicy<any> | FieldReadFunction<any>
    fileNames?: FieldPolicy<any> | FieldReadFunction<any>
    blob?: FieldPolicy<any> | FieldReadFunction<any>
    file?: FieldPolicy<any> | FieldReadFunction<any>
    languages?: FieldPolicy<any> | FieldReadFunction<any>
    languageStatistics?: FieldPolicy<any> | FieldReadFunction<any>
    ancestors?: FieldPolicy<any> | FieldReadFunction<any>
    behindAhead?: FieldPolicy<any> | FieldReadFunction<any>
    symbols?: FieldPolicy<any> | FieldReadFunction<any>
    diff?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BehindAheadCountsKeySpecifier = ('behind' | 'ahead' | BehindAheadCountsKeySpecifier)[]
export type BehindAheadCountsFieldPolicy = {
    behind?: FieldPolicy<any> | FieldReadFunction<any>
    ahead?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SignatureKeySpecifier = ('person' | 'date' | SignatureKeySpecifier)[]
export type SignatureFieldPolicy = {
    person?: FieldPolicy<any> | FieldReadFunction<any>
    date?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PersonKeySpecifier = ('name' | 'email' | 'displayName' | 'avatarURL' | 'user' | PersonKeySpecifier)[]
export type PersonFieldPolicy = {
    name?: FieldPolicy<any> | FieldReadFunction<any>
    email?: FieldPolicy<any> | FieldReadFunction<any>
    displayName?: FieldPolicy<any> | FieldReadFunction<any>
    avatarURL?: FieldPolicy<any> | FieldReadFunction<any>
    user?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SubmoduleKeySpecifier = ('url' | 'commit' | 'path' | SubmoduleKeySpecifier)[]
export type SubmoduleFieldPolicy = {
    url?: FieldPolicy<any> | FieldReadFunction<any>
    commit?: FieldPolicy<any> | FieldReadFunction<any>
    path?: FieldPolicy<any> | FieldReadFunction<any>
}
export type File2KeySpecifier = (
    | 'path'
    | 'name'
    | 'isDirectory'
    | 'content'
    | 'byteSize'
    | 'binary'
    | 'richHTML'
    | 'url'
    | 'canonicalURL'
    | 'externalURLs'
    | 'highlight'
    | File2KeySpecifier
)[]
export type File2FieldPolicy = {
    path?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    isDirectory?: FieldPolicy<any> | FieldReadFunction<any>
    content?: FieldPolicy<any> | FieldReadFunction<any>
    byteSize?: FieldPolicy<any> | FieldReadFunction<any>
    binary?: FieldPolicy<any> | FieldReadFunction<any>
    richHTML?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
    canonicalURL?: FieldPolicy<any> | FieldReadFunction<any>
    externalURLs?: FieldPolicy<any> | FieldReadFunction<any>
    highlight?: FieldPolicy<any> | FieldReadFunction<any>
}
export type VirtualFileKeySpecifier = (
    | 'path'
    | 'name'
    | 'isDirectory'
    | 'content'
    | 'byteSize'
    | 'binary'
    | 'richHTML'
    | 'url'
    | 'canonicalURL'
    | 'externalURLs'
    | 'highlight'
    | VirtualFileKeySpecifier
)[]
export type VirtualFileFieldPolicy = {
    path?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    isDirectory?: FieldPolicy<any> | FieldReadFunction<any>
    content?: FieldPolicy<any> | FieldReadFunction<any>
    byteSize?: FieldPolicy<any> | FieldReadFunction<any>
    binary?: FieldPolicy<any> | FieldReadFunction<any>
    richHTML?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
    canonicalURL?: FieldPolicy<any> | FieldReadFunction<any>
    externalURLs?: FieldPolicy<any> | FieldReadFunction<any>
    highlight?: FieldPolicy<any> | FieldReadFunction<any>
}
export type FileKeySpecifier = ('path' | 'name' | 'isDirectory' | 'url' | 'repository' | FileKeySpecifier)[]
export type FileFieldPolicy = {
    path?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    isDirectory?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
    repository?: FieldPolicy<any> | FieldReadFunction<any>
}
export type HighlightedFileKeySpecifier = ('aborted' | 'html' | 'lsif' | 'lineRanges' | HighlightedFileKeySpecifier)[]
export type HighlightedFileFieldPolicy = {
    aborted?: FieldPolicy<any> | FieldReadFunction<any>
    html?: FieldPolicy<any> | FieldReadFunction<any>
    lsif?: FieldPolicy<any> | FieldReadFunction<any>
    lineRanges?: FieldPolicy<any> | FieldReadFunction<any>
}
export type FileMatchKeySpecifier = (
    | 'file'
    | 'repository'
    | 'revSpec'
    | 'symbols'
    | 'lineMatches'
    | 'chunkMatches'
    | 'limitHit'
    | FileMatchKeySpecifier
)[]
export type FileMatchFieldPolicy = {
    file?: FieldPolicy<any> | FieldReadFunction<any>
    repository?: FieldPolicy<any> | FieldReadFunction<any>
    revSpec?: FieldPolicy<any> | FieldReadFunction<any>
    symbols?: FieldPolicy<any> | FieldReadFunction<any>
    lineMatches?: FieldPolicy<any> | FieldReadFunction<any>
    chunkMatches?: FieldPolicy<any> | FieldReadFunction<any>
    limitHit?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LineMatchKeySpecifier = (
    | 'preview'
    | 'lineNumber'
    | 'offsetAndLengths'
    | 'limitHit'
    | LineMatchKeySpecifier
)[]
export type LineMatchFieldPolicy = {
    preview?: FieldPolicy<any> | FieldReadFunction<any>
    lineNumber?: FieldPolicy<any> | FieldReadFunction<any>
    offsetAndLengths?: FieldPolicy<any> | FieldReadFunction<any>
    limitHit?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ChunkMatchKeySpecifier = ('content' | 'contentStart' | 'ranges' | ChunkMatchKeySpecifier)[]
export type ChunkMatchFieldPolicy = {
    content?: FieldPolicy<any> | FieldReadFunction<any>
    contentStart?: FieldPolicy<any> | FieldReadFunction<any>
    ranges?: FieldPolicy<any> | FieldReadFunction<any>
}
export type HunkKeySpecifier = (
    | 'startLine'
    | 'endLine'
    | 'startByte'
    | 'endByte'
    | 'rev'
    | 'author'
    | 'message'
    | 'commit'
    | 'filename'
    | HunkKeySpecifier
)[]
export type HunkFieldPolicy = {
    startLine?: FieldPolicy<any> | FieldReadFunction<any>
    endLine?: FieldPolicy<any> | FieldReadFunction<any>
    startByte?: FieldPolicy<any> | FieldReadFunction<any>
    endByte?: FieldPolicy<any> | FieldReadFunction<any>
    rev?: FieldPolicy<any> | FieldReadFunction<any>
    author?: FieldPolicy<any> | FieldReadFunction<any>
    message?: FieldPolicy<any> | FieldReadFunction<any>
    commit?: FieldPolicy<any> | FieldReadFunction<any>
    filename?: FieldPolicy<any> | FieldReadFunction<any>
}
export type NamespaceKeySpecifier = ('id' | 'namespaceName' | 'url' | NamespaceKeySpecifier)[]
export type NamespaceFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    namespaceName?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserConnectionKeySpecifier = ('nodes' | 'totalCount' | 'pageInfo' | UserConnectionKeySpecifier)[]
export type UserConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AccessTokenKeySpecifier = (
    | 'id'
    | 'subject'
    | 'scopes'
    | 'note'
    | 'creator'
    | 'createdAt'
    | 'lastUsedAt'
    | AccessTokenKeySpecifier
)[]
export type AccessTokenFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    subject?: FieldPolicy<any> | FieldReadFunction<any>
    scopes?: FieldPolicy<any> | FieldReadFunction<any>
    note?: FieldPolicy<any> | FieldReadFunction<any>
    creator?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    lastUsedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AccessTokenConnectionKeySpecifier = (
    | 'nodes'
    | 'totalCount'
    | 'pageInfo'
    | AccessTokenConnectionKeySpecifier
)[]
export type AccessTokenConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AuthProviderConnectionKeySpecifier = (
    | 'nodes'
    | 'totalCount'
    | 'pageInfo'
    | AuthProviderConnectionKeySpecifier
)[]
export type AuthProviderConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AuthProviderKeySpecifier = (
    | 'serviceType'
    | 'serviceID'
    | 'clientID'
    | 'displayName'
    | 'isBuiltin'
    | 'authenticationURL'
    | AuthProviderKeySpecifier
)[]
export type AuthProviderFieldPolicy = {
    serviceType?: FieldPolicy<any> | FieldReadFunction<any>
    serviceID?: FieldPolicy<any> | FieldReadFunction<any>
    clientID?: FieldPolicy<any> | FieldReadFunction<any>
    displayName?: FieldPolicy<any> | FieldReadFunction<any>
    isBuiltin?: FieldPolicy<any> | FieldReadFunction<any>
    authenticationURL?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExternalAccountConnectionKeySpecifier = (
    | 'nodes'
    | 'totalCount'
    | 'pageInfo'
    | ExternalAccountConnectionKeySpecifier
)[]
export type ExternalAccountConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExternalAccountKeySpecifier = (
    | 'id'
    | 'user'
    | 'serviceType'
    | 'serviceID'
    | 'clientID'
    | 'accountID'
    | 'createdAt'
    | 'updatedAt'
    | 'refreshURL'
    | 'accountData'
    | ExternalAccountKeySpecifier
)[]
export type ExternalAccountFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    user?: FieldPolicy<any> | FieldReadFunction<any>
    serviceType?: FieldPolicy<any> | FieldReadFunction<any>
    serviceID?: FieldPolicy<any> | FieldReadFunction<any>
    clientID?: FieldPolicy<any> | FieldReadFunction<any>
    accountID?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
    refreshURL?: FieldPolicy<any> | FieldReadFunction<any>
    accountData?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SessionKeySpecifier = ('canSignOut' | SessionKeySpecifier)[]
export type SessionFieldPolicy = {
    canSignOut?: FieldPolicy<any> | FieldReadFunction<any>
}
export type OrganizationMembershipKeySpecifier = (
    | 'organization'
    | 'user'
    | 'createdAt'
    | 'updatedAt'
    | OrganizationMembershipKeySpecifier
)[]
export type OrganizationMembershipFieldPolicy = {
    organization?: FieldPolicy<any> | FieldReadFunction<any>
    user?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type OrganizationMembershipConnectionKeySpecifier = (
    | 'nodes'
    | 'totalCount'
    | OrganizationMembershipConnectionKeySpecifier
)[]
export type OrganizationMembershipConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserEmailKeySpecifier = (
    | 'email'
    | 'isPrimary'
    | 'verified'
    | 'verificationPending'
    | 'user'
    | 'viewerCanManuallyVerify'
    | UserEmailKeySpecifier
)[]
export type UserEmailFieldPolicy = {
    email?: FieldPolicy<any> | FieldReadFunction<any>
    isPrimary?: FieldPolicy<any> | FieldReadFunction<any>
    verified?: FieldPolicy<any> | FieldReadFunction<any>
    verificationPending?: FieldPolicy<any> | FieldReadFunction<any>
    user?: FieldPolicy<any> | FieldReadFunction<any>
    viewerCanManuallyVerify?: FieldPolicy<any> | FieldReadFunction<any>
}
export type OrgConnectionKeySpecifier = ('nodes' | 'totalCount' | OrgConnectionKeySpecifier)[]
export type OrgConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type InviteUserToOrganizationResultKeySpecifier = (
    | 'sentInvitationEmail'
    | 'invitationURL'
    | InviteUserToOrganizationResultKeySpecifier
)[]
export type InviteUserToOrganizationResultFieldPolicy = {
    sentInvitationEmail?: FieldPolicy<any> | FieldReadFunction<any>
    invitationURL?: FieldPolicy<any> | FieldReadFunction<any>
}
export type OrganizationInvitationKeySpecifier = (
    | 'id'
    | 'organization'
    | 'sender'
    | 'recipient'
    | 'recipientEmail'
    | 'createdAt'
    | 'notifiedAt'
    | 'respondedAt'
    | 'responseType'
    | 'respondURL'
    | 'revokedAt'
    | 'expiresAt'
    | 'isVerifiedEmail'
    | OrganizationInvitationKeySpecifier
)[]
export type OrganizationInvitationFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    organization?: FieldPolicy<any> | FieldReadFunction<any>
    sender?: FieldPolicy<any> | FieldReadFunction<any>
    recipient?: FieldPolicy<any> | FieldReadFunction<any>
    recipientEmail?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    notifiedAt?: FieldPolicy<any> | FieldReadFunction<any>
    respondedAt?: FieldPolicy<any> | FieldReadFunction<any>
    responseType?: FieldPolicy<any> | FieldReadFunction<any>
    respondURL?: FieldPolicy<any> | FieldReadFunction<any>
    revokedAt?: FieldPolicy<any> | FieldReadFunction<any>
    expiresAt?: FieldPolicy<any> | FieldReadFunction<any>
    isVerifiedEmail?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AutocompleteMemberSearchItemKeySpecifier = (
    | 'id'
    | 'username'
    | 'displayName'
    | 'avatarURL'
    | 'inOrg'
    | AutocompleteMemberSearchItemKeySpecifier
)[]
export type AutocompleteMemberSearchItemFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    username?: FieldPolicy<any> | FieldReadFunction<any>
    displayName?: FieldPolicy<any> | FieldReadFunction<any>
    avatarURL?: FieldPolicy<any> | FieldReadFunction<any>
    inOrg?: FieldPolicy<any> | FieldReadFunction<any>
}
export type OrgMembersSummaryKeySpecifier = ('id' | 'membersCount' | 'invitesCount' | OrgMembersSummaryKeySpecifier)[]
export type OrgMembersSummaryFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    membersCount?: FieldPolicy<any> | FieldReadFunction<any>
    invitesCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type DefaultSettingsKeySpecifier = (
    | 'id'
    | 'latestSettings'
    | 'settingsURL'
    | 'viewerCanAdminister'
    | 'settingsCascade'
    | 'configurationCascade'
    | DefaultSettingsKeySpecifier
)[]
export type DefaultSettingsFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    latestSettings?: FieldPolicy<any> | FieldReadFunction<any>
    settingsURL?: FieldPolicy<any> | FieldReadFunction<any>
    viewerCanAdminister?: FieldPolicy<any> | FieldReadFunction<any>
    settingsCascade?: FieldPolicy<any> | FieldReadFunction<any>
    configurationCascade?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SiteKeySpecifier = (
    | 'id'
    | 'siteID'
    | 'configuration'
    | 'latestSettings'
    | 'settingsCascade'
    | 'configurationCascade'
    | 'settingsURL'
    | 'canReloadSite'
    | 'viewerCanAdminister'
    | 'accessTokens'
    | 'authProviders'
    | 'externalAccounts'
    | 'buildVersion'
    | 'productVersion'
    | 'updateCheck'
    | 'needsRepositoryConfiguration'
    | 'freeUsersExceeded'
    | 'alerts'
    | 'hasCodeIntelligence'
    | 'disableBuiltInSearches'
    | 'sendsEmailVerificationEmails'
    | 'productSubscription'
    | 'usageStatistics'
    | 'analytics'
    | 'users'
    | 'monitoringStatistics'
    | 'allowSiteSettingsEdits'
    | 'enableLegacyExtensions'
    | SiteKeySpecifier
)[]
export type SiteFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    siteID?: FieldPolicy<any> | FieldReadFunction<any>
    configuration?: FieldPolicy<any> | FieldReadFunction<any>
    latestSettings?: FieldPolicy<any> | FieldReadFunction<any>
    settingsCascade?: FieldPolicy<any> | FieldReadFunction<any>
    configurationCascade?: FieldPolicy<any> | FieldReadFunction<any>
    settingsURL?: FieldPolicy<any> | FieldReadFunction<any>
    canReloadSite?: FieldPolicy<any> | FieldReadFunction<any>
    viewerCanAdminister?: FieldPolicy<any> | FieldReadFunction<any>
    accessTokens?: FieldPolicy<any> | FieldReadFunction<any>
    authProviders?: FieldPolicy<any> | FieldReadFunction<any>
    externalAccounts?: FieldPolicy<any> | FieldReadFunction<any>
    buildVersion?: FieldPolicy<any> | FieldReadFunction<any>
    productVersion?: FieldPolicy<any> | FieldReadFunction<any>
    updateCheck?: FieldPolicy<any> | FieldReadFunction<any>
    needsRepositoryConfiguration?: FieldPolicy<any> | FieldReadFunction<any>
    freeUsersExceeded?: FieldPolicy<any> | FieldReadFunction<any>
    alerts?: FieldPolicy<any> | FieldReadFunction<any>
    hasCodeIntelligence?: FieldPolicy<any> | FieldReadFunction<any>
    disableBuiltInSearches?: FieldPolicy<any> | FieldReadFunction<any>
    sendsEmailVerificationEmails?: FieldPolicy<any> | FieldReadFunction<any>
    productSubscription?: FieldPolicy<any> | FieldReadFunction<any>
    usageStatistics?: FieldPolicy<any> | FieldReadFunction<any>
    analytics?: FieldPolicy<any> | FieldReadFunction<any>
    users?: FieldPolicy<any> | FieldReadFunction<any>
    monitoringStatistics?: FieldPolicy<any> | FieldReadFunction<any>
    allowSiteSettingsEdits?: FieldPolicy<any> | FieldReadFunction<any>
    enableLegacyExtensions?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SiteConfigurationKeySpecifier = (
    | 'id'
    | 'effectiveContents'
    | 'validationMessages'
    | SiteConfigurationKeySpecifier
)[]
export type SiteConfigurationFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    effectiveContents?: FieldPolicy<any> | FieldReadFunction<any>
    validationMessages?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UpdateCheckKeySpecifier = (
    | 'pending'
    | 'checkedAt'
    | 'errorMessage'
    | 'updateVersionAvailable'
    | UpdateCheckKeySpecifier
)[]
export type UpdateCheckFieldPolicy = {
    pending?: FieldPolicy<any> | FieldReadFunction<any>
    checkedAt?: FieldPolicy<any> | FieldReadFunction<any>
    errorMessage?: FieldPolicy<any> | FieldReadFunction<any>
    updateVersionAvailable?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AlertKeySpecifier = ('type' | 'message' | 'isDismissibleWithKey' | AlertKeySpecifier)[]
export type AlertFieldPolicy = {
    type?: FieldPolicy<any> | FieldReadFunction<any>
    message?: FieldPolicy<any> | FieldReadFunction<any>
    isDismissibleWithKey?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SettingsSubjectKeySpecifier = (
    | 'id'
    | 'latestSettings'
    | 'settingsURL'
    | 'viewerCanAdminister'
    | 'settingsCascade'
    | 'configurationCascade'
    | SettingsSubjectKeySpecifier
)[]
export type SettingsSubjectFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    latestSettings?: FieldPolicy<any> | FieldReadFunction<any>
    settingsURL?: FieldPolicy<any> | FieldReadFunction<any>
    viewerCanAdminister?: FieldPolicy<any> | FieldReadFunction<any>
    settingsCascade?: FieldPolicy<any> | FieldReadFunction<any>
    configurationCascade?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SettingsCascadeKeySpecifier = ('subjects' | 'final' | 'merged' | SettingsCascadeKeySpecifier)[]
export type SettingsCascadeFieldPolicy = {
    subjects?: FieldPolicy<any> | FieldReadFunction<any>
    final?: FieldPolicy<any> | FieldReadFunction<any>
    merged?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ConfigurationCascadeKeySpecifier = ('subjects' | 'merged' | ConfigurationCascadeKeySpecifier)[]
export type ConfigurationCascadeFieldPolicy = {
    subjects?: FieldPolicy<any> | FieldReadFunction<any>
    merged?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SettingsKeySpecifier = (
    | 'id'
    | 'subject'
    | 'author'
    | 'createdAt'
    | 'contents'
    | 'configuration'
    | SettingsKeySpecifier
)[]
export type SettingsFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    subject?: FieldPolicy<any> | FieldReadFunction<any>
    author?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    contents?: FieldPolicy<any> | FieldReadFunction<any>
    configuration?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ConfigurationKeySpecifier = ('contents' | 'messages' | ConfigurationKeySpecifier)[]
export type ConfigurationFieldPolicy = {
    contents?: FieldPolicy<any> | FieldReadFunction<any>
    messages?: FieldPolicy<any> | FieldReadFunction<any>
}
export type UserUsageStatisticsKeySpecifier = (
    | 'searchQueries'
    | 'pageViews'
    | 'codeIntelligenceActions'
    | 'findReferencesActions'
    | 'lastActiveTime'
    | 'lastActiveCodeHostIntegrationTime'
    | UserUsageStatisticsKeySpecifier
)[]
export type UserUsageStatisticsFieldPolicy = {
    searchQueries?: FieldPolicy<any> | FieldReadFunction<any>
    pageViews?: FieldPolicy<any> | FieldReadFunction<any>
    codeIntelligenceActions?: FieldPolicy<any> | FieldReadFunction<any>
    findReferencesActions?: FieldPolicy<any> | FieldReadFunction<any>
    lastActiveTime?: FieldPolicy<any> | FieldReadFunction<any>
    lastActiveCodeHostIntegrationTime?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SiteUsageStatisticsKeySpecifier = ('daus' | 'waus' | 'maus' | SiteUsageStatisticsKeySpecifier)[]
export type SiteUsageStatisticsFieldPolicy = {
    daus?: FieldPolicy<any> | FieldReadFunction<any>
    waus?: FieldPolicy<any> | FieldReadFunction<any>
    maus?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AnalyticsStatItemNodeKeySpecifier = (
    | 'date'
    | 'count'
    | 'uniqueUsers'
    | 'registeredUsers'
    | AnalyticsStatItemNodeKeySpecifier
)[]
export type AnalyticsStatItemNodeFieldPolicy = {
    date?: FieldPolicy<any> | FieldReadFunction<any>
    count?: FieldPolicy<any> | FieldReadFunction<any>
    uniqueUsers?: FieldPolicy<any> | FieldReadFunction<any>
    registeredUsers?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AnalyticsStatItemSummaryKeySpecifier = (
    | 'totalCount'
    | 'totalUniqueUsers'
    | 'totalRegisteredUsers'
    | AnalyticsStatItemSummaryKeySpecifier
)[]
export type AnalyticsStatItemSummaryFieldPolicy = {
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    totalUniqueUsers?: FieldPolicy<any> | FieldReadFunction<any>
    totalRegisteredUsers?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AnalyticsStatItemKeySpecifier = ('summary' | 'nodes' | AnalyticsStatItemKeySpecifier)[]
export type AnalyticsStatItemFieldPolicy = {
    summary?: FieldPolicy<any> | FieldReadFunction<any>
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AnalyticsSearchResultKeySpecifier = (
    | 'searches'
    | 'resultClicks'
    | 'fileViews'
    | 'fileOpens'
    | AnalyticsSearchResultKeySpecifier
)[]
export type AnalyticsSearchResultFieldPolicy = {
    searches?: FieldPolicy<any> | FieldReadFunction<any>
    resultClicks?: FieldPolicy<any> | FieldReadFunction<any>
    fileViews?: FieldPolicy<any> | FieldReadFunction<any>
    fileOpens?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AnalyticsNotebooksResultsKeySpecifier = (
    | 'creations'
    | 'views'
    | 'blockRuns'
    | AnalyticsNotebooksResultsKeySpecifier
)[]
export type AnalyticsNotebooksResultsFieldPolicy = {
    creations?: FieldPolicy<any> | FieldReadFunction<any>
    views?: FieldPolicy<any> | FieldReadFunction<any>
    blockRuns?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AnalyticsUsersFrequencyItemKeySpecifier = (
    | 'daysUsed'
    | 'frequency'
    | 'percentage'
    | AnalyticsUsersFrequencyItemKeySpecifier
)[]
export type AnalyticsUsersFrequencyItemFieldPolicy = {
    daysUsed?: FieldPolicy<any> | FieldReadFunction<any>
    frequency?: FieldPolicy<any> | FieldReadFunction<any>
    percentage?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AnalyticsUsersSummaryResultKeySpecifier = (
    | 'avgDAU'
    | 'avgWAU'
    | 'avgMAU'
    | AnalyticsUsersSummaryResultKeySpecifier
)[]
export type AnalyticsUsersSummaryResultFieldPolicy = {
    avgDAU?: FieldPolicy<any> | FieldReadFunction<any>
    avgWAU?: FieldPolicy<any> | FieldReadFunction<any>
    avgMAU?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AnalyticsUsersResultKeySpecifier = (
    | 'activity'
    | 'frequencies'
    | 'summary'
    | AnalyticsUsersResultKeySpecifier
)[]
export type AnalyticsUsersResultFieldPolicy = {
    activity?: FieldPolicy<any> | FieldReadFunction<any>
    frequencies?: FieldPolicy<any> | FieldReadFunction<any>
    summary?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AnalyticsCodeIntelResultKeySpecifier = (
    | 'referenceClicks'
    | 'definitionClicks'
    | 'inAppEvents'
    | 'codeHostEvents'
    | 'searchBasedEvents'
    | 'preciseEvents'
    | 'crossRepoEvents'
    | AnalyticsCodeIntelResultKeySpecifier
)[]
export type AnalyticsCodeIntelResultFieldPolicy = {
    referenceClicks?: FieldPolicy<any> | FieldReadFunction<any>
    definitionClicks?: FieldPolicy<any> | FieldReadFunction<any>
    inAppEvents?: FieldPolicy<any> | FieldReadFunction<any>
    codeHostEvents?: FieldPolicy<any> | FieldReadFunction<any>
    searchBasedEvents?: FieldPolicy<any> | FieldReadFunction<any>
    preciseEvents?: FieldPolicy<any> | FieldReadFunction<any>
    crossRepoEvents?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AnalyticsCodeIntelByLanguageResultKeySpecifier = (
    | 'language'
    | 'precision'
    | 'count'
    | AnalyticsCodeIntelByLanguageResultKeySpecifier
)[]
export type AnalyticsCodeIntelByLanguageResultFieldPolicy = {
    language?: FieldPolicy<any> | FieldReadFunction<any>
    precision?: FieldPolicy<any> | FieldReadFunction<any>
    count?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AnalyticsCodeIntelRepositoryResultKeySpecifier = (
    | 'name'
    | 'language'
    | 'kind'
    | 'precision'
    | 'events'
    | 'hasPrecise'
    | AnalyticsCodeIntelRepositoryResultKeySpecifier
)[]
export type AnalyticsCodeIntelRepositoryResultFieldPolicy = {
    name?: FieldPolicy<any> | FieldReadFunction<any>
    language?: FieldPolicy<any> | FieldReadFunction<any>
    kind?: FieldPolicy<any> | FieldReadFunction<any>
    precision?: FieldPolicy<any> | FieldReadFunction<any>
    events?: FieldPolicy<any> | FieldReadFunction<any>
    hasPrecise?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AnalyticsReposSummartResultKeySpecifier = (
    | 'count'
    | 'preciseCodeIntelCount'
    | AnalyticsReposSummartResultKeySpecifier
)[]
export type AnalyticsReposSummartResultFieldPolicy = {
    count?: FieldPolicy<any> | FieldReadFunction<any>
    preciseCodeIntelCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AnalyticsBatchChangesResultKeySpecifier = (
    | 'changesetsCreated'
    | 'changesetsMerged'
    | AnalyticsBatchChangesResultKeySpecifier
)[]
export type AnalyticsBatchChangesResultFieldPolicy = {
    changesetsCreated?: FieldPolicy<any> | FieldReadFunction<any>
    changesetsMerged?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AnalyticsExtensionsResultKeySpecifier = (
    | 'jetbrains'
    | 'vscode'
    | 'browser'
    | AnalyticsExtensionsResultKeySpecifier
)[]
export type AnalyticsExtensionsResultFieldPolicy = {
    jetbrains?: FieldPolicy<any> | FieldReadFunction<any>
    vscode?: FieldPolicy<any> | FieldReadFunction<any>
    browser?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AnalyticsCodesInsightsResultKeySpecifier = (
    | 'insightHovers'
    | 'insightDataPointClicks'
    | AnalyticsCodesInsightsResultKeySpecifier
)[]
export type AnalyticsCodesInsightsResultFieldPolicy = {
    insightHovers?: FieldPolicy<any> | FieldReadFunction<any>
    insightDataPointClicks?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AnalyticsKeySpecifier = (
    | 'search'
    | 'notebooks'
    | 'users'
    | 'codeIntel'
    | 'codeIntelByLanguage'
    | 'codeIntelTopRepositories'
    | 'repos'
    | 'batchChanges'
    | 'extensions'
    | 'codeInsights'
    | AnalyticsKeySpecifier
)[]
export type AnalyticsFieldPolicy = {
    search?: FieldPolicy<any> | FieldReadFunction<any>
    notebooks?: FieldPolicy<any> | FieldReadFunction<any>
    users?: FieldPolicy<any> | FieldReadFunction<any>
    codeIntel?: FieldPolicy<any> | FieldReadFunction<any>
    codeIntelByLanguage?: FieldPolicy<any> | FieldReadFunction<any>
    codeIntelTopRepositories?: FieldPolicy<any> | FieldReadFunction<any>
    repos?: FieldPolicy<any> | FieldReadFunction<any>
    batchChanges?: FieldPolicy<any> | FieldReadFunction<any>
    extensions?: FieldPolicy<any> | FieldReadFunction<any>
    codeInsights?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SiteUserKeySpecifier = (
    | 'id'
    | 'username'
    | 'email'
    | 'displayName'
    | 'createdAt'
    | 'lastActiveAt'
    | 'deletedAt'
    | 'siteAdmin'
    | 'eventsCount'
    | SiteUserKeySpecifier
)[]
export type SiteUserFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    username?: FieldPolicy<any> | FieldReadFunction<any>
    email?: FieldPolicy<any> | FieldReadFunction<any>
    displayName?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    lastActiveAt?: FieldPolicy<any> | FieldReadFunction<any>
    deletedAt?: FieldPolicy<any> | FieldReadFunction<any>
    siteAdmin?: FieldPolicy<any> | FieldReadFunction<any>
    eventsCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SiteUsersKeySpecifier = ('totalCount' | 'nodes' | SiteUsersKeySpecifier)[]
export type SiteUsersFieldPolicy = {
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SiteUsagePeriodKeySpecifier = (
    | 'startTime'
    | 'userCount'
    | 'registeredUserCount'
    | 'anonymousUserCount'
    | 'integrationUserCount'
    | SiteUsagePeriodKeySpecifier
)[]
export type SiteUsagePeriodFieldPolicy = {
    startTime?: FieldPolicy<any> | FieldReadFunction<any>
    userCount?: FieldPolicy<any> | FieldReadFunction<any>
    registeredUserCount?: FieldPolicy<any> | FieldReadFunction<any>
    anonymousUserCount?: FieldPolicy<any> | FieldReadFunction<any>
    integrationUserCount?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MonitoringStatisticsKeySpecifier = ('alerts' | MonitoringStatisticsKeySpecifier)[]
export type MonitoringStatisticsFieldPolicy = {
    alerts?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MonitoringAlertKeySpecifier = (
    | 'timestamp'
    | 'name'
    | 'serviceName'
    | 'owner'
    | 'average'
    | MonitoringAlertKeySpecifier
)[]
export type MonitoringAlertFieldPolicy = {
    timestamp?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    serviceName?: FieldPolicy<any> | FieldReadFunction<any>
    owner?: FieldPolicy<any> | FieldReadFunction<any>
    average?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SurveyResponseConnectionKeySpecifier = (
    | 'nodes'
    | 'totalCount'
    | 'last30DaysCount'
    | 'averageScore'
    | 'netPromoterScore'
    | SurveyResponseConnectionKeySpecifier
)[]
export type SurveyResponseConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    last30DaysCount?: FieldPolicy<any> | FieldReadFunction<any>
    averageScore?: FieldPolicy<any> | FieldReadFunction<any>
    netPromoterScore?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SurveyResponseKeySpecifier = (
    | 'id'
    | 'user'
    | 'email'
    | 'score'
    | 'reason'
    | 'better'
    | 'otherUseCase'
    | 'createdAt'
    | SurveyResponseKeySpecifier
)[]
export type SurveyResponseFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    user?: FieldPolicy<any> | FieldReadFunction<any>
    email?: FieldPolicy<any> | FieldReadFunction<any>
    score?: FieldPolicy<any> | FieldReadFunction<any>
    reason?: FieldPolicy<any> | FieldReadFunction<any>
    better?: FieldPolicy<any> | FieldReadFunction<any>
    otherUseCase?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ProductSubscriptionStatusKeySpecifier = (
    | 'productNameWithBrand'
    | 'actualUserCount'
    | 'actualUserCountDate'
    | 'maximumAllowedUserCount'
    | 'noLicenseWarningUserCount'
    | 'license'
    | ProductSubscriptionStatusKeySpecifier
)[]
export type ProductSubscriptionStatusFieldPolicy = {
    productNameWithBrand?: FieldPolicy<any> | FieldReadFunction<any>
    actualUserCount?: FieldPolicy<any> | FieldReadFunction<any>
    actualUserCountDate?: FieldPolicy<any> | FieldReadFunction<any>
    maximumAllowedUserCount?: FieldPolicy<any> | FieldReadFunction<any>
    noLicenseWarningUserCount?: FieldPolicy<any> | FieldReadFunction<any>
    license?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ProductLicenseInfoKeySpecifier = (
    | 'productNameWithBrand'
    | 'tags'
    | 'userCount'
    | 'expiresAt'
    | ProductLicenseInfoKeySpecifier
)[]
export type ProductLicenseInfoFieldPolicy = {
    productNameWithBrand?: FieldPolicy<any> | FieldReadFunction<any>
    tags?: FieldPolicy<any> | FieldReadFunction<any>
    userCount?: FieldPolicy<any> | FieldReadFunction<any>
    expiresAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExtensionRegistryKeySpecifier = (
    | 'extension'
    | 'extensions'
    | 'publishers'
    | 'viewerPublishers'
    | 'localExtensionIDPrefix'
    | 'featuredExtensions'
    | ExtensionRegistryKeySpecifier
)[]
export type ExtensionRegistryFieldPolicy = {
    extension?: FieldPolicy<any> | FieldReadFunction<any>
    extensions?: FieldPolicy<any> | FieldReadFunction<any>
    publishers?: FieldPolicy<any> | FieldReadFunction<any>
    viewerPublishers?: FieldPolicy<any> | FieldReadFunction<any>
    localExtensionIDPrefix?: FieldPolicy<any> | FieldReadFunction<any>
    featuredExtensions?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RegistryPublisherConnectionKeySpecifier = (
    | 'nodes'
    | 'totalCount'
    | 'pageInfo'
    | RegistryPublisherConnectionKeySpecifier
)[]
export type RegistryPublisherConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExtensionRegistryMutationKeySpecifier = (
    | 'createExtension'
    | 'updateExtension'
    | 'deleteExtension'
    | 'publishExtension'
    | ExtensionRegistryMutationKeySpecifier
)[]
export type ExtensionRegistryMutationFieldPolicy = {
    createExtension?: FieldPolicy<any> | FieldReadFunction<any>
    updateExtension?: FieldPolicy<any> | FieldReadFunction<any>
    deleteExtension?: FieldPolicy<any> | FieldReadFunction<any>
    publishExtension?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExtensionRegistryCreateExtensionResultKeySpecifier = (
    | 'extension'
    | ExtensionRegistryCreateExtensionResultKeySpecifier
)[]
export type ExtensionRegistryCreateExtensionResultFieldPolicy = {
    extension?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExtensionRegistryUpdateExtensionResultKeySpecifier = (
    | 'extension'
    | ExtensionRegistryUpdateExtensionResultKeySpecifier
)[]
export type ExtensionRegistryUpdateExtensionResultFieldPolicy = {
    extension?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExtensionRegistryPublishExtensionResultKeySpecifier = (
    | 'extension'
    | ExtensionRegistryPublishExtensionResultKeySpecifier
)[]
export type ExtensionRegistryPublishExtensionResultFieldPolicy = {
    extension?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RegistryExtensionKeySpecifier = (
    | 'id'
    | 'uuid'
    | 'publisher'
    | 'extensionID'
    | 'extensionIDWithoutRegistry'
    | 'name'
    | 'manifest'
    | 'createdAt'
    | 'updatedAt'
    | 'publishedAt'
    | 'url'
    | 'remoteURL'
    | 'registryName'
    | 'isLocal'
    | 'isWorkInProgress'
    | 'viewerCanAdminister'
    | RegistryExtensionKeySpecifier
)[]
export type RegistryExtensionFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    uuid?: FieldPolicy<any> | FieldReadFunction<any>
    publisher?: FieldPolicy<any> | FieldReadFunction<any>
    extensionID?: FieldPolicy<any> | FieldReadFunction<any>
    extensionIDWithoutRegistry?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    manifest?: FieldPolicy<any> | FieldReadFunction<any>
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
    publishedAt?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
    remoteURL?: FieldPolicy<any> | FieldReadFunction<any>
    registryName?: FieldPolicy<any> | FieldReadFunction<any>
    isLocal?: FieldPolicy<any> | FieldReadFunction<any>
    isWorkInProgress?: FieldPolicy<any> | FieldReadFunction<any>
    viewerCanAdminister?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExtensionManifestKeySpecifier = (
    | 'raw'
    | 'jsonFields'
    | 'description'
    | 'bundleURL'
    | ExtensionManifestKeySpecifier
)[]
export type ExtensionManifestFieldPolicy = {
    raw?: FieldPolicy<any> | FieldReadFunction<any>
    jsonFields?: FieldPolicy<any> | FieldReadFunction<any>
    description?: FieldPolicy<any> | FieldReadFunction<any>
    bundleURL?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RegistryExtensionConnectionKeySpecifier = (
    | 'nodes'
    | 'totalCount'
    | 'pageInfo'
    | 'url'
    | 'error'
    | RegistryExtensionConnectionKeySpecifier
)[]
export type RegistryExtensionConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
    error?: FieldPolicy<any> | FieldReadFunction<any>
}
export type FeaturedExtensionsConnectionKeySpecifier = ('nodes' | 'error' | FeaturedExtensionsConnectionKeySpecifier)[]
export type FeaturedExtensionsConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    error?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CodeIntelligenceRangeConnectionKeySpecifier = ('nodes' | CodeIntelligenceRangeConnectionKeySpecifier)[]
export type CodeIntelligenceRangeConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CodeIntelligenceRangeKeySpecifier = (
    | 'range'
    | 'definitions'
    | 'references'
    | 'implementations'
    | 'hover'
    | CodeIntelligenceRangeKeySpecifier
)[]
export type CodeIntelligenceRangeFieldPolicy = {
    range?: FieldPolicy<any> | FieldReadFunction<any>
    definitions?: FieldPolicy<any> | FieldReadFunction<any>
    references?: FieldPolicy<any> | FieldReadFunction<any>
    implementations?: FieldPolicy<any> | FieldReadFunction<any>
    hover?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LocationConnectionKeySpecifier = ('nodes' | 'pageInfo' | LocationConnectionKeySpecifier)[]
export type LocationConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type HoverKeySpecifier = ('markdown' | 'range' | HoverKeySpecifier)[]
export type HoverFieldPolicy = {
    markdown?: FieldPolicy<any> | FieldReadFunction<any>
    range?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CloningProgressKeySpecifier = ('message' | CloningProgressKeySpecifier)[]
export type CloningProgressFieldPolicy = {
    message?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExternalServiceSyncErrorKeySpecifier = (
    | 'message'
    | 'externalService'
    | ExternalServiceSyncErrorKeySpecifier
)[]
export type ExternalServiceSyncErrorFieldPolicy = {
    message?: FieldPolicy<any> | FieldReadFunction<any>
    externalService?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SyncErrorKeySpecifier = ('message' | SyncErrorKeySpecifier)[]
export type SyncErrorFieldPolicy = {
    message?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RepositoryStatsKeySpecifier = (
    | 'gitDirBytes'
    | 'indexedLinesCount'
    | 'total'
    | 'cloned'
    | 'cloning'
    | 'notCloned'
    | 'failedFetch'
    | RepositoryStatsKeySpecifier
)[]
export type RepositoryStatsFieldPolicy = {
    gitDirBytes?: FieldPolicy<any> | FieldReadFunction<any>
    indexedLinesCount?: FieldPolicy<any> | FieldReadFunction<any>
    total?: FieldPolicy<any> | FieldReadFunction<any>
    cloned?: FieldPolicy<any> | FieldReadFunction<any>
    cloning?: FieldPolicy<any> | FieldReadFunction<any>
    notCloned?: FieldPolicy<any> | FieldReadFunction<any>
    failedFetch?: FieldPolicy<any> | FieldReadFunction<any>
}
export type EventLogKeySpecifier = (
    | 'name'
    | 'user'
    | 'anonymousUserID'
    | 'url'
    | 'source'
    | 'argument'
    | 'version'
    | 'timestamp'
    | EventLogKeySpecifier
)[]
export type EventLogFieldPolicy = {
    name?: FieldPolicy<any> | FieldReadFunction<any>
    user?: FieldPolicy<any> | FieldReadFunction<any>
    anonymousUserID?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
    source?: FieldPolicy<any> | FieldReadFunction<any>
    argument?: FieldPolicy<any> | FieldReadFunction<any>
    version?: FieldPolicy<any> | FieldReadFunction<any>
    timestamp?: FieldPolicy<any> | FieldReadFunction<any>
}
export type EventLogsConnectionKeySpecifier = ('nodes' | 'totalCount' | 'pageInfo' | EventLogsConnectionKeySpecifier)[]
export type EventLogsConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CodeHostRepositoryConnectionKeySpecifier = (
    | 'codeHostErrors'
    | 'nodes'
    | CodeHostRepositoryConnectionKeySpecifier
)[]
export type CodeHostRepositoryConnectionFieldPolicy = {
    codeHostErrors?: FieldPolicy<any> | FieldReadFunction<any>
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CodeHostRepositoryKeySpecifier = ('name' | 'codeHost' | 'private' | CodeHostRepositoryKeySpecifier)[]
export type CodeHostRepositoryFieldPolicy = {
    name?: FieldPolicy<any> | FieldReadFunction<any>
    codeHost?: FieldPolicy<any> | FieldReadFunction<any>
    private?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ExecutionLogEntryKeySpecifier = (
    | 'key'
    | 'command'
    | 'startTime'
    | 'exitCode'
    | 'out'
    | 'durationMilliseconds'
    | ExecutionLogEntryKeySpecifier
)[]
export type ExecutionLogEntryFieldPolicy = {
    key?: FieldPolicy<any> | FieldReadFunction<any>
    command?: FieldPolicy<any> | FieldReadFunction<any>
    startTime?: FieldPolicy<any> | FieldReadFunction<any>
    exitCode?: FieldPolicy<any> | FieldReadFunction<any>
    out?: FieldPolicy<any> | FieldReadFunction<any>
    durationMilliseconds?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TemporarySettingsKeySpecifier = ('contents' | TemporarySettingsKeySpecifier)[]
export type TemporarySettingsFieldPolicy = {
    contents?: FieldPolicy<any> | FieldReadFunction<any>
}
export type WebhookLogConnectionKeySpecifier = (
    | 'nodes'
    | 'totalCount'
    | 'pageInfo'
    | WebhookLogConnectionKeySpecifier
)[]
export type WebhookLogConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type WebhookLogKeySpecifier = (
    | 'id'
    | 'receivedAt'
    | 'externalService'
    | 'statusCode'
    | 'request'
    | 'response'
    | WebhookLogKeySpecifier
)[]
export type WebhookLogFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    receivedAt?: FieldPolicy<any> | FieldReadFunction<any>
    externalService?: FieldPolicy<any> | FieldReadFunction<any>
    statusCode?: FieldPolicy<any> | FieldReadFunction<any>
    request?: FieldPolicy<any> | FieldReadFunction<any>
    response?: FieldPolicy<any> | FieldReadFunction<any>
}
export type WebhookLogMessageKeySpecifier = ('headers' | 'body' | WebhookLogMessageKeySpecifier)[]
export type WebhookLogMessageFieldPolicy = {
    headers?: FieldPolicy<any> | FieldReadFunction<any>
    body?: FieldPolicy<any> | FieldReadFunction<any>
}
export type WebhookLogRequestKeySpecifier = (
    | 'headers'
    | 'body'
    | 'method'
    | 'url'
    | 'version'
    | WebhookLogRequestKeySpecifier
)[]
export type WebhookLogRequestFieldPolicy = {
    headers?: FieldPolicy<any> | FieldReadFunction<any>
    body?: FieldPolicy<any> | FieldReadFunction<any>
    method?: FieldPolicy<any> | FieldReadFunction<any>
    url?: FieldPolicy<any> | FieldReadFunction<any>
    version?: FieldPolicy<any> | FieldReadFunction<any>
}
export type WebhookLogResponseKeySpecifier = ('headers' | 'body' | WebhookLogResponseKeySpecifier)[]
export type WebhookLogResponseFieldPolicy = {
    headers?: FieldPolicy<any> | FieldReadFunction<any>
    body?: FieldPolicy<any> | FieldReadFunction<any>
}
export type WebhookLogHeaderKeySpecifier = ('name' | 'values' | WebhookLogHeaderKeySpecifier)[]
export type WebhookLogHeaderFieldPolicy = {
    name?: FieldPolicy<any> | FieldReadFunction<any>
    values?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SearchContextKeySpecifier = (
    | 'id'
    | 'name'
    | 'namespace'
    | 'description'
    | 'spec'
    | 'autoDefined'
    | 'query'
    | 'repositories'
    | 'public'
    | 'updatedAt'
    | 'viewerCanManage'
    | SearchContextKeySpecifier
)[]
export type SearchContextFieldPolicy = {
    id?: FieldPolicy<any> | FieldReadFunction<any>
    name?: FieldPolicy<any> | FieldReadFunction<any>
    namespace?: FieldPolicy<any> | FieldReadFunction<any>
    description?: FieldPolicy<any> | FieldReadFunction<any>
    spec?: FieldPolicy<any> | FieldReadFunction<any>
    autoDefined?: FieldPolicy<any> | FieldReadFunction<any>
    query?: FieldPolicy<any> | FieldReadFunction<any>
    repositories?: FieldPolicy<any> | FieldReadFunction<any>
    public?: FieldPolicy<any> | FieldReadFunction<any>
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
    viewerCanManage?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SearchContextRepositoryRevisionsKeySpecifier = (
    | 'repository'
    | 'revisions'
    | SearchContextRepositoryRevisionsKeySpecifier
)[]
export type SearchContextRepositoryRevisionsFieldPolicy = {
    repository?: FieldPolicy<any> | FieldReadFunction<any>
    revisions?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SearchContextConnectionKeySpecifier = (
    | 'nodes'
    | 'totalCount'
    | 'pageInfo'
    | SearchContextConnectionKeySpecifier
)[]
export type SearchContextConnectionFieldPolicy = {
    nodes?: FieldPolicy<any> | FieldReadFunction<any>
    totalCount?: FieldPolicy<any> | FieldReadFunction<any>
    pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TypedTypePolicies = TypePolicies & {
    Mutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | MutationKeySpecifier | (() => undefined | MutationKeySpecifier)
        fields?: MutationFieldPolicy
    }
    Query?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | QueryKeySpecifier | (() => undefined | QueryKeySpecifier)
        fields?: QueryFieldPolicy
    }
    Repository?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | RepositoryKeySpecifier | (() => undefined | RepositoryKeySpecifier)
        fields?: RepositoryFieldPolicy
    }
    User?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | UserKeySpecifier | (() => undefined | UserKeySpecifier)
        fields?: UserFieldPolicy
    }
    UserPermission?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | UserPermissionKeySpecifier | (() => undefined | UserPermissionKeySpecifier)
        fields?: UserPermissionFieldPolicy
    }
    PermissionsInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | PermissionsInfoKeySpecifier | (() => undefined | PermissionsInfoKeySpecifier)
        fields?: PermissionsInfoFieldPolicy
    }
    BitbucketProjectPermissionJobs?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | BitbucketProjectPermissionJobsKeySpecifier
            | (() => undefined | BitbucketProjectPermissionJobsKeySpecifier)
        fields?: BitbucketProjectPermissionJobsFieldPolicy
    }
    BitbucketProjectPermissionJob?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | BitbucketProjectPermissionJobKeySpecifier
            | (() => undefined | BitbucketProjectPermissionJobKeySpecifier)
        fields?: BitbucketProjectPermissionJobFieldPolicy
    }
    ChangesetCounts?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ChangesetCountsKeySpecifier | (() => undefined | ChangesetCountsKeySpecifier)
        fields?: ChangesetCountsFieldPolicy
    }
    ChangesetLabel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ChangesetLabelKeySpecifier | (() => undefined | ChangesetLabelKeySpecifier)
        fields?: ChangesetLabelFieldPolicy
    }
    Changeset?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ChangesetKeySpecifier | (() => undefined | ChangesetKeySpecifier)
        fields?: ChangesetFieldPolicy
    }
    HiddenExternalChangeset?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | HiddenExternalChangesetKeySpecifier
            | (() => undefined | HiddenExternalChangesetKeySpecifier)
        fields?: HiddenExternalChangesetFieldPolicy
    }
    ExternalChangeset?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ExternalChangesetKeySpecifier | (() => undefined | ExternalChangesetKeySpecifier)
        fields?: ExternalChangesetFieldPolicy
    }
    ChangesetsStats?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ChangesetsStatsKeySpecifier | (() => undefined | ChangesetsStatsKeySpecifier)
        fields?: ChangesetsStatsFieldPolicy
    }
    RepoChangesetsStats?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | RepoChangesetsStatsKeySpecifier | (() => undefined | RepoChangesetsStatsKeySpecifier)
        fields?: RepoChangesetsStatsFieldPolicy
    }
    ChangesetConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ChangesetConnectionKeySpecifier | (() => undefined | ChangesetConnectionKeySpecifier)
        fields?: ChangesetConnectionFieldPolicy
    }
    ChangesetEvent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ChangesetEventKeySpecifier | (() => undefined | ChangesetEventKeySpecifier)
        fields?: ChangesetEventFieldPolicy
    }
    ChangesetEventConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ChangesetEventConnectionKeySpecifier
            | (() => undefined | ChangesetEventConnectionKeySpecifier)
        fields?: ChangesetEventConnectionFieldPolicy
    }
    ChangesetSpecDelta?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ChangesetSpecDeltaKeySpecifier | (() => undefined | ChangesetSpecDeltaKeySpecifier)
        fields?: ChangesetSpecDeltaFieldPolicy
    }
    ChangesetSpec?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ChangesetSpecKeySpecifier | (() => undefined | ChangesetSpecKeySpecifier)
        fields?: ChangesetSpecFieldPolicy
    }
    HiddenChangesetSpec?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | HiddenChangesetSpecKeySpecifier | (() => undefined | HiddenChangesetSpecKeySpecifier)
        fields?: HiddenChangesetSpecFieldPolicy
    }
    VisibleChangesetSpec?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | VisibleChangesetSpecKeySpecifier | (() => undefined | VisibleChangesetSpecKeySpecifier)
        fields?: VisibleChangesetSpecFieldPolicy
    }
    ExistingChangesetReference?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ExistingChangesetReferenceKeySpecifier
            | (() => undefined | ExistingChangesetReferenceKeySpecifier)
        fields?: ExistingChangesetReferenceFieldPolicy
    }
    GitBranchChangesetDescription?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | GitBranchChangesetDescriptionKeySpecifier
            | (() => undefined | GitBranchChangesetDescriptionKeySpecifier)
        fields?: GitBranchChangesetDescriptionFieldPolicy
    }
    GitCommitDescription?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | GitCommitDescriptionKeySpecifier | (() => undefined | GitCommitDescriptionKeySpecifier)
        fields?: GitCommitDescriptionFieldPolicy
    }
    ChangesetSpecConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ChangesetSpecConnectionKeySpecifier
            | (() => undefined | ChangesetSpecConnectionKeySpecifier)
        fields?: ChangesetSpecConnectionFieldPolicy
    }
    VisibleApplyPreviewTargetsAttach?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | VisibleApplyPreviewTargetsAttachKeySpecifier
            | (() => undefined | VisibleApplyPreviewTargetsAttachKeySpecifier)
        fields?: VisibleApplyPreviewTargetsAttachFieldPolicy
    }
    VisibleApplyPreviewTargetsUpdate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | VisibleApplyPreviewTargetsUpdateKeySpecifier
            | (() => undefined | VisibleApplyPreviewTargetsUpdateKeySpecifier)
        fields?: VisibleApplyPreviewTargetsUpdateFieldPolicy
    }
    VisibleApplyPreviewTargetsDetach?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | VisibleApplyPreviewTargetsDetachKeySpecifier
            | (() => undefined | VisibleApplyPreviewTargetsDetachKeySpecifier)
        fields?: VisibleApplyPreviewTargetsDetachFieldPolicy
    }
    HiddenApplyPreviewTargetsAttach?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | HiddenApplyPreviewTargetsAttachKeySpecifier
            | (() => undefined | HiddenApplyPreviewTargetsAttachKeySpecifier)
        fields?: HiddenApplyPreviewTargetsAttachFieldPolicy
    }
    HiddenApplyPreviewTargetsUpdate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | HiddenApplyPreviewTargetsUpdateKeySpecifier
            | (() => undefined | HiddenApplyPreviewTargetsUpdateKeySpecifier)
        fields?: HiddenApplyPreviewTargetsUpdateFieldPolicy
    }
    HiddenApplyPreviewTargetsDetach?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | HiddenApplyPreviewTargetsDetachKeySpecifier
            | (() => undefined | HiddenApplyPreviewTargetsDetachKeySpecifier)
        fields?: HiddenApplyPreviewTargetsDetachFieldPolicy
    }
    HiddenChangesetApplyPreview?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | HiddenChangesetApplyPreviewKeySpecifier
            | (() => undefined | HiddenChangesetApplyPreviewKeySpecifier)
        fields?: HiddenChangesetApplyPreviewFieldPolicy
    }
    VisibleChangesetApplyPreview?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | VisibleChangesetApplyPreviewKeySpecifier
            | (() => undefined | VisibleChangesetApplyPreviewKeySpecifier)
        fields?: VisibleChangesetApplyPreviewFieldPolicy
    }
    ChangesetApplyPreviewConnectionStats?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ChangesetApplyPreviewConnectionStatsKeySpecifier
            | (() => undefined | ChangesetApplyPreviewConnectionStatsKeySpecifier)
        fields?: ChangesetApplyPreviewConnectionStatsFieldPolicy
    }
    ChangesetApplyPreviewConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ChangesetApplyPreviewConnectionKeySpecifier
            | (() => undefined | ChangesetApplyPreviewConnectionKeySpecifier)
        fields?: ChangesetApplyPreviewConnectionFieldPolicy
    }
    ForkTarget?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ForkTargetKeySpecifier | (() => undefined | ForkTargetKeySpecifier)
        fields?: ForkTargetFieldPolicy
    }
    ResolvedBatchSpecWorkspace?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ResolvedBatchSpecWorkspaceKeySpecifier
            | (() => undefined | ResolvedBatchSpecWorkspaceKeySpecifier)
        fields?: ResolvedBatchSpecWorkspaceFieldPolicy
    }
    BatchSpecWorkspaceResolution?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | BatchSpecWorkspaceResolutionKeySpecifier
            | (() => undefined | BatchSpecWorkspaceResolutionKeySpecifier)
        fields?: BatchSpecWorkspaceResolutionFieldPolicy
    }
    BatchSpecWorkspacesStats?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | BatchSpecWorkspacesStatsKeySpecifier
            | (() => undefined | BatchSpecWorkspacesStatsKeySpecifier)
        fields?: BatchSpecWorkspacesStatsFieldPolicy
    }
    BatchSpecWorkspaceConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | BatchSpecWorkspaceConnectionKeySpecifier
            | (() => undefined | BatchSpecWorkspaceConnectionKeySpecifier)
        fields?: BatchSpecWorkspaceConnectionFieldPolicy
    }
    BatchSpecWorkspaceStages?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | BatchSpecWorkspaceStagesKeySpecifier
            | (() => undefined | BatchSpecWorkspaceStagesKeySpecifier)
        fields?: BatchSpecWorkspaceStagesFieldPolicy
    }
    BatchSpecWorkspace?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | BatchSpecWorkspaceKeySpecifier | (() => undefined | BatchSpecWorkspaceKeySpecifier)
        fields?: BatchSpecWorkspaceFieldPolicy
    }
    VisibleBatchSpecWorkspace?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | VisibleBatchSpecWorkspaceKeySpecifier
            | (() => undefined | VisibleBatchSpecWorkspaceKeySpecifier)
        fields?: VisibleBatchSpecWorkspaceFieldPolicy
    }
    HiddenBatchSpecWorkspace?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | HiddenBatchSpecWorkspaceKeySpecifier
            | (() => undefined | HiddenBatchSpecWorkspaceKeySpecifier)
        fields?: HiddenBatchSpecWorkspaceFieldPolicy
    }
    BatchSpecWorkspaceStep?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | BatchSpecWorkspaceStepKeySpecifier | (() => undefined | BatchSpecWorkspaceStepKeySpecifier)
        fields?: BatchSpecWorkspaceStepFieldPolicy
    }
    BatchSpecWorkspaceOutputVariable?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | BatchSpecWorkspaceOutputVariableKeySpecifier
            | (() => undefined | BatchSpecWorkspaceOutputVariableKeySpecifier)
        fields?: BatchSpecWorkspaceOutputVariableFieldPolicy
    }
    BatchSpecWorkspaceEnvironmentVariable?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | BatchSpecWorkspaceEnvironmentVariableKeySpecifier
            | (() => undefined | BatchSpecWorkspaceEnvironmentVariableKeySpecifier)
        fields?: BatchSpecWorkspaceEnvironmentVariableFieldPolicy
    }
    BatchChange?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | BatchChangeKeySpecifier | (() => undefined | BatchChangeKeySpecifier)
        fields?: BatchChangeFieldPolicy
    }
    BulkOperationConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | BulkOperationConnectionKeySpecifier
            | (() => undefined | BulkOperationConnectionKeySpecifier)
        fields?: BulkOperationConnectionFieldPolicy
    }
    BulkOperation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | BulkOperationKeySpecifier | (() => undefined | BulkOperationKeySpecifier)
        fields?: BulkOperationFieldPolicy
    }
    ChangesetJobError?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ChangesetJobErrorKeySpecifier | (() => undefined | ChangesetJobErrorKeySpecifier)
        fields?: ChangesetJobErrorFieldPolicy
    }
    BatchSpecConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | BatchSpecConnectionKeySpecifier | (() => undefined | BatchSpecConnectionKeySpecifier)
        fields?: BatchSpecConnectionFieldPolicy
    }
    BatchSpec?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | BatchSpecKeySpecifier | (() => undefined | BatchSpecKeySpecifier)
        fields?: BatchSpecFieldPolicy
    }
    BatchChangeConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | BatchChangeConnectionKeySpecifier | (() => undefined | BatchChangeConnectionKeySpecifier)
        fields?: BatchChangeConnectionFieldPolicy
    }
    Org?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | OrgKeySpecifier | (() => undefined | OrgKeySpecifier)
        fields?: OrgFieldPolicy
    }
    BatchChangesCodeHostConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | BatchChangesCodeHostConnectionKeySpecifier
            | (() => undefined | BatchChangesCodeHostConnectionKeySpecifier)
        fields?: BatchChangesCodeHostConnectionFieldPolicy
    }
    BatchChangesCodeHost?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | BatchChangesCodeHostKeySpecifier | (() => undefined | BatchChangesCodeHostKeySpecifier)
        fields?: BatchChangesCodeHostFieldPolicy
    }
    BatchChangesCredential?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | BatchChangesCredentialKeySpecifier | (() => undefined | BatchChangesCredentialKeySpecifier)
        fields?: BatchChangesCredentialFieldPolicy
    }
    BatchChangeDescription?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | BatchChangeDescriptionKeySpecifier | (() => undefined | BatchChangeDescriptionKeySpecifier)
        fields?: BatchChangeDescriptionFieldPolicy
    }
    MonitorConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | MonitorConnectionKeySpecifier | (() => undefined | MonitorConnectionKeySpecifier)
        fields?: MonitorConnectionFieldPolicy
    }
    Monitor?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | MonitorKeySpecifier | (() => undefined | MonitorKeySpecifier)
        fields?: MonitorFieldPolicy
    }
    MonitorQuery?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | MonitorQueryKeySpecifier | (() => undefined | MonitorQueryKeySpecifier)
        fields?: MonitorQueryFieldPolicy
    }
    MonitorTriggerEventConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | MonitorTriggerEventConnectionKeySpecifier
            | (() => undefined | MonitorTriggerEventConnectionKeySpecifier)
        fields?: MonitorTriggerEventConnectionFieldPolicy
    }
    MonitorTriggerEvent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | MonitorTriggerEventKeySpecifier | (() => undefined | MonitorTriggerEventKeySpecifier)
        fields?: MonitorTriggerEventFieldPolicy
    }
    MonitorActionConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | MonitorActionConnectionKeySpecifier
            | (() => undefined | MonitorActionConnectionKeySpecifier)
        fields?: MonitorActionConnectionFieldPolicy
    }
    MonitorEmail?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | MonitorEmailKeySpecifier | (() => undefined | MonitorEmailKeySpecifier)
        fields?: MonitorEmailFieldPolicy
    }
    MonitorWebhook?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | MonitorWebhookKeySpecifier | (() => undefined | MonitorWebhookKeySpecifier)
        fields?: MonitorWebhookFieldPolicy
    }
    MonitorSlackWebhook?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | MonitorSlackWebhookKeySpecifier | (() => undefined | MonitorSlackWebhookKeySpecifier)
        fields?: MonitorSlackWebhookFieldPolicy
    }
    MonitorActionEmailRecipientsConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | MonitorActionEmailRecipientsConnectionKeySpecifier
            | (() => undefined | MonitorActionEmailRecipientsConnectionKeySpecifier)
        fields?: MonitorActionEmailRecipientsConnectionFieldPolicy
    }
    MonitorActionEventConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | MonitorActionEventConnectionKeySpecifier
            | (() => undefined | MonitorActionEventConnectionKeySpecifier)
        fields?: MonitorActionEventConnectionFieldPolicy
    }
    MonitorActionEvent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | MonitorActionEventKeySpecifier | (() => undefined | MonitorActionEventKeySpecifier)
        fields?: MonitorActionEventFieldPolicy
    }
    RepositoryFilterPreview?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | RepositoryFilterPreviewKeySpecifier
            | (() => undefined | RepositoryFilterPreviewKeySpecifier)
        fields?: RepositoryFilterPreviewFieldPolicy
    }
    CodeIntelligenceConfigurationPolicyConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | CodeIntelligenceConfigurationPolicyConnectionKeySpecifier
            | (() => undefined | CodeIntelligenceConfigurationPolicyConnectionKeySpecifier)
        fields?: CodeIntelligenceConfigurationPolicyConnectionFieldPolicy
    }
    CodeIntelligenceConfigurationPolicy?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | CodeIntelligenceConfigurationPolicyKeySpecifier
            | (() => undefined | CodeIntelligenceConfigurationPolicyKeySpecifier)
        fields?: CodeIntelligenceConfigurationPolicyFieldPolicy
    }
    CodeIntelligenceRetentionPolicyMatch?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | CodeIntelligenceRetentionPolicyMatchKeySpecifier
            | (() => undefined | CodeIntelligenceRetentionPolicyMatchKeySpecifier)
        fields?: CodeIntelligenceRetentionPolicyMatchFieldPolicy
    }
    CodeIntelligenceRetentionPolicyMatchesConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | CodeIntelligenceRetentionPolicyMatchesConnectionKeySpecifier
            | (() => undefined | CodeIntelligenceRetentionPolicyMatchesConnectionKeySpecifier)
        fields?: CodeIntelligenceRetentionPolicyMatchesConnectionFieldPolicy
    }
    TreeEntry?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | TreeEntryKeySpecifier | (() => undefined | TreeEntryKeySpecifier)
        fields?: TreeEntryFieldPolicy
    }
    GitTree?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | GitTreeKeySpecifier | (() => undefined | GitTreeKeySpecifier)
        fields?: GitTreeFieldPolicy
    }
    GitBlob?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | GitBlobKeySpecifier | (() => undefined | GitBlobKeySpecifier)
        fields?: GitBlobFieldPolicy
    }
    SymbolInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SymbolInfoKeySpecifier | (() => undefined | SymbolInfoKeySpecifier)
        fields?: SymbolInfoFieldPolicy
    }
    SymbolLocation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SymbolLocationKeySpecifier | (() => undefined | SymbolLocationKeySpecifier)
        fields?: SymbolLocationFieldPolicy
    }
    LineRange?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | LineRangeKeySpecifier | (() => undefined | LineRangeKeySpecifier)
        fields?: LineRangeFieldPolicy
    }
    CodeIntelRepositorySummary?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | CodeIntelRepositorySummaryKeySpecifier
            | (() => undefined | CodeIntelRepositorySummaryKeySpecifier)
        fields?: CodeIntelRepositorySummaryFieldPolicy
    }
    LSIFUploadsWithRepositoryNamespace?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | LSIFUploadsWithRepositoryNamespaceKeySpecifier
            | (() => undefined | LSIFUploadsWithRepositoryNamespaceKeySpecifier)
        fields?: LSIFUploadsWithRepositoryNamespaceFieldPolicy
    }
    LSIFIndexesWithRepositoryNamespace?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | LSIFIndexesWithRepositoryNamespaceKeySpecifier
            | (() => undefined | LSIFIndexesWithRepositoryNamespaceKeySpecifier)
        fields?: LSIFIndexesWithRepositoryNamespaceFieldPolicy
    }
    GitObjectFilterPreview?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | GitObjectFilterPreviewKeySpecifier | (() => undefined | GitObjectFilterPreviewKeySpecifier)
        fields?: GitObjectFilterPreviewFieldPolicy
    }
    TreeEntryLSIFData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | TreeEntryLSIFDataKeySpecifier | (() => undefined | TreeEntryLSIFDataKeySpecifier)
        fields?: TreeEntryLSIFDataFieldPolicy
    }
    GitTreeLSIFData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | GitTreeLSIFDataKeySpecifier | (() => undefined | GitTreeLSIFDataKeySpecifier)
        fields?: GitTreeLSIFDataFieldPolicy
    }
    GitBlobLSIFData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | GitBlobLSIFDataKeySpecifier | (() => undefined | GitBlobLSIFDataKeySpecifier)
        fields?: GitBlobLSIFDataFieldPolicy
    }
    LSIFUpload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | LSIFUploadKeySpecifier | (() => undefined | LSIFUploadKeySpecifier)
        fields?: LSIFUploadFieldPolicy
    }
    LSIFUploadConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | LSIFUploadConnectionKeySpecifier | (() => undefined | LSIFUploadConnectionKeySpecifier)
        fields?: LSIFUploadConnectionFieldPolicy
    }
    LSIFUploadDocumentPathsConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | LSIFUploadDocumentPathsConnectionKeySpecifier
            | (() => undefined | LSIFUploadDocumentPathsConnectionKeySpecifier)
        fields?: LSIFUploadDocumentPathsConnectionFieldPolicy
    }
    LSIFUploadAuditLog?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | LSIFUploadAuditLogKeySpecifier | (() => undefined | LSIFUploadAuditLogKeySpecifier)
        fields?: LSIFUploadAuditLogFieldPolicy
    }
    AuditLogColumnChange?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | AuditLogColumnChangeKeySpecifier | (() => undefined | AuditLogColumnChangeKeySpecifier)
        fields?: AuditLogColumnChangeFieldPolicy
    }
    LSIFIndex?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | LSIFIndexKeySpecifier | (() => undefined | LSIFIndexKeySpecifier)
        fields?: LSIFIndexFieldPolicy
    }
    IndexSteps?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | IndexStepsKeySpecifier | (() => undefined | IndexStepsKeySpecifier)
        fields?: IndexStepsFieldPolicy
    }
    PreIndexStep?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | PreIndexStepKeySpecifier | (() => undefined | PreIndexStepKeySpecifier)
        fields?: PreIndexStepFieldPolicy
    }
    IndexStep?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | IndexStepKeySpecifier | (() => undefined | IndexStepKeySpecifier)
        fields?: IndexStepFieldPolicy
    }
    LSIFIndexConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | LSIFIndexConnectionKeySpecifier | (() => undefined | LSIFIndexConnectionKeySpecifier)
        fields?: LSIFIndexConnectionFieldPolicy
    }
    IndexConfiguration?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | IndexConfigurationKeySpecifier | (() => undefined | IndexConfigurationKeySpecifier)
        fields?: IndexConfigurationFieldPolicy
    }
    GitTreeCodeIntelInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | GitTreeCodeIntelInfoKeySpecifier | (() => undefined | GitTreeCodeIntelInfoKeySpecifier)
        fields?: GitTreeCodeIntelInfoFieldPolicy
    }
    GitTreeSearchBasedCoverage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | GitTreeSearchBasedCoverageKeySpecifier
            | (() => undefined | GitTreeSearchBasedCoverageKeySpecifier)
        fields?: GitTreeSearchBasedCoverageFieldPolicy
    }
    GitTreePreciseCoverage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | GitTreePreciseCoverageKeySpecifier | (() => undefined | GitTreePreciseCoverageKeySpecifier)
        fields?: GitTreePreciseCoverageFieldPolicy
    }
    CodeIntelSupport?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | CodeIntelSupportKeySpecifier | (() => undefined | CodeIntelSupportKeySpecifier)
        fields?: CodeIntelSupportFieldPolicy
    }
    PreciseCodeIntelSupport?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | PreciseCodeIntelSupportKeySpecifier
            | (() => undefined | PreciseCodeIntelSupportKeySpecifier)
        fields?: PreciseCodeIntelSupportFieldPolicy
    }
    CodeIntelIndexer?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | CodeIntelIndexerKeySpecifier | (() => undefined | CodeIntelIndexerKeySpecifier)
        fields?: CodeIntelIndexerFieldPolicy
    }
    SearchBasedCodeIntelSupport?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | SearchBasedCodeIntelSupportKeySpecifier
            | (() => undefined | SearchBasedCodeIntelSupportKeySpecifier)
        fields?: SearchBasedCodeIntelSupportFieldPolicy
    }
    ComputeMatchContext?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ComputeMatchContextKeySpecifier | (() => undefined | ComputeMatchContextKeySpecifier)
        fields?: ComputeMatchContextFieldPolicy
    }
    ComputeMatch?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ComputeMatchKeySpecifier | (() => undefined | ComputeMatchKeySpecifier)
        fields?: ComputeMatchFieldPolicy
    }
    ComputeEnvironmentEntry?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ComputeEnvironmentEntryKeySpecifier
            | (() => undefined | ComputeEnvironmentEntryKeySpecifier)
        fields?: ComputeEnvironmentEntryFieldPolicy
    }
    ComputeText?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ComputeTextKeySpecifier | (() => undefined | ComputeTextKeySpecifier)
        fields?: ComputeTextFieldPolicy
    }
    DotcomMutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | DotcomMutationKeySpecifier | (() => undefined | DotcomMutationKeySpecifier)
        fields?: DotcomMutationFieldPolicy
    }
    DotcomQuery?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | DotcomQueryKeySpecifier | (() => undefined | DotcomQueryKeySpecifier)
        fields?: DotcomQueryFieldPolicy
    }
    ProductSubscriptionConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ProductSubscriptionConnectionKeySpecifier
            | (() => undefined | ProductSubscriptionConnectionKeySpecifier)
        fields?: ProductSubscriptionConnectionFieldPolicy
    }
    ProductSubscriptionPreviewInvoice?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ProductSubscriptionPreviewInvoiceKeySpecifier
            | (() => undefined | ProductSubscriptionPreviewInvoiceKeySpecifier)
        fields?: ProductSubscriptionPreviewInvoiceFieldPolicy
    }
    ProductLicense?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ProductLicenseKeySpecifier | (() => undefined | ProductLicenseKeySpecifier)
        fields?: ProductLicenseFieldPolicy
    }
    ProductLicenseConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ProductLicenseConnectionKeySpecifier
            | (() => undefined | ProductLicenseConnectionKeySpecifier)
        fields?: ProductLicenseConnectionFieldPolicy
    }
    ProductPlan?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ProductPlanKeySpecifier | (() => undefined | ProductPlanKeySpecifier)
        fields?: ProductPlanFieldPolicy
    }
    PlanTier?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | PlanTierKeySpecifier | (() => undefined | PlanTierKeySpecifier)
        fields?: PlanTierFieldPolicy
    }
    ProductSubscriptionInvoiceItem?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ProductSubscriptionInvoiceItemKeySpecifier
            | (() => undefined | ProductSubscriptionInvoiceItemKeySpecifier)
        fields?: ProductSubscriptionInvoiceItemFieldPolicy
    }
    CreatePaidProductSubscriptionResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | CreatePaidProductSubscriptionResultKeySpecifier
            | (() => undefined | CreatePaidProductSubscriptionResultKeySpecifier)
        fields?: CreatePaidProductSubscriptionResultFieldPolicy
    }
    UpdatePaidProductSubscriptionResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | UpdatePaidProductSubscriptionResultKeySpecifier
            | (() => undefined | UpdatePaidProductSubscriptionResultKeySpecifier)
        fields?: UpdatePaidProductSubscriptionResultFieldPolicy
    }
    ProductSubscriptionEvent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ProductSubscriptionEventKeySpecifier
            | (() => undefined | ProductSubscriptionEventKeySpecifier)
        fields?: ProductSubscriptionEventFieldPolicy
    }
    ProductSubscription?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ProductSubscriptionKeySpecifier | (() => undefined | ProductSubscriptionKeySpecifier)
        fields?: ProductSubscriptionFieldPolicy
    }
    InsightConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | InsightConnectionKeySpecifier | (() => undefined | InsightConnectionKeySpecifier)
        fields?: InsightConnectionFieldPolicy
    }
    Insight?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | InsightKeySpecifier | (() => undefined | InsightKeySpecifier)
        fields?: InsightFieldPolicy
    }
    InsightsSeries?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | InsightsSeriesKeySpecifier | (() => undefined | InsightsSeriesKeySpecifier)
        fields?: InsightsSeriesFieldPolicy
    }
    InsightDataPoint?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | InsightDataPointKeySpecifier | (() => undefined | InsightDataPointKeySpecifier)
        fields?: InsightDataPointFieldPolicy
    }
    InsightDirtyQueryMetadata?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | InsightDirtyQueryMetadataKeySpecifier
            | (() => undefined | InsightDirtyQueryMetadataKeySpecifier)
        fields?: InsightDirtyQueryMetadataFieldPolicy
    }
    InsightSeriesStatus?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | InsightSeriesStatusKeySpecifier | (() => undefined | InsightSeriesStatusKeySpecifier)
        fields?: InsightSeriesStatusFieldPolicy
    }
    InsightsDashboardConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | InsightsDashboardConnectionKeySpecifier
            | (() => undefined | InsightsDashboardConnectionKeySpecifier)
        fields?: InsightsDashboardConnectionFieldPolicy
    }
    InsightsDashboard?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | InsightsDashboardKeySpecifier | (() => undefined | InsightsDashboardKeySpecifier)
        fields?: InsightsDashboardFieldPolicy
    }
    InsightsPermissionGrants?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | InsightsPermissionGrantsKeySpecifier
            | (() => undefined | InsightsPermissionGrantsKeySpecifier)
        fields?: InsightsPermissionGrantsFieldPolicy
    }
    InsightViewConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | InsightViewConnectionKeySpecifier | (() => undefined | InsightViewConnectionKeySpecifier)
        fields?: InsightViewConnectionFieldPolicy
    }
    InsightsDashboardPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | InsightsDashboardPayloadKeySpecifier
            | (() => undefined | InsightsDashboardPayloadKeySpecifier)
        fields?: InsightsDashboardPayloadFieldPolicy
    }
    InsightSeriesMetadata?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | InsightSeriesMetadataKeySpecifier | (() => undefined | InsightSeriesMetadataKeySpecifier)
        fields?: InsightSeriesMetadataFieldPolicy
    }
    InsightSeriesMetadataPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | InsightSeriesMetadataPayloadKeySpecifier
            | (() => undefined | InsightSeriesMetadataPayloadKeySpecifier)
        fields?: InsightSeriesMetadataPayloadFieldPolicy
    }
    InsightSeriesQueryStatus?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | InsightSeriesQueryStatusKeySpecifier
            | (() => undefined | InsightSeriesQueryStatusKeySpecifier)
        fields?: InsightSeriesQueryStatusFieldPolicy
    }
    SeriesDisplayOptions?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SeriesDisplayOptionsKeySpecifier | (() => undefined | SeriesDisplayOptionsKeySpecifier)
        fields?: SeriesDisplayOptionsFieldPolicy
    }
    SeriesSortOptions?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SeriesSortOptionsKeySpecifier | (() => undefined | SeriesSortOptionsKeySpecifier)
        fields?: SeriesSortOptionsFieldPolicy
    }
    InsightViewPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | InsightViewPayloadKeySpecifier | (() => undefined | InsightViewPayloadKeySpecifier)
        fields?: InsightViewPayloadFieldPolicy
    }
    InsightView?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | InsightViewKeySpecifier | (() => undefined | InsightViewKeySpecifier)
        fields?: InsightViewFieldPolicy
    }
    InsightRepositoryScope?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | InsightRepositoryScopeKeySpecifier | (() => undefined | InsightRepositoryScopeKeySpecifier)
        fields?: InsightRepositoryScopeFieldPolicy
    }
    InsightIntervalTimeScope?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | InsightIntervalTimeScopeKeySpecifier
            | (() => undefined | InsightIntervalTimeScopeKeySpecifier)
        fields?: InsightIntervalTimeScopeFieldPolicy
    }
    SearchInsightDataSeriesDefinition?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | SearchInsightDataSeriesDefinitionKeySpecifier
            | (() => undefined | SearchInsightDataSeriesDefinitionKeySpecifier)
        fields?: SearchInsightDataSeriesDefinitionFieldPolicy
    }
    LineChartInsightViewPresentation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | LineChartInsightViewPresentationKeySpecifier
            | (() => undefined | LineChartInsightViewPresentationKeySpecifier)
        fields?: LineChartInsightViewPresentationFieldPolicy
    }
    LineChartDataSeriesPresentation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | LineChartDataSeriesPresentationKeySpecifier
            | (() => undefined | LineChartDataSeriesPresentationKeySpecifier)
        fields?: LineChartDataSeriesPresentationFieldPolicy
    }
    PieChartInsightViewPresentation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | PieChartInsightViewPresentationKeySpecifier
            | (() => undefined | PieChartInsightViewPresentationKeySpecifier)
        fields?: PieChartInsightViewPresentationFieldPolicy
    }
    InsightViewFilters?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | InsightViewFiltersKeySpecifier | (() => undefined | InsightViewFiltersKeySpecifier)
        fields?: InsightViewFiltersFieldPolicy
    }
    SearchInsightLivePreviewSeries?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | SearchInsightLivePreviewSeriesKeySpecifier
            | (() => undefined | SearchInsightLivePreviewSeriesKeySpecifier)
        fields?: SearchInsightLivePreviewSeriesFieldPolicy
    }
    SearchQueryAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SearchQueryAggregateKeySpecifier | (() => undefined | SearchQueryAggregateKeySpecifier)
        fields?: SearchQueryAggregateFieldPolicy
    }
    AggregationModeAvailability?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | AggregationModeAvailabilityKeySpecifier
            | (() => undefined | AggregationModeAvailabilityKeySpecifier)
        fields?: AggregationModeAvailabilityFieldPolicy
    }
    ExhaustiveSearchAggregationResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ExhaustiveSearchAggregationResultKeySpecifier
            | (() => undefined | ExhaustiveSearchAggregationResultKeySpecifier)
        fields?: ExhaustiveSearchAggregationResultFieldPolicy
    }
    NonExhaustiveSearchAggregationResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | NonExhaustiveSearchAggregationResultKeySpecifier
            | (() => undefined | NonExhaustiveSearchAggregationResultKeySpecifier)
        fields?: NonExhaustiveSearchAggregationResultFieldPolicy
    }
    AggregationGroup?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | AggregationGroupKeySpecifier | (() => undefined | AggregationGroupKeySpecifier)
        fields?: AggregationGroupFieldPolicy
    }
    SearchAggregationNotAvailable?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | SearchAggregationNotAvailableKeySpecifier
            | (() => undefined | SearchAggregationNotAvailableKeySpecifier)
        fields?: SearchAggregationNotAvailableFieldPolicy
    }
    NotebookConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | NotebookConnectionKeySpecifier | (() => undefined | NotebookConnectionKeySpecifier)
        fields?: NotebookConnectionFieldPolicy
    }
    MarkdownBlock?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | MarkdownBlockKeySpecifier | (() => undefined | MarkdownBlockKeySpecifier)
        fields?: MarkdownBlockFieldPolicy
    }
    QueryBlock?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | QueryBlockKeySpecifier | (() => undefined | QueryBlockKeySpecifier)
        fields?: QueryBlockFieldPolicy
    }
    FileBlockLineRange?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | FileBlockLineRangeKeySpecifier | (() => undefined | FileBlockLineRangeKeySpecifier)
        fields?: FileBlockLineRangeFieldPolicy
    }
    FileBlockInput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | FileBlockInputKeySpecifier | (() => undefined | FileBlockInputKeySpecifier)
        fields?: FileBlockInputFieldPolicy
    }
    FileBlock?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | FileBlockKeySpecifier | (() => undefined | FileBlockKeySpecifier)
        fields?: FileBlockFieldPolicy
    }
    SymbolBlockInput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SymbolBlockInputKeySpecifier | (() => undefined | SymbolBlockInputKeySpecifier)
        fields?: SymbolBlockInputFieldPolicy
    }
    SymbolBlock?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SymbolBlockKeySpecifier | (() => undefined | SymbolBlockKeySpecifier)
        fields?: SymbolBlockFieldPolicy
    }
    ComputeBlock?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ComputeBlockKeySpecifier | (() => undefined | ComputeBlockKeySpecifier)
        fields?: ComputeBlockFieldPolicy
    }
    Notebook?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | NotebookKeySpecifier | (() => undefined | NotebookKeySpecifier)
        fields?: NotebookFieldPolicy
    }
    NotebookStarConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | NotebookStarConnectionKeySpecifier | (() => undefined | NotebookStarConnectionKeySpecifier)
        fields?: NotebookStarConnectionFieldPolicy
    }
    NotebookStar?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | NotebookStarKeySpecifier | (() => undefined | NotebookStarKeySpecifier)
        fields?: NotebookStarFieldPolicy
    }
    Error?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ErrorKeySpecifier | (() => undefined | ErrorKeySpecifier)
        fields?: ErrorFieldPolicy
    }
    ErrorExtensions?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ErrorExtensionsKeySpecifier | (() => undefined | ErrorExtensionsKeySpecifier)
        fields?: ErrorExtensionsFieldPolicy
    }
    EmptyResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | EmptyResponseKeySpecifier | (() => undefined | EmptyResponseKeySpecifier)
        fields?: EmptyResponseFieldPolicy
    }
    Node?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | NodeKeySpecifier | (() => undefined | NodeKeySpecifier)
        fields?: NodeFieldPolicy
    }
    SettingsMutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SettingsMutationKeySpecifier | (() => undefined | SettingsMutationKeySpecifier)
        fields?: SettingsMutationFieldPolicy
    }
    UpdateSettingsPayload?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | UpdateSettingsPayloadKeySpecifier | (() => undefined | UpdateSettingsPayloadKeySpecifier)
        fields?: UpdateSettingsPayloadFieldPolicy
    }
    CreateAccessTokenResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | CreateAccessTokenResultKeySpecifier
            | (() => undefined | CreateAccessTokenResultKeySpecifier)
        fields?: CreateAccessTokenResultFieldPolicy
    }
    CheckMirrorRepositoryConnectionResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | CheckMirrorRepositoryConnectionResultKeySpecifier
            | (() => undefined | CheckMirrorRepositoryConnectionResultKeySpecifier)
        fields?: CheckMirrorRepositoryConnectionResultFieldPolicy
    }
    CreateUserResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | CreateUserResultKeySpecifier | (() => undefined | CreateUserResultKeySpecifier)
        fields?: CreateUserResultFieldPolicy
    }
    RandomizeUserPasswordResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | RandomizeUserPasswordResultKeySpecifier
            | (() => undefined | RandomizeUserPasswordResultKeySpecifier)
        fields?: RandomizeUserPasswordResultFieldPolicy
    }
    ExecutorConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ExecutorConnectionKeySpecifier | (() => undefined | ExecutorConnectionKeySpecifier)
        fields?: ExecutorConnectionFieldPolicy
    }
    Executor?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ExecutorKeySpecifier | (() => undefined | ExecutorKeySpecifier)
        fields?: ExecutorFieldPolicy
    }
    FeatureFlagBoolean?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | FeatureFlagBooleanKeySpecifier | (() => undefined | FeatureFlagBooleanKeySpecifier)
        fields?: FeatureFlagBooleanFieldPolicy
    }
    FeatureFlagRollout?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | FeatureFlagRolloutKeySpecifier | (() => undefined | FeatureFlagRolloutKeySpecifier)
        fields?: FeatureFlagRolloutFieldPolicy
    }
    FeatureFlagOverride?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | FeatureFlagOverrideKeySpecifier | (() => undefined | FeatureFlagOverrideKeySpecifier)
        fields?: FeatureFlagOverrideFieldPolicy
    }
    EvaluatedFeatureFlag?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | EvaluatedFeatureFlagKeySpecifier | (() => undefined | EvaluatedFeatureFlagKeySpecifier)
        fields?: EvaluatedFeatureFlagFieldPolicy
    }
    OutOfBandMigration?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | OutOfBandMigrationKeySpecifier | (() => undefined | OutOfBandMigrationKeySpecifier)
        fields?: OutOfBandMigrationFieldPolicy
    }
    OutOfBandMigrationError?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | OutOfBandMigrationErrorKeySpecifier
            | (() => undefined | OutOfBandMigrationErrorKeySpecifier)
        fields?: OutOfBandMigrationErrorFieldPolicy
    }
    ClientConfigurationDetails?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ClientConfigurationDetailsKeySpecifier
            | (() => undefined | ClientConfigurationDetailsKeySpecifier)
        fields?: ClientConfigurationDetailsFieldPolicy
    }
    ParentSourcegraphDetails?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ParentSourcegraphDetailsKeySpecifier
            | (() => undefined | ParentSourcegraphDetailsKeySpecifier)
        fields?: ParentSourcegraphDetailsFieldPolicy
    }
    Search?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SearchKeySpecifier | (() => undefined | SearchKeySpecifier)
        fields?: SearchFieldPolicy
    }
    Markdown?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | MarkdownKeySpecifier | (() => undefined | MarkdownKeySpecifier)
        fields?: MarkdownFieldPolicy
    }
    GenericSearchResultInterface?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | GenericSearchResultInterfaceKeySpecifier
            | (() => undefined | GenericSearchResultInterfaceKeySpecifier)
        fields?: GenericSearchResultInterfaceFieldPolicy
    }
    SearchResultMatch?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SearchResultMatchKeySpecifier | (() => undefined | SearchResultMatchKeySpecifier)
        fields?: SearchResultMatchFieldPolicy
    }
    SearchResults?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SearchResultsKeySpecifier | (() => undefined | SearchResultsKeySpecifier)
        fields?: SearchResultsFieldPolicy
    }
    SearchResultsStats?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SearchResultsStatsKeySpecifier | (() => undefined | SearchResultsStatsKeySpecifier)
        fields?: SearchResultsStatsFieldPolicy
    }
    SearchFilter?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SearchFilterKeySpecifier | (() => undefined | SearchFilterKeySpecifier)
        fields?: SearchFilterFieldPolicy
    }
    Language?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | LanguageKeySpecifier | (() => undefined | LanguageKeySpecifier)
        fields?: LanguageFieldPolicy
    }
    SearchAlert?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SearchAlertKeySpecifier | (() => undefined | SearchAlertKeySpecifier)
        fields?: SearchAlertFieldPolicy
    }
    SavedSearch?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SavedSearchKeySpecifier | (() => undefined | SavedSearchKeySpecifier)
        fields?: SavedSearchFieldPolicy
    }
    SearchQueryDescription?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SearchQueryDescriptionKeySpecifier | (() => undefined | SearchQueryDescriptionKeySpecifier)
        fields?: SearchQueryDescriptionFieldPolicy
    }
    SearchQueryAnnotation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SearchQueryAnnotationKeySpecifier | (() => undefined | SearchQueryAnnotationKeySpecifier)
        fields?: SearchQueryAnnotationFieldPolicy
    }
    Diff?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | DiffKeySpecifier | (() => undefined | DiffKeySpecifier)
        fields?: DiffFieldPolicy
    }
    CommitSearchResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | CommitSearchResultKeySpecifier | (() => undefined | CommitSearchResultKeySpecifier)
        fields?: CommitSearchResultFieldPolicy
    }
    HighlightedString?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | HighlightedStringKeySpecifier | (() => undefined | HighlightedStringKeySpecifier)
        fields?: HighlightedStringFieldPolicy
    }
    Highlight?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | HighlightKeySpecifier | (() => undefined | HighlightKeySpecifier)
        fields?: HighlightFieldPolicy
    }
    ExternalServiceConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ExternalServiceConnectionKeySpecifier
            | (() => undefined | ExternalServiceConnectionKeySpecifier)
        fields?: ExternalServiceConnectionFieldPolicy
    }
    ExternalService?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ExternalServiceKeySpecifier | (() => undefined | ExternalServiceKeySpecifier)
        fields?: ExternalServiceFieldPolicy
    }
    ExternalServiceSyncJobConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ExternalServiceSyncJobConnectionKeySpecifier
            | (() => undefined | ExternalServiceSyncJobConnectionKeySpecifier)
        fields?: ExternalServiceSyncJobConnectionFieldPolicy
    }
    ExternalServiceSyncJob?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ExternalServiceSyncJobKeySpecifier | (() => undefined | ExternalServiceSyncJobKeySpecifier)
        fields?: ExternalServiceSyncJobFieldPolicy
    }
    RepositoryConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | RepositoryConnectionKeySpecifier | (() => undefined | RepositoryConnectionKeySpecifier)
        fields?: RepositoryConnectionFieldPolicy
    }
    KeyValuePair?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | KeyValuePairKeySpecifier | (() => undefined | KeyValuePairKeySpecifier)
        fields?: KeyValuePairFieldPolicy
    }
    CodeIntelligenceCommitGraph?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | CodeIntelligenceCommitGraphKeySpecifier
            | (() => undefined | CodeIntelligenceCommitGraphKeySpecifier)
        fields?: CodeIntelligenceCommitGraphFieldPolicy
    }
    Redirect?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | RedirectKeySpecifier | (() => undefined | RedirectKeySpecifier)
        fields?: RedirectFieldPolicy
    }
    ExternalLink?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ExternalLinkKeySpecifier | (() => undefined | ExternalLinkKeySpecifier)
        fields?: ExternalLinkFieldPolicy
    }
    MirrorRepositoryInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | MirrorRepositoryInfoKeySpecifier | (() => undefined | MirrorRepositoryInfoKeySpecifier)
        fields?: MirrorRepositoryInfoFieldPolicy
    }
    UpdateSchedule?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | UpdateScheduleKeySpecifier | (() => undefined | UpdateScheduleKeySpecifier)
        fields?: UpdateScheduleFieldPolicy
    }
    UpdateQueue?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | UpdateQueueKeySpecifier | (() => undefined | UpdateQueueKeySpecifier)
        fields?: UpdateQueueFieldPolicy
    }
    ExternalRepository?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ExternalRepositoryKeySpecifier | (() => undefined | ExternalRepositoryKeySpecifier)
        fields?: ExternalRepositoryFieldPolicy
    }
    RepositoryTextSearchIndex?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | RepositoryTextSearchIndexKeySpecifier
            | (() => undefined | RepositoryTextSearchIndexKeySpecifier)
        fields?: RepositoryTextSearchIndexFieldPolicy
    }
    RepositoryTextSearchIndexStatus?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | RepositoryTextSearchIndexStatusKeySpecifier
            | (() => undefined | RepositoryTextSearchIndexStatusKeySpecifier)
        fields?: RepositoryTextSearchIndexStatusFieldPolicy
    }
    RepositoryTextSearchIndexedRef?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | RepositoryTextSearchIndexedRefKeySpecifier
            | (() => undefined | RepositoryTextSearchIndexedRefKeySpecifier)
        fields?: RepositoryTextSearchIndexedRefFieldPolicy
    }
    SkippedIndexed?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SkippedIndexedKeySpecifier | (() => undefined | SkippedIndexedKeySpecifier)
        fields?: SkippedIndexedFieldPolicy
    }
    GitRefConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | GitRefConnectionKeySpecifier | (() => undefined | GitRefConnectionKeySpecifier)
        fields?: GitRefConnectionFieldPolicy
    }
    PreviewRepositoryComparison?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | PreviewRepositoryComparisonKeySpecifier
            | (() => undefined | PreviewRepositoryComparisonKeySpecifier)
        fields?: PreviewRepositoryComparisonFieldPolicy
    }
    RepositoryComparison?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | RepositoryComparisonKeySpecifier | (() => undefined | RepositoryComparisonKeySpecifier)
        fields?: RepositoryComparisonFieldPolicy
    }
    FileDiffConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | FileDiffConnectionKeySpecifier | (() => undefined | FileDiffConnectionKeySpecifier)
        fields?: FileDiffConnectionFieldPolicy
    }
    FileDiff?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | FileDiffKeySpecifier | (() => undefined | FileDiffKeySpecifier)
        fields?: FileDiffFieldPolicy
    }
    HighlightedDiffHunkLine?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | HighlightedDiffHunkLineKeySpecifier
            | (() => undefined | HighlightedDiffHunkLineKeySpecifier)
        fields?: HighlightedDiffHunkLineFieldPolicy
    }
    HighlightedDiffHunkBody?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | HighlightedDiffHunkBodyKeySpecifier
            | (() => undefined | HighlightedDiffHunkBodyKeySpecifier)
        fields?: HighlightedDiffHunkBodyFieldPolicy
    }
    FileDiffHunk?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | FileDiffHunkKeySpecifier | (() => undefined | FileDiffHunkKeySpecifier)
        fields?: FileDiffHunkFieldPolicy
    }
    FileDiffHunkRange?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | FileDiffHunkRangeKeySpecifier | (() => undefined | FileDiffHunkRangeKeySpecifier)
        fields?: FileDiffHunkRangeFieldPolicy
    }
    DiffStat?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | DiffStatKeySpecifier | (() => undefined | DiffStatKeySpecifier)
        fields?: DiffStatFieldPolicy
    }
    RepositoryContributorConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | RepositoryContributorConnectionKeySpecifier
            | (() => undefined | RepositoryContributorConnectionKeySpecifier)
        fields?: RepositoryContributorConnectionFieldPolicy
    }
    RepositoryContributor?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | RepositoryContributorKeySpecifier | (() => undefined | RepositoryContributorKeySpecifier)
        fields?: RepositoryContributorFieldPolicy
    }
    Symbol?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SymbolKeySpecifier | (() => undefined | SymbolKeySpecifier)
        fields?: SymbolFieldPolicy
    }
    Location?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | LocationKeySpecifier | (() => undefined | LocationKeySpecifier)
        fields?: LocationFieldPolicy
    }
    Range?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | RangeKeySpecifier | (() => undefined | RangeKeySpecifier)
        fields?: RangeFieldPolicy
    }
    Position?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | PositionKeySpecifier | (() => undefined | PositionKeySpecifier)
        fields?: PositionFieldPolicy
    }
    DiagnosticConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | DiagnosticConnectionKeySpecifier | (() => undefined | DiagnosticConnectionKeySpecifier)
        fields?: DiagnosticConnectionFieldPolicy
    }
    Diagnostic?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | DiagnosticKeySpecifier | (() => undefined | DiagnosticKeySpecifier)
        fields?: DiagnosticFieldPolicy
    }
    SymbolConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SymbolConnectionKeySpecifier | (() => undefined | SymbolConnectionKeySpecifier)
        fields?: SymbolConnectionFieldPolicy
    }
    GitRef?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | GitRefKeySpecifier | (() => undefined | GitRefKeySpecifier)
        fields?: GitRefFieldPolicy
    }
    GitObject?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | GitObjectKeySpecifier | (() => undefined | GitObjectKeySpecifier)
        fields?: GitObjectFieldPolicy
    }
    GitRevSpecExpr?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | GitRevSpecExprKeySpecifier | (() => undefined | GitRevSpecExprKeySpecifier)
        fields?: GitRevSpecExprFieldPolicy
    }
    GitRevisionRange?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | GitRevisionRangeKeySpecifier | (() => undefined | GitRevisionRangeKeySpecifier)
        fields?: GitRevisionRangeFieldPolicy
    }
    PhabricatorRepo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | PhabricatorRepoKeySpecifier | (() => undefined | PhabricatorRepoKeySpecifier)
        fields?: PhabricatorRepoFieldPolicy
    }
    PageInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | PageInfoKeySpecifier | (() => undefined | PageInfoKeySpecifier)
        fields?: PageInfoFieldPolicy
    }
    GitCommitConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | GitCommitConnectionKeySpecifier | (() => undefined | GitCommitConnectionKeySpecifier)
        fields?: GitCommitConnectionFieldPolicy
    }
    LanguageStatistics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | LanguageStatisticsKeySpecifier | (() => undefined | LanguageStatisticsKeySpecifier)
        fields?: LanguageStatisticsFieldPolicy
    }
    GitCommit?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | GitCommitKeySpecifier | (() => undefined | GitCommitKeySpecifier)
        fields?: GitCommitFieldPolicy
    }
    BehindAheadCounts?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | BehindAheadCountsKeySpecifier | (() => undefined | BehindAheadCountsKeySpecifier)
        fields?: BehindAheadCountsFieldPolicy
    }
    Signature?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SignatureKeySpecifier | (() => undefined | SignatureKeySpecifier)
        fields?: SignatureFieldPolicy
    }
    Person?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | PersonKeySpecifier | (() => undefined | PersonKeySpecifier)
        fields?: PersonFieldPolicy
    }
    Submodule?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SubmoduleKeySpecifier | (() => undefined | SubmoduleKeySpecifier)
        fields?: SubmoduleFieldPolicy
    }
    File2?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | File2KeySpecifier | (() => undefined | File2KeySpecifier)
        fields?: File2FieldPolicy
    }
    VirtualFile?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | VirtualFileKeySpecifier | (() => undefined | VirtualFileKeySpecifier)
        fields?: VirtualFileFieldPolicy
    }
    File?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | FileKeySpecifier | (() => undefined | FileKeySpecifier)
        fields?: FileFieldPolicy
    }
    HighlightedFile?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | HighlightedFileKeySpecifier | (() => undefined | HighlightedFileKeySpecifier)
        fields?: HighlightedFileFieldPolicy
    }
    FileMatch?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | FileMatchKeySpecifier | (() => undefined | FileMatchKeySpecifier)
        fields?: FileMatchFieldPolicy
    }
    LineMatch?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | LineMatchKeySpecifier | (() => undefined | LineMatchKeySpecifier)
        fields?: LineMatchFieldPolicy
    }
    ChunkMatch?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ChunkMatchKeySpecifier | (() => undefined | ChunkMatchKeySpecifier)
        fields?: ChunkMatchFieldPolicy
    }
    Hunk?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | HunkKeySpecifier | (() => undefined | HunkKeySpecifier)
        fields?: HunkFieldPolicy
    }
    Namespace?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | NamespaceKeySpecifier | (() => undefined | NamespaceKeySpecifier)
        fields?: NamespaceFieldPolicy
    }
    UserConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | UserConnectionKeySpecifier | (() => undefined | UserConnectionKeySpecifier)
        fields?: UserConnectionFieldPolicy
    }
    AccessToken?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | AccessTokenKeySpecifier | (() => undefined | AccessTokenKeySpecifier)
        fields?: AccessTokenFieldPolicy
    }
    AccessTokenConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | AccessTokenConnectionKeySpecifier | (() => undefined | AccessTokenConnectionKeySpecifier)
        fields?: AccessTokenConnectionFieldPolicy
    }
    AuthProviderConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | AuthProviderConnectionKeySpecifier | (() => undefined | AuthProviderConnectionKeySpecifier)
        fields?: AuthProviderConnectionFieldPolicy
    }
    AuthProvider?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | AuthProviderKeySpecifier | (() => undefined | AuthProviderKeySpecifier)
        fields?: AuthProviderFieldPolicy
    }
    ExternalAccountConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ExternalAccountConnectionKeySpecifier
            | (() => undefined | ExternalAccountConnectionKeySpecifier)
        fields?: ExternalAccountConnectionFieldPolicy
    }
    ExternalAccount?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ExternalAccountKeySpecifier | (() => undefined | ExternalAccountKeySpecifier)
        fields?: ExternalAccountFieldPolicy
    }
    Session?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SessionKeySpecifier | (() => undefined | SessionKeySpecifier)
        fields?: SessionFieldPolicy
    }
    OrganizationMembership?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | OrganizationMembershipKeySpecifier | (() => undefined | OrganizationMembershipKeySpecifier)
        fields?: OrganizationMembershipFieldPolicy
    }
    OrganizationMembershipConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | OrganizationMembershipConnectionKeySpecifier
            | (() => undefined | OrganizationMembershipConnectionKeySpecifier)
        fields?: OrganizationMembershipConnectionFieldPolicy
    }
    UserEmail?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | UserEmailKeySpecifier | (() => undefined | UserEmailKeySpecifier)
        fields?: UserEmailFieldPolicy
    }
    OrgConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | OrgConnectionKeySpecifier | (() => undefined | OrgConnectionKeySpecifier)
        fields?: OrgConnectionFieldPolicy
    }
    InviteUserToOrganizationResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | InviteUserToOrganizationResultKeySpecifier
            | (() => undefined | InviteUserToOrganizationResultKeySpecifier)
        fields?: InviteUserToOrganizationResultFieldPolicy
    }
    OrganizationInvitation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | OrganizationInvitationKeySpecifier | (() => undefined | OrganizationInvitationKeySpecifier)
        fields?: OrganizationInvitationFieldPolicy
    }
    AutocompleteMemberSearchItem?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | AutocompleteMemberSearchItemKeySpecifier
            | (() => undefined | AutocompleteMemberSearchItemKeySpecifier)
        fields?: AutocompleteMemberSearchItemFieldPolicy
    }
    OrgMembersSummary?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | OrgMembersSummaryKeySpecifier | (() => undefined | OrgMembersSummaryKeySpecifier)
        fields?: OrgMembersSummaryFieldPolicy
    }
    DefaultSettings?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | DefaultSettingsKeySpecifier | (() => undefined | DefaultSettingsKeySpecifier)
        fields?: DefaultSettingsFieldPolicy
    }
    Site?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SiteKeySpecifier | (() => undefined | SiteKeySpecifier)
        fields?: SiteFieldPolicy
    }
    SiteConfiguration?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SiteConfigurationKeySpecifier | (() => undefined | SiteConfigurationKeySpecifier)
        fields?: SiteConfigurationFieldPolicy
    }
    UpdateCheck?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | UpdateCheckKeySpecifier | (() => undefined | UpdateCheckKeySpecifier)
        fields?: UpdateCheckFieldPolicy
    }
    Alert?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | AlertKeySpecifier | (() => undefined | AlertKeySpecifier)
        fields?: AlertFieldPolicy
    }
    SettingsSubject?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SettingsSubjectKeySpecifier | (() => undefined | SettingsSubjectKeySpecifier)
        fields?: SettingsSubjectFieldPolicy
    }
    SettingsCascade?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SettingsCascadeKeySpecifier | (() => undefined | SettingsCascadeKeySpecifier)
        fields?: SettingsCascadeFieldPolicy
    }
    ConfigurationCascade?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ConfigurationCascadeKeySpecifier | (() => undefined | ConfigurationCascadeKeySpecifier)
        fields?: ConfigurationCascadeFieldPolicy
    }
    Settings?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SettingsKeySpecifier | (() => undefined | SettingsKeySpecifier)
        fields?: SettingsFieldPolicy
    }
    Configuration?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ConfigurationKeySpecifier | (() => undefined | ConfigurationKeySpecifier)
        fields?: ConfigurationFieldPolicy
    }
    UserUsageStatistics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | UserUsageStatisticsKeySpecifier | (() => undefined | UserUsageStatisticsKeySpecifier)
        fields?: UserUsageStatisticsFieldPolicy
    }
    SiteUsageStatistics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SiteUsageStatisticsKeySpecifier | (() => undefined | SiteUsageStatisticsKeySpecifier)
        fields?: SiteUsageStatisticsFieldPolicy
    }
    AnalyticsStatItemNode?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | AnalyticsStatItemNodeKeySpecifier | (() => undefined | AnalyticsStatItemNodeKeySpecifier)
        fields?: AnalyticsStatItemNodeFieldPolicy
    }
    AnalyticsStatItemSummary?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | AnalyticsStatItemSummaryKeySpecifier
            | (() => undefined | AnalyticsStatItemSummaryKeySpecifier)
        fields?: AnalyticsStatItemSummaryFieldPolicy
    }
    AnalyticsStatItem?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | AnalyticsStatItemKeySpecifier | (() => undefined | AnalyticsStatItemKeySpecifier)
        fields?: AnalyticsStatItemFieldPolicy
    }
    AnalyticsSearchResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | AnalyticsSearchResultKeySpecifier | (() => undefined | AnalyticsSearchResultKeySpecifier)
        fields?: AnalyticsSearchResultFieldPolicy
    }
    AnalyticsNotebooksResults?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | AnalyticsNotebooksResultsKeySpecifier
            | (() => undefined | AnalyticsNotebooksResultsKeySpecifier)
        fields?: AnalyticsNotebooksResultsFieldPolicy
    }
    AnalyticsUsersFrequencyItem?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | AnalyticsUsersFrequencyItemKeySpecifier
            | (() => undefined | AnalyticsUsersFrequencyItemKeySpecifier)
        fields?: AnalyticsUsersFrequencyItemFieldPolicy
    }
    AnalyticsUsersSummaryResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | AnalyticsUsersSummaryResultKeySpecifier
            | (() => undefined | AnalyticsUsersSummaryResultKeySpecifier)
        fields?: AnalyticsUsersSummaryResultFieldPolicy
    }
    AnalyticsUsersResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | AnalyticsUsersResultKeySpecifier | (() => undefined | AnalyticsUsersResultKeySpecifier)
        fields?: AnalyticsUsersResultFieldPolicy
    }
    AnalyticsCodeIntelResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | AnalyticsCodeIntelResultKeySpecifier
            | (() => undefined | AnalyticsCodeIntelResultKeySpecifier)
        fields?: AnalyticsCodeIntelResultFieldPolicy
    }
    AnalyticsCodeIntelByLanguageResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | AnalyticsCodeIntelByLanguageResultKeySpecifier
            | (() => undefined | AnalyticsCodeIntelByLanguageResultKeySpecifier)
        fields?: AnalyticsCodeIntelByLanguageResultFieldPolicy
    }
    AnalyticsCodeIntelRepositoryResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | AnalyticsCodeIntelRepositoryResultKeySpecifier
            | (() => undefined | AnalyticsCodeIntelRepositoryResultKeySpecifier)
        fields?: AnalyticsCodeIntelRepositoryResultFieldPolicy
    }
    AnalyticsReposSummartResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | AnalyticsReposSummartResultKeySpecifier
            | (() => undefined | AnalyticsReposSummartResultKeySpecifier)
        fields?: AnalyticsReposSummartResultFieldPolicy
    }
    AnalyticsBatchChangesResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | AnalyticsBatchChangesResultKeySpecifier
            | (() => undefined | AnalyticsBatchChangesResultKeySpecifier)
        fields?: AnalyticsBatchChangesResultFieldPolicy
    }
    AnalyticsExtensionsResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | AnalyticsExtensionsResultKeySpecifier
            | (() => undefined | AnalyticsExtensionsResultKeySpecifier)
        fields?: AnalyticsExtensionsResultFieldPolicy
    }
    AnalyticsCodesInsightsResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | AnalyticsCodesInsightsResultKeySpecifier
            | (() => undefined | AnalyticsCodesInsightsResultKeySpecifier)
        fields?: AnalyticsCodesInsightsResultFieldPolicy
    }
    Analytics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | AnalyticsKeySpecifier | (() => undefined | AnalyticsKeySpecifier)
        fields?: AnalyticsFieldPolicy
    }
    SiteUser?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SiteUserKeySpecifier | (() => undefined | SiteUserKeySpecifier)
        fields?: SiteUserFieldPolicy
    }
    SiteUsers?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SiteUsersKeySpecifier | (() => undefined | SiteUsersKeySpecifier)
        fields?: SiteUsersFieldPolicy
    }
    SiteUsagePeriod?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SiteUsagePeriodKeySpecifier | (() => undefined | SiteUsagePeriodKeySpecifier)
        fields?: SiteUsagePeriodFieldPolicy
    }
    MonitoringStatistics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | MonitoringStatisticsKeySpecifier | (() => undefined | MonitoringStatisticsKeySpecifier)
        fields?: MonitoringStatisticsFieldPolicy
    }
    MonitoringAlert?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | MonitoringAlertKeySpecifier | (() => undefined | MonitoringAlertKeySpecifier)
        fields?: MonitoringAlertFieldPolicy
    }
    SurveyResponseConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | SurveyResponseConnectionKeySpecifier
            | (() => undefined | SurveyResponseConnectionKeySpecifier)
        fields?: SurveyResponseConnectionFieldPolicy
    }
    SurveyResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SurveyResponseKeySpecifier | (() => undefined | SurveyResponseKeySpecifier)
        fields?: SurveyResponseFieldPolicy
    }
    ProductSubscriptionStatus?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ProductSubscriptionStatusKeySpecifier
            | (() => undefined | ProductSubscriptionStatusKeySpecifier)
        fields?: ProductSubscriptionStatusFieldPolicy
    }
    ProductLicenseInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ProductLicenseInfoKeySpecifier | (() => undefined | ProductLicenseInfoKeySpecifier)
        fields?: ProductLicenseInfoFieldPolicy
    }
    ExtensionRegistry?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ExtensionRegistryKeySpecifier | (() => undefined | ExtensionRegistryKeySpecifier)
        fields?: ExtensionRegistryFieldPolicy
    }
    RegistryPublisherConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | RegistryPublisherConnectionKeySpecifier
            | (() => undefined | RegistryPublisherConnectionKeySpecifier)
        fields?: RegistryPublisherConnectionFieldPolicy
    }
    ExtensionRegistryMutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ExtensionRegistryMutationKeySpecifier
            | (() => undefined | ExtensionRegistryMutationKeySpecifier)
        fields?: ExtensionRegistryMutationFieldPolicy
    }
    ExtensionRegistryCreateExtensionResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ExtensionRegistryCreateExtensionResultKeySpecifier
            | (() => undefined | ExtensionRegistryCreateExtensionResultKeySpecifier)
        fields?: ExtensionRegistryCreateExtensionResultFieldPolicy
    }
    ExtensionRegistryUpdateExtensionResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ExtensionRegistryUpdateExtensionResultKeySpecifier
            | (() => undefined | ExtensionRegistryUpdateExtensionResultKeySpecifier)
        fields?: ExtensionRegistryUpdateExtensionResultFieldPolicy
    }
    ExtensionRegistryPublishExtensionResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ExtensionRegistryPublishExtensionResultKeySpecifier
            | (() => undefined | ExtensionRegistryPublishExtensionResultKeySpecifier)
        fields?: ExtensionRegistryPublishExtensionResultFieldPolicy
    }
    RegistryExtension?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | RegistryExtensionKeySpecifier | (() => undefined | RegistryExtensionKeySpecifier)
        fields?: RegistryExtensionFieldPolicy
    }
    ExtensionManifest?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ExtensionManifestKeySpecifier | (() => undefined | ExtensionManifestKeySpecifier)
        fields?: ExtensionManifestFieldPolicy
    }
    RegistryExtensionConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | RegistryExtensionConnectionKeySpecifier
            | (() => undefined | RegistryExtensionConnectionKeySpecifier)
        fields?: RegistryExtensionConnectionFieldPolicy
    }
    FeaturedExtensionsConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | FeaturedExtensionsConnectionKeySpecifier
            | (() => undefined | FeaturedExtensionsConnectionKeySpecifier)
        fields?: FeaturedExtensionsConnectionFieldPolicy
    }
    CodeIntelligenceRangeConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | CodeIntelligenceRangeConnectionKeySpecifier
            | (() => undefined | CodeIntelligenceRangeConnectionKeySpecifier)
        fields?: CodeIntelligenceRangeConnectionFieldPolicy
    }
    CodeIntelligenceRange?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | CodeIntelligenceRangeKeySpecifier | (() => undefined | CodeIntelligenceRangeKeySpecifier)
        fields?: CodeIntelligenceRangeFieldPolicy
    }
    LocationConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | LocationConnectionKeySpecifier | (() => undefined | LocationConnectionKeySpecifier)
        fields?: LocationConnectionFieldPolicy
    }
    Hover?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | HoverKeySpecifier | (() => undefined | HoverKeySpecifier)
        fields?: HoverFieldPolicy
    }
    CloningProgress?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | CloningProgressKeySpecifier | (() => undefined | CloningProgressKeySpecifier)
        fields?: CloningProgressFieldPolicy
    }
    ExternalServiceSyncError?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ExternalServiceSyncErrorKeySpecifier
            | (() => undefined | ExternalServiceSyncErrorKeySpecifier)
        fields?: ExternalServiceSyncErrorFieldPolicy
    }
    SyncError?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SyncErrorKeySpecifier | (() => undefined | SyncErrorKeySpecifier)
        fields?: SyncErrorFieldPolicy
    }
    RepositoryStats?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | RepositoryStatsKeySpecifier | (() => undefined | RepositoryStatsKeySpecifier)
        fields?: RepositoryStatsFieldPolicy
    }
    EventLog?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | EventLogKeySpecifier | (() => undefined | EventLogKeySpecifier)
        fields?: EventLogFieldPolicy
    }
    EventLogsConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | EventLogsConnectionKeySpecifier | (() => undefined | EventLogsConnectionKeySpecifier)
        fields?: EventLogsConnectionFieldPolicy
    }
    CodeHostRepositoryConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | CodeHostRepositoryConnectionKeySpecifier
            | (() => undefined | CodeHostRepositoryConnectionKeySpecifier)
        fields?: CodeHostRepositoryConnectionFieldPolicy
    }
    CodeHostRepository?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | CodeHostRepositoryKeySpecifier | (() => undefined | CodeHostRepositoryKeySpecifier)
        fields?: CodeHostRepositoryFieldPolicy
    }
    ExecutionLogEntry?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | ExecutionLogEntryKeySpecifier | (() => undefined | ExecutionLogEntryKeySpecifier)
        fields?: ExecutionLogEntryFieldPolicy
    }
    TemporarySettings?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | TemporarySettingsKeySpecifier | (() => undefined | TemporarySettingsKeySpecifier)
        fields?: TemporarySettingsFieldPolicy
    }
    WebhookLogConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | WebhookLogConnectionKeySpecifier | (() => undefined | WebhookLogConnectionKeySpecifier)
        fields?: WebhookLogConnectionFieldPolicy
    }
    WebhookLog?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | WebhookLogKeySpecifier | (() => undefined | WebhookLogKeySpecifier)
        fields?: WebhookLogFieldPolicy
    }
    WebhookLogMessage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | WebhookLogMessageKeySpecifier | (() => undefined | WebhookLogMessageKeySpecifier)
        fields?: WebhookLogMessageFieldPolicy
    }
    WebhookLogRequest?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | WebhookLogRequestKeySpecifier | (() => undefined | WebhookLogRequestKeySpecifier)
        fields?: WebhookLogRequestFieldPolicy
    }
    WebhookLogResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | WebhookLogResponseKeySpecifier | (() => undefined | WebhookLogResponseKeySpecifier)
        fields?: WebhookLogResponseFieldPolicy
    }
    WebhookLogHeader?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | WebhookLogHeaderKeySpecifier | (() => undefined | WebhookLogHeaderKeySpecifier)
        fields?: WebhookLogHeaderFieldPolicy
    }
    SearchContext?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?: false | SearchContextKeySpecifier | (() => undefined | SearchContextKeySpecifier)
        fields?: SearchContextFieldPolicy
    }
    SearchContextRepositoryRevisions?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | SearchContextRepositoryRevisionsKeySpecifier
            | (() => undefined | SearchContextRepositoryRevisionsKeySpecifier)
        fields?: SearchContextRepositoryRevisionsFieldPolicy
    }
    SearchContextConnection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | SearchContextConnectionKeySpecifier
            | (() => undefined | SearchContextConnectionKeySpecifier)
        fields?: SearchContextConnectionFieldPolicy
    }
}
